import { apiRequest } from "../apiRequest";
import {
  LOGIN_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  SET_PASSWORD_ENDPOINT,
} from "../urls";

export const login = (data) => {
  return apiRequest("POST", LOGIN_ENDPOINT, data);
};

export const resetPassword = (data) => {
  return apiRequest("POST", RESET_PASSWORD_ENDPOINT, data);
};

export const setPassword = (data) => {
  return apiRequest("POST", SET_PASSWORD_ENDPOINT, data);
};
