import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Field } from "formik";
import _ from "lodash";

import ProtectedRoutes from "../..";
import SubmitButton from "../../../../components/Button";
import CustomInput from "../../../../components/Input";
import {
  CustomColumn,
  CustomMarginTopSpacing,
  CustomRow,
} from "../../../../components";
import VignetCost from "./VignetCostInfo";
import Payments, { FailedPaymentMessage } from "./Payment";
import {
  ExtendSchema,
  BankSelectionSchema,
  AccountHolderSchema,
} from "../validationSchemas";
import DocumentUpload from "./Documents";
import {
  getDriveName,
  getNextAvailableVignet,
  getPaymentTypeIDFromName,
  parseQuery,
} from "../../../../utils/helpers";
import ShowDefaultVignet from "./ShowDefaultVignet";
import { DriveDropDown, MarinaChoice } from "./Forms";
import SummaryManagement from "./SummaryManagement";
import store from "../../../../services/store";
import { Creators } from "../../../../services/redux/payments/actions";
import {
  BlackIntro,
  BlackP1,
  CustomErrorMessage,
} from "../../../../components/Typography";

const RenderField = ({ showField, fieldName, value }) => {
  return (
    <React.Fragment>
      {showField ? (
        <div style={{ marginTop: "-20px" }}>
          <Field name={fieldName} component={CustomInput} isSpecial />
        </div>
      ) : (
        <BlackP1>{value}</BlackP1>
      )}
    </React.Fragment>
  );
};

export const UploadFileContext = React.createContext();
let initialState = {
  Ligplaatsbewijs: null,
  Ontheffing: null,
};

let reducer = (state, action) => {
  switch (action.type) {
    case "Ontheffing":
      return { ...state, Ontheffing: action.payload };
    case "Ontheffing.delete":
      return {
        ...state,
        Ontheffing: state.Ontheffing.filter(
          (prevFile) => prevFile.id !== action.id
        ),
      };
    case "Ligplaatsbewijs":
      return { ...state, Ligplaatsbewijs: action.payload };
    case "Ligplaatsbewijs.delete":
      return {
        ...state,
        Ligplaatsbewijs: state.Ligplaatsbewijs.filter(
          (prevFile) => prevFile.id !== action.id
        ),
      };
    default:
      return { ...state };
  }
};

const ExtendVignette = ({
  location,
  idealPaymentfailed,

  getDriveTypes,
  driveTypes,
  isGettingDriveTypes,
  isGettingDriveTypesError,

  getOwner,

  isUpdatingVignet,
  isUpdatingVignetError,
  updateVignet,

  isGettingVignet,
  isGettingVignetError,
  vignet,
  getPaymentTypes,

  isUploading,
  uploadError,
  uploadDocuments,

  payViaAccountNumber,
  isPayingViaBankAccount,
  isPayingAccountNumberError,

  isPayingBankPayment,
  isPayingBankPaymentError,
  payViaBankPayment,

  validateIban,
  isCheckingIbanRequest,
  ibanValidationError,
  ibanValid,
  refreshIbanValidation,

  lang,
}) => {
  const [apiDone, setApiDone] = useState(false);
  const [shouldEdit, setShouldEdit] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showInstallmentForm, setShowInstallmentForm] = useState(false);
  const [paymentChoice, setPaymentChoice] = useState("");
  const [showSummary, setShowSummary] = useState(false);
  const [paymentValues, setPaymentValues] = useState("");
  const [showDocumentSummary, setShowDocumentSummary] = useState(false);
  const [defaultDriveTypeError, setDefaultDriveTypeError] = useState(false);
  const [showRequiredDocumentError, setShowRequiredDocumentError] = useState(
    false
  );
  const [formValues, setFormValues] = useState({});
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [showFailedPayment, setShowFailedPayment] = useState(
    idealPaymentfailed === "true"
  );

  let [state, dispatch] = React.useReducer(reducer, initialState);
  let uploadedFiles = { state, dispatch };

  const failureMessageRef = useRef(null);
  const successMessageRef = useRef(null);
  const documentRef = useRef();

  useEffect(() => {
    if (idealPaymentfailed !== "true") {
      getPaymentTypes();
    }
    if (!apiDone) getDriveTypes();

    // this is from the queryparam
    if (idealPaymentfailed === "true") {
      store.dispatch(
        Creators.getPaymentTypesSuccess(
          JSON.parse(localStorage.getItem("payment_types"))
        )
      );
      getOwner();
      setApiDone(false);
      setPaymentValues(JSON.parse(localStorage.getItem("payment_details")));
      setShowPayment(true);
      setShowSummary(true);
      setPaymentChoice(lang === "nl" ? "In 1 keer betalen" : "Pay in one go");

      if (failureMessageRef.current) {
        setTimeout(() => {
          failureMessageRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 1000);
      }
    }

    // check query params
    if (parseQuery(location.search)?.success) {
      setReadOnlyMode(true);
      getOwner();
      setApiDone(false);
      setPaymentValues(JSON.parse(localStorage.getItem("payment_details")));
      setPaymentChoice(lang === "nl" ? "In 1 keer betalen" : "Pay in one go");
      setShowSummary(true);
      setShowPayment(true);

      if (successMessageRef?.current) {
        setTimeout(() => {
          successMessageRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 1000);
      }
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ibanValid) {
      getOwner();
      setShowSummary(true);
    }
  }, [ibanValid, getOwner]);

  const getYesOrNo = (vignet) => {
    if (vignet.object_marina === "0") {
      return lang === "nl" ? "Nee" : "No";
    }

    return lang === "nl" ? "Ja" : "Yes";
  };

  const showVignetCost = () => {
    if (readOnlyMode) return true;
    if (shouldEdit === false && idealPaymentfailed === "true") return true;
    if (
      shouldEdit === false &&
      isGettingVignet === false &&
      apiDone &&
      !_.isEmpty(vignet) &&
      vignet.object_drive_type_id
    ) {
      return true;
    }
  };

  const showDocument = () => {
    if (showVignetCost() && vignet.documents && apiDone) {
      return true;
    }
  };

  const getValidationSchema = () => {
    if (showSummary) return;
    if (shouldEdit) {
      return ExtendSchema;
    }
    if (
      showDocument &&
      ["In 1 keer betalen", "Pay in one go"].includes(paymentChoice)
    )
      return;
    if (["In 1 keer betalen", "Pay in one go"].includes(paymentChoice)) {
      return BankSelectionSchema;
    }
    if (
      ["In 4 installments", "In 4 termijnen", "In 4 delen"].includes(
        paymentChoice
      )
    ) {
      return AccountHolderSchema;
    }
  };

  const submitData = () => {
    const values = formValues;
    if (!values.vignet_id) values.vignet_id = vignet.vignet_id;
    delete values.agree_1;
    delete values.agree_2;
    delete values.object_drive_type_id;
    delete values.object_length;
    delete values.object_marina;
    delete values.object_width;

    if (paymentChoice) {
      values.payment_type_id = getPaymentTypeIDFromName(paymentChoice);
    }
    localStorage.setItem("payment_details", JSON.stringify(values));
    if (showDocument()) {
      // we don't need issuer for documents upload;
      // if (values.issuer) delete values.issuer;

      uploadDocuments({
        documents: uploadedFiles.state,
        vignet_id: vignet.vignet_id,
        values,
      });
    } else if (["In 1 keer betalen", "Pay in one go"].includes(paymentChoice)) {
      delete values.account_number;
      delete values.account_holder;
      payViaBankPayment(values);
    } else if (
      ["In 4 installments", "In 4 termijnen", "In 4 delen"].includes(
        paymentChoice
      )
    ) {
      //delete values.issuer;
      payViaAccountNumber(values);
    }
  };

  const moveToNextActiveVignetFromReadOnly = () => {
    const nextVignet = getNextAvailableVignet();
    if (nextVignet) {
      // get the vignet decision coz the next vignet could be cancellation
      window.location = `/push-decision?vignet_id=${nextVignet.vignet_id}&decision=${nextVignet.decision}`;
    } else {
      window.location = "/done";
    }
  };

  return (
    <UploadFileContext.Provider value={uploadedFiles}>
      <ProtectedRoutes
        step={3}
        title={<FormattedMessage id="extendTitle" />}
        titleMore={vignet.vignet_number}
        subtitle={<FormattedMessage id="extendDescription" />}
        hasForm={!showSummary}
        initialValues={{
          object_marina: vignet.object_marina,
          object_length: vignet.object_length,
          object_width: vignet.object_width,
          object_drive_type_id: vignet.object_drive_type_id,
          ...(showInstallmentForm && {
            account_number: "",
            account_holder: "",
            account_agreement: "",
          }),
        }}
        validationSchema={getValidationSchema()}
        isLoading={
          isGettingDriveTypes ||
          isUpdatingVignet ||
          isGettingVignet ||
          isPayingViaBankAccount ||
          isPayingBankPayment ||
          isUploading
        }
        error={
          isGettingDriveTypesError ||
          isUpdatingVignetError ||
          isGettingVignetError ||
          isPayingAccountNumberError ||
          isPayingBankPaymentError ||
          uploadError
        }
        onSubmit={(values) => {
          values.vignet_id = vignet.vignet_id;
          setFormValues(values);
          if (shouldEdit) {
            setShouldEdit(false);
            setShowDocumentSummary(false);
            updateVignet({
              vignet_id: values.vignet_id,
              object_length: values.object_length,
              object_width: values.object_width,
              object_marina: values.object_marina,
              object_drive_type_id: values.object_drive_type_id,
            });
          } else if (
            ["In 1 keer betalen", "Pay in one go"].includes(paymentChoice)
          ) {
            getOwner();
            setPaymentValues(values);
            setShowSummary(true);
          } else if (
            ["In 4 installments", "In 4 termijnen", "In 4 delen"].includes(
              paymentChoice
            )
          ) {
            setPaymentValues(values);
            if (!ibanValid) {
              validateIban(values);
            } else {
              getOwner();
              setShowSummary(true);
            }
          }
        }}
      >
        <React.Fragment>
          <ShowDefaultVignet
            vignet={vignet}
            setShouldEdit={setShouldEdit}
            setShowSummary={setShowSummary}
            setShowPayment={setShowPayment}
            shouldEdit={shouldEdit}
            setShowDocumentSummary={setShowDocumentSummary}
            setDefaultDriveTypeError={setDefaultDriveTypeError}
            readOnlyMode={readOnlyMode}
            setShowFailedPayment={setShowFailedPayment}
          />
          {shouldEdit && (
            <CustomRow>
              <CustomColumn size={2}>
                <RenderField
                  value={vignet.object_length}
                  fieldName="object_length"
                  showField
                />
              </CustomColumn>
              <CustomColumn size={2}>
                <RenderField
                  value={vignet.object_width}
                  fieldName="object_width"
                  showField
                />
              </CustomColumn>
              <CustomColumn size={4} />
            </CustomRow>
          )}
          <CustomRow style={{ marginTop: shouldEdit ? "0px" : "10px" }}>
            <CustomColumn size={12}>
              <BlackIntro style={{ fontWeight: "600" }}>
                <FormattedMessage id="drive" />
              </BlackIntro>
            </CustomColumn>
            <CustomColumn size={4}>
              {shouldEdit ? (
                <DriveDropDown vignet={vignet} driveTypes={driveTypes} />
              ) : (
                <BlackP1 style={{ marginTop: "-10px" }}>
                  {getDriveName(driveTypes, vignet.object_drive_type_id)}
                  {defaultDriveTypeError && (
                    <CustomErrorMessage id="selectChoice" />
                  )}
                </BlackP1>
              )}
            </CustomColumn>
          </CustomRow>
          <CustomMarginTopSpacing />
          <CustomRow>
            <CustomColumn size={12}>
              <BlackIntro style={{ fontWeight: "600" }}>
                <FormattedMessage
                  id={
                    shouldEdit || showVignetCost() ? "marinaQuestion" : "marina"
                  }
                />
              </BlackIntro>
            </CustomColumn>
            <CustomColumn size={12}>
              {shouldEdit ? (
                <MarinaChoice />
              ) : (
                <BlackP1 style={{ marginTop: "-10px" }}>
                  {getYesOrNo(vignet)}
                </BlackP1>
              )}
            </CustomColumn>
          </CustomRow>
          {showVignetCost() && (
            <CustomRow>
              <CustomColumn size={6}>
                <VignetCost vignet={vignet} />
              </CustomColumn>
            </CustomRow>
          )}
          {showDocument() && (
            <CustomRow>
              <CustomColumn size={6}>
                <DocumentUpload
                  documentRef={documentRef}
                  vignet={vignet}
                  showRequiredDocumentError={showRequiredDocumentError}
                  setShowRequiredDocumentError={setShowRequiredDocumentError}
                  showDocumentSummary={showDocumentSummary}
                  setShowDocumentSummary={setShowDocumentSummary}
                  setShowSummary={setShowSummary}
                  setShowPayment={setShowPayment}
                />
              </CustomColumn>
            </CustomRow>
          )}
          {showPayment && (
            <CustomRow>
              <CustomColumn size={12}>
                <CustomMarginTopSpacing />
                <Payments
                  selectPaymentChoice={setPaymentChoice}
                  isSummary={showSummary}
                  resetChoice={() => {
                    setShowSummary(false);
                  }}
                  lang={lang}
                  hasDocuments={showDocument()}
                  {...(idealPaymentfailed === "true" && {
                    resetFailedPaymentType:
                      lang === "nl" ? "In 1 keer betalen" : "Pay in one go",
                  })}
                  // the purpose of this is due to when a vignet has documents and after selecting
                  // the payment choice and the user decides to edit
                  previousChoice={paymentChoice}
                  showInstallmentForm={showInstallmentForm}
                  ibanValidationError={ibanValidationError}
                  refreshIbanValidation={refreshIbanValidation}
                  readOnlyMode={readOnlyMode}
                  setShowFailedPayment={setShowFailedPayment}
                />
              </CustomColumn>
            </CustomRow>
          )}
          {showSummary && (
            <>
              <br />
              <SummaryManagement
                vignet={vignet}
                driveTypes={driveTypes}
                paymentChoice={paymentChoice}
                paymentValues={paymentValues}
                lang={lang}
                onNext={() =>
                  readOnlyMode
                    ? moveToNextActiveVignetFromReadOnly()
                    : submitData()
                }
                readOnlyMode={readOnlyMode}
                disabled={isPayingViaBankAccount || isPayingBankPayment}
                {...(showSummary &&
                  !showDocument() &&
                  ["In 1 keer betalen", "Pay in one go"].includes(
                    paymentChoice
                  ) && { nextTitle: "pay" })}
              >
                <div ref={failureMessageRef}>
                  {showFailedPayment && <FailedPaymentMessage />}
                </div>
              </SummaryManagement>
            </>
          )}
          {!showSummary && (
            <SubmitButton
              {...(showSummary &&
                !showDocument() &&
                ["In 1 keer betalen", "Pay in one go"].includes(
                  paymentChoice
                ) && { nextTitle: "pay" })}
              {...(showSummary &&
                (showDocument() ||
                  !["In 1 keer betalen", "Pay in one go"].includes(
                    paymentChoice
                  )) && {
                  nextTitle: "send",
                })}
              onNext={() => {
                // only set this once, we don't want unnecessary re-render
                if (!apiDone) setApiDone(true);
                if (showDocument() && !showPayment && !showSummary) {
                  const allDOcumentsUploaded = vignet.documents?.every(
                    (doc) => !_.isEmpty(uploadedFiles.state[doc])
                  );
                  if (!allDOcumentsUploaded) {
                    setShowRequiredDocumentError(true);
                    return;
                  }
                  if (paymentChoice) setPaymentChoice(paymentChoice);
                  setShowRequiredDocumentError(false);
                  setShowDocumentSummary(true);
                  setShowPayment(true);
                  setShowSummary(false);
                } else if (showSummary) return;
                else if (showVignetCost() && !showPayment) {
                  setShowPayment(true);
                } else if (
                  ["In 1 keer betalen", "Pay in one go"].includes(paymentChoice)
                ) {
                  return;
                } else if (
                  [
                    "In 4 installments",
                    "In 4 termijnen",
                    "In 4 delen",
                  ].includes(paymentChoice)
                ) {
                  if (showInstallmentForm) {
                    return;
                  } else {
                    setShowInstallmentForm(true);
                    return;
                  }
                } else if (shouldEdit || showPayment) return;
                else {
                  if (!vignet.object_drive_type_id) {
                    setDefaultDriveTypeError(true);
                    return;
                  }
                  setDefaultDriveTypeError(false);
                  updateVignet({
                    vignet_id: vignet.vignet_id,
                    object_length: vignet.object_length,
                    object_width: vignet.object_width,
                    object_marina: vignet.object_marina,
                    object_drive_type_id: vignet.object_drive_type_id,
                  });
                }
              }}
              disabled={
                isGettingDriveTypes ||
                isUpdatingVignet ||
                isGettingVignet ||
                isPayingViaBankAccount ||
                isPayingBankPayment ||
                isUploading ||
                isCheckingIbanRequest
              }
            />
          )}
        </React.Fragment>
      </ProtectedRoutes>
    </UploadFileContext.Provider>
  );
};

ExtendVignette.propTypes = {
  idealPaymentfailed: PropTypes.string,
  getDriveTypes: PropTypes.func.isRequired,
  driveTypes: PropTypes.shape({}),
  isGettingDriveTypes: PropTypes.bool.isRequired,
  isGettingDriveTypesError: PropTypes.string,
  getOwner: PropTypes.func.isRequired,
  isUpdatingVignet: PropTypes.bool.isRequired,
  isUpdatingVignetError: PropTypes.string,
  updateVignet: PropTypes.func.isRequired,
  isGettingVignet: PropTypes.bool.isRequired,
  isGettingVignetError: PropTypes.string,
  vignet: PropTypes.shape({}).isRequired,
  getPaymentTypes: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  uploadError: PropTypes.string,
  uploadDocuments: PropTypes.func.isRequired,
  isPayingViaBankAccount: PropTypes.bool.isRequired,
  isPayingAccountNumberError: PropTypes.string,
  payViaAccountNumber: PropTypes.func.isRequired,
  isPayingBankPayment: PropTypes.bool.isRequired,
  isPayingBankPaymentError: PropTypes.string,
  payViaBankPayment: PropTypes.func.isRequired,
};

export default ExtendVignette;
