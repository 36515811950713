import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    getPaymentTypesRequest: [],
    getPaymentTypesSuccess: ["responseData"],
    getPaymentTypesFailure: ["error"],

    accountNumberPaymentRequest: ["data"],
    accountNumberPaymentSuccess: ["responseData"],
    accountNumberPaymentFailure: ["error"],

    bankPaymentRequest: ["data"],
    bankPaymentSuccess: ["responseData"],
    bankPaymentFailure: ["error"],

    checkIbanValidationRequest: ["data"],
    checkIbanValidationSuccess: ["responseData"],
    checkIbanValidationFailure: ["error"],

    refreshIbanValidation: [],

    // TRANSIT VIGNETTES
    getNewPaymentTypes: [],
    getNewPaymentTypesSuccess: ["responseData"],
    getNewPaymentTypesFailure: ["error"],

    makeNewPayment: [],
    makeNewPaymentSuccess: ["responseData"],
    makeNewPaymentFailure: ["error"],

    getMooringPaymentTypes: [],
    getMooringPaymentTypesSuccess: ["responseData"],
    getMooringPaymentTypesFailure: ["error"],

    mooringMakePayment: ["data"],
    mooringMakePaymentSuccess: ["responseData"],
    mooringMakePaymentFailure: ["error"],
  },
  {}
);
