import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    loginRequest: ["email", "password"],
    loginSuccess: ["responseData"],
    loginFailure: ["error"],

    resetPasswordRequest: ["email", "base_url"],
    resetPasswordSuccess: ["responseData"],
    resetPasswordFailure: ["error"],

    setPasswordRequest: ["password", "token"],
    setPasswordSuccess: ["responseData"],
    setPasswordFailure: ["error"],

    logout: null,
  },
  {}
);
