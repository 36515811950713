import axios from "axios";
import { BASE_URL } from "./urls";
import { BASE_URL as TRANSIT_BASE_URL } from "./transit-urls";

export const getToken = () => {
  let token = localStorage.getItem("access_token");
  return token;
};

export const refreshToken = () => {};

export const axiosInstance = (file) => {
  let AUTH_TOKEN = getToken() || "";
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: "application/json",
      ...(AUTH_TOKEN && {
        Authorization: "Bearer " + AUTH_TOKEN,
      }),
    },
  });

  return instance;
};

export const transitAxiosInstance = (file) => {
  let AUTH_TOKEN = getToken() || "";
  const instance = axios.create({
    baseURL: TRANSIT_BASE_URL,
    headers: {
      Accept: "application/json",
      ...(AUTH_TOKEN && {
        Authorization: "Bearer " + AUTH_TOKEN,
      }),
    },
  });

  return instance;
};
