import { apiRequest, transitApiRequest } from "../apiRequest";
import { BANK_PAYMENT, GET_PAYMENT_TYPES, CHECK_IBAN } from "../urls";

import {
  GET_PAYMENT_TYPES as TRANSIT_GET_PAYMENT_TYPES,
  NEW_VIGNET_PAYMENT,
  GET_MOORING_PAYMENT_TYPES,
  MOORING_PAYMENT,
} from "../transit-urls";

export const getPaymentTypes = () => {
  return apiRequest("GET", GET_PAYMENT_TYPES, null);
};

export const accountNumberPayment = (data) => {
  return apiRequest("POST", BANK_PAYMENT, data);
};

export const bankPayment = (data) => {
  return apiRequest("POST", BANK_PAYMENT, data);
};

export const checkIbanValidation = (data) => {
  return apiRequest("POST", CHECK_IBAN, data);
};

// TRANSIT VIGNETTES

export const getNewPaymentTypes = () => {
  return transitApiRequest("GET", TRANSIT_GET_PAYMENT_TYPES, null);
};

export const makeNewPayment = (data) => {
  return transitApiRequest("POST", NEW_VIGNET_PAYMENT, data);
};

// 24 hour mooring vignette
export const getMooringPaymentTypes = () => {
  return transitApiRequest("GET", GET_MOORING_PAYMENT_TYPES, null);
};

export const makeMooringPayment = (data) => {
  return transitApiRequest("POST", MOORING_PAYMENT, data);
};
