import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CustomLink from "../../../../components/Link/link";
import { DetailContainer } from "../../../../components";
import { BlackIntro, BlackP1 } from "../../../../components/Typography";

const CompanyHeader = ({
  isUserCompany,
  user: { kvk, companyname },
  setShowPersonalInfo,
  setEditCompany,
  editCompany,
}) => {
  return isUserCompany ? (
    <React.Fragment>
      <BlackIntro>
        <FormattedMessage id="companyDetails" />
      </BlackIntro>
      <BlackP1></BlackP1>
      <BlackIntro>
        <FormattedMessage id="chamberCommerce" />
      </BlackIntro>
      <BlackP1 style={{ marginTop: "-18px" }}>{kvk}</BlackP1>

      <BlackIntro>
        <FormattedMessage id="company" />
      </BlackIntro>
      <BlackP1 style={{ marginTop: "-18px" }}>{companyname}</BlackP1>
      <DetailContainer>
        <div style={{ width: "70%" }}>
          <BlackIntro>
            <FormattedMessage id="contactPerson" />
          </BlackIntro>
        </div>
        <div style={{ width: "30%" }}>
          {!editCompany && (
            <CustomLink
              variant="inline"
              onClick={(e) => {
                e.preventDefault();
                setShowPersonalInfo(false);
                setEditCompany(true);
              }}
            >
              <FormattedMessage id="change" />
            </CustomLink>
          )}
        </div>
      </DetailContainer>
    </React.Fragment>
  ) : null;
};

CompanyHeader.propTypes = {
  isUserCompany: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  setShowPersonalInfo: PropTypes.func.isRequired,
  setEditCompany: PropTypes.func.isRequired,
};

export default CompanyHeader;
