import React from "react";
import { Button, ErrorMessage, List, ListItem } from "@amsterdam/asc-ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import AuthWrapper from "../../components/Wrapper";
import CustomInput from "../../../../components/Input";
import CustomSpinner from "../../../../components/Spinner";

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("passwordRequird")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      "passwordFailedValidation"
    ),
  password2: Yup.string()
    .required("passwordRequird")
    .oneOf([Yup.ref("password"), null], "passwordMatch"),
});

const Wrapper = styled.div`
  width: 70%;
  margin-bottom: 20px;

  @media only screen and (max-width: 468px) {
    width: 100%;
  }
`;

const SetPasswordForm = ({ isloading, error, setPassword, match }) => {
  return (
    <AuthWrapper
      title={<FormattedMessage id="newPassword" />}
      info={<FormattedMessage id="passwordInstruction" />}
    >
      <List variant="bullet">
        {[
          "pwInstcution1",
          "pwInstcution2",
          "pwInstcution3",
          "pwInstcution4",
        ].map((instruction) => (
          <ListItem
            key={instruction}
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              fontFamily: "Avenir Next LT Pro Regular",
            }}
          >
            <FormattedMessage id={instruction} />
          </ListItem>
        ))}
      </List>
      <Formik
        initialValues={{
          password2: "",
          password: "",
        }}
        validationSchema={ResetPasswordSchema}
        onSubmit={(values) => {
          setPassword(values.password, match.params.token);
        }}
      >
        {() => (
          <Form>
            <Wrapper>
              <Field
                name="password"
                component={CustomInput}
                type="password"
                label={<FormattedMessage id="password" />}
                style={{ width: "300px" }}
              />
              <Field
                name="password2"
                type="password"
                component={CustomInput}
                label={<FormattedMessage id="retypePassword" />}
                style={{ width: "300px" }}
              />
            </Wrapper>
            {isloading ? <CustomSpinner size={100} /> : null}
            {error && <ErrorMessage message={error} />}
            <Button variant="secondary" type="submit" disabled={isloading}>
              <FormattedMessage id="send" />
            </Button>
          </Form>
        )}
      </Formik>
    </AuthWrapper>
  );
};

SetPasswordForm.propTypes = {
  isloading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  setPassword: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

SetPasswordForm.defaultProps = {
  error: null,
};

export default SetPasswordForm;
