import { createBrowserHistory } from "history";
import { Route, Redirect } from "react-router-dom";
import decode from "jwt-decode";
import storage from "redux-persist/lib/storage";

import { getToken } from "../services/api/axiosDefault";
import store from "../services/store";

export const checkAuth = () => {
  let token = getToken();
  if (!token) {
    return false;
  }
  try {
    const { exp } = decode(token);
    if (exp < new Date().getTime() / 1000) {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        checkAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
};

export function normalizeData(data) {
  const newData = [];
  /* eslint-disable array-callback-return */
  Object.keys(data).map((key) => {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      newData.push({ ...data[key], id: key });
    }
  });
  return newData;
}

export const clearBrowserStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
  return;
};

export const logUserOut = () => {
  clearBrowserStorage();
  storage.removeItem("persist:root").then(() => (window.location = "/"));
};

export const formatAmountToNL = (amount) =>
  new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 }).format(amount);

export const history = createBrowserHistory();

const PROTOCOL = window.location.protocol;
const PORT = window.location.port;
const HOSTNAME = window.location.hostname;
export const CURRENT_URL = PORT
  ? `${PROTOCOL}//${HOSTNAME}:${PORT}`
  : `${PROTOCOL}//${HOSTNAME}`;

export const getBankNameFromIssuer = (issuer) => {
  const paymentChoices = store.getState().payments.paymentTypes;
  const bankChoices = paymentChoices.filter((p) => p.issuer_list)[0];
  if (bankChoices) {
    return bankChoices.issuer_list.filter((v) => v.DebtorBankId === issuer)[0]
      .DebtorBankName;
  }
};

export const getDriveName = (drives, drive_id) => {
  return drives.filter((drive) => drive.drive_type_id === drive_id)[0]
    ?.drive_type_name;
};

export const persistVignetDecision = (values) => {
  const decisionKeys = Object.keys(values);

  let dataStructure = [];
  let formattedDecision;
  decisionKeys.map((key) => {
    if (values[key].replace(key, "")) {
      formattedDecision = {
        vignet_id: key,
        decision: values[key].replace(key, ""),
      };
      dataStructure.push(formattedDecision);
    }
  });

  localStorage.setItem("decisions", JSON.stringify(dataStructure));
};

export const getVignetStatus = (vignet_number) => {
  const vignets = store.getState().vignets.vignets;
  return vignets?.vignets.filter((v) => v.vignet_number === vignet_number)[0]
    .vignet_status;
};

export const getVignetNumberFromId = (id) => {
  const vignets = store.getState().vignets.vignets;
  return vignets?.vignets.filter((v) => v.vignet_id === id)[0].vignet_number;
};

export const persistSteppersDecision = (values) => {
  // this util is basically for the stepper to list the vignet with its decision
  const decisionKeys = Object.keys(values);

  let dataStructure = [];
  let formattedDecision;
  decisionKeys.map((key) => {
    if (values[key].replace(key, "")) {
      formattedDecision = {
        vignet_number: getVignetNumberFromId(key),
        decision: values[key].replace(key, ""),
      };
      dataStructure.push(formattedDecision);
    }
  });

  localStorage.setItem("steppers_decisions", JSON.stringify(dataStructure));
};

export const getNextAvailableVignet = () => {
  let decisions = localStorage.getItem("decisions");
  if (decisions) {
    decisions = JSON.parse(decisions);
    return decisions[0];
  }
};

export const completeActiveVignetFlow = () => {
  let decisions = localStorage.getItem("decisions");
  if (decisions) {
    decisions = JSON.parse(decisions);
    decisions.shift();
    localStorage.setItem("decisions", JSON.stringify(decisions));
  }
};

export const parseQuery = (queryString) => {
  let query = {};
  let pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
};

export const getPaymentTypeIDFromName = (name) => {
  const paymentTypes = store.getState().payments.paymentTypes;
  return paymentTypes.filter((payment) => payment.name === name)[0].id;
};

export function mergeSchemas(...schemas) {
  const [first, ...rest] = schemas;

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  );

  return merged;
}

export const DBConfig = {
  name: "AmsterdamBHG",
  version: 3,
  objectStoresMeta: [
    {
      store: "photos",
      storeSchema: [],
    },
  ],
};
