import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { history } from "../../../../../utils/helpers";
import { DayVignetLayout } from "../..";
import PaymentOptions from "../../../components/PaymentChoice";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../../../constants";
import TransitStorage from "../../../../../utils/storage";

const PaymentChoice = ({
  isGettingMooringPaymentTypes,
  error,
  mooringPaymentTypes,
  getPaymentTypes,
  validateVoucherCode,
  isValidatingVoucherCode,
  voucherApiError,
}) => {
  useEffect(() => {
    getPaymentTypes();
  }, [getPaymentTypes]);

  const [showCardError, setShowCardError] = useState(false);
  const [showVoucherError, setShowVoucherError] = useState(false);

  const moveToNextPage = (values) => {
    const selectedPaymentChoice = mooringPaymentTypes.filter(
      (payment) => payment.id === values.payment_type_id
    )[0];
    if (selectedPaymentChoice.name.includes("card")) {
      // check if the values include card_service_code
      localStorage.setItem("card_service_code", values.card_service_code);
      if (!values.card_service_code) {
        setShowCardError(true);
        return;
      }
    } else if (selectedPaymentChoice.name?.toLowerCase().includes("voucher")) {
      if (!values.voucher_code) {
        setShowVoucherError(true);
        return;
      }
      validateVoucherCode({ voucher_code: values.voucher_code });
      return;
    }
    setShowCardError(false);
    setShowVoucherError(false);
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/mooring-request/summary`);
  };
  return (
    <DayVignetLayout
      step={3}
      title={<FormattedMessage id="dayStep4" />}
      isLoading={isGettingMooringPaymentTypes || isValidatingVoucherCode}
      error={error}
      hasForm
      initialValues={{ ...TransitStorage.getDayVignettePaymentInfo() }}
      onSubmit={(values) => {
        TransitStorage.persistDayPaymentSchema(values);
        moveToNextPage(values);
      }}
    >
      <PaymentOptions
        showDescription={false}
        paymentTypes={mooringPaymentTypes}
        showCardError={showCardError}
        setShowCardError={setShowCardError}
        showVoucherError={showVoucherError}
        voucherApiError={voucherApiError}
      />
    </DayVignetLayout>
  );
};

export default PaymentChoice;
