import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

import { mergeSchemas } from "../../../../../utils/helpers";
import { ActivateVignetteLayout } from "../..";
import PersonalInfoForm, {
  CompanySchema,
} from "../../../components/personal-info-form";
import TransitStorage from "../../../../../utils/storage";

export const ContactSchema = Yup.object().shape({
  initials: Yup.string().required("required"),
  last_name: Yup.string().required("required"),
  address: Yup.string().required("required"),
  number: Yup.string().required("required"),
  postcode: Yup.string().required("required"),
  city: Yup.string().required("required"),
  country_id: Yup.string().required("required"),
  mobile_phone: Yup.string()
    .required("required")
    .matches(/^[+]?\d+$/, "phoneNumberValidation")
    .max(45, "required"),
  email: Yup.string().email("transitEmailRequired").required("required"),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], "transitEmailNoMatch")
    .required("required"),
});

const PersonalInfo = ({ isValidatingOwner, error, validateOwner }) => {
  const [showCompany, setShowCompany] = useState(
    TransitStorage.getActivatePersonalInfoSchema()?.kvk
  );
  return (
    <ActivateVignetteLayout
      step={1}
      title={<FormattedMessage id="activateVignetteStep2" />}
      subtitle={
        <FormattedMessage
          id="activateVignettePersonalSubtitle"
          values={{ br: <br /> }}
        />
      }
      isLoading={isValidatingOwner}
      error={error}
      hasForm
      initialValues={{ ...TransitStorage.getActivatePersonalInfoSchema() }}
      validationSchema={
        showCompany ? mergeSchemas(ContactSchema, CompanySchema) : ContactSchema
      }
      onSubmit={(values) => {
        if (!showCompany) {
          // We need to remove this from the form as the user has used the checkbox to state that
          // the vignette is not for a company even if they have previously entered info for company info.
          delete values.company;
          delete values.kvk;
        }
        TransitStorage.persistActivatePersonalInfoSchema(values);
        validateOwner(values);
      }}
    >
      <React.Fragment>
        <PersonalInfoForm
          setShowCompany={setShowCompany}
          showCompany={showCompany}
          initialsFieldLabel="dayVignetteFirstName"
          lastNameField="last_name"
          phoneField="mobile_phone"
          readOnly={localStorage.getItem("read_only")}
          defaultCountry={
            TransitStorage.getActivatePersonalInfoSchema()?.country_id
          }
        />
      </React.Fragment>
    </ActivateVignetteLayout>
  );
};

export default PersonalInfo;
