import { connect } from "react-redux";

import { Creators } from "../../../../services/redux/vignets/actions";
import ChooseVignette from "./components";

const mapStateToProps = (state) => {
  const {
    isGettingVignetteTypes,
    error,
    vignetTypes,
    isValidatingPassageVignet,
  } = state.vignets;
  return {
    isGettingVignetteTypes,
    error,
    vignetTypes,
    isValidatingPassageVignet,
    lang: state.app.language,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getVignetteTypes: () => {
      dispatch(Creators.getVignetteTypes());
    },
    validatePassageVignet: (data) => {
      dispatch(Creators.validatePassageVignet(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseVignette);
