import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { useMediaQuery } from "react-responsive";
import { Field } from "formik";
import PropTypes from "prop-types";

import ProtectedLayout from "../..";
import { history } from "../../../../utils/helpers";
import SubmitButton from "../../../../components/Button";
import CompanyHeader from "./companyHeaders";
import CustomLink from "../../../../components/Link/link";
import { CustomRow, DetailContainer } from "../../../../components";
import RenderField from "./inputDisplay";
import CustomInput from "../../../../components/Input";
import {
  BlackIntro,
  BlackP1,
  ForwradBlackH3,
} from "../../../../components/Typography";

const ContactSchema = Yup.object().shape({
  address: Yup.string().required("streetRequired"),
  number: Yup.string().required("houseNumberRequired"),
  postcode: Yup.string().required("postCodeRequired"),
  city: Yup.string().required("residenceRequired"),
  mobilephone: Yup.string()
    .required("phoneRequired")
    .matches(/^[0-9]+$/, "phoneNumberValidation")
    .max(45, "phoneNumberValidation"),
  email: Yup.string().email("invalidEmail").required("emailRequired"),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], "emailMatch")
    .required("repeatEmailRequired"),
});

const CompanySchema = Yup.object().shape({
  initials: Yup.string().required("initialsRequired"),
  lastname: Yup.string().required("lastnameRequired"),
});

const PersonalInfo = ({
  isloading,
  error,
  user,
  isUpdatingError,
  isUpdating,
  updateOwner,
  getOwner,
}) => {
  const [editContact, setEditContact] = useState(false);
  const [showPersonalInfo, setShowPersonalInfo] = useState(true);
  const [editCompany, setEditCompany] = useState(false);
  const [formUser, setFormUser] = useState({});

  useEffect(() => {
    localStorage.removeItem("steppers_decisions");
    getOwner();
    // eslint-disable-next-line
  }, []);

  const isUserCompany = () => {
    return user.companyname && user.kvk;
  };

  const isMobile = useMediaQuery({
    query: "(max-width: 468px)",
  });

  return (
    <ProtectedLayout
      step={1}
      headerTitle={<FormattedMessage id="personalInfoHeader" />}
      title={<FormattedMessage id="personalInfoTitle" />}
      subtitle={<FormattedMessage id="personalInfoSubTitle" />}
      isSubmitting={isUpdating}
      isLoading={isloading || isUpdating}
      error={error || isUpdatingError}
      initialValues={{ ...user, confirmEmail: user.email, ...formUser }}
      validationSchema={editCompany ? CompanySchema : ContactSchema}
      onSubmit={(values) => {
        // if (!values.addition) {
        //   values.addition = user.addition;
        // }
        values.phone = values.mobilephone;

        // we don't need confirmEamil and status in the update-owner api
        delete values.confirmEmail;
        delete values.status;
        setFormUser(values);
        updateOwner(values);
      }}
      hasForm={editCompany || editContact}
    >
      <React.Fragment>
        <CompanyHeader
          isUserCompany={isUserCompany()}
          user={user}
          setShowPersonalInfo={setShowPersonalInfo}
          setEditCompany={setEditCompany}
          editCompany={editCompany}
        />
        {!isUserCompany() ? (
          <React.Fragment>
            <BlackIntro>BSN</BlackIntro>
            <BlackP1 style={{ marginTop: "-15px" }}>{user.bsn}</BlackP1>
          </React.Fragment>
        ) : null}
        <RenderField
          showField={editCompany}
          fieldName="initials"
          value={user.initials}
          headerName="initials"
        />
        <RenderField
          showField={editCompany}
          fieldName="lastname"
          value={user.lastname}
          headerName="lastname"
        />
        {showPersonalInfo ? (
          <React.Fragment>
            <DetailContainer>
              <div style={{ width: "70%" }}>
                <ForwradBlackH3 forwardedAs="h3">
                  <FormattedMessage id="contactDetails" />
                </ForwradBlackH3>
                <BlackP1>
                  <FormattedMessage id="detailsInfo" />
                </BlackP1>
              </div>
              <div style={{ width: "30%" }}>
                {!editContact && (
                  <CustomLink
                    variant="inline"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditContact(true);
                    }}
                  >
                    <FormattedMessage id="change" />
                  </CustomLink>
                )}
              </div>
            </DetailContainer>
            <RenderField
              showField={editContact}
              fieldName="address"
              value={user.address}
              headerName="street"
            />
            {isMobile ? (
              <React.Fragment>
                <RenderField
                  showField={editContact}
                  fieldName="number"
                  value={user.number}
                  headerName="houseNumber"
                />
                <div style={{ width: "95%" }}>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <BlackIntro>
                      <FormattedMessage id="addition" />
                    </BlackIntro>
                    <span style={{ paddingLeft: "10px", marginTop: "5px" }}>
                      <br />
                      (<FormattedMessage id="notRequired" />)
                    </span>
                  </div>
                  {editContact ? (
                    <Field name="addition">
                      {({ field, form }) => (
                        <CustomInput
                          onChange={(e) => {
                            form.setFieldValue("addition", e.target.value);
                            form.values.addition = e.target.value;
                            // form.setValues('addition', e.target.value)
                          }}
                          field={field}
                          form={form}
                        />
                      )}
                    </Field>
                  ) : (
                    <BlackP1>{user.addition}</BlackP1>
                  )}
                </div>
                <RenderField
                  showField={editContact}
                  fieldName="postcode"
                  value={user.number}
                  headerName="postcode"
                />
                <RenderField
                  showField={editContact}
                  fieldName="city"
                  value={user.city}
                  headerName="residence"
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <CustomRow>
                  <DetailContainer>
                    <div style={{ width: "30%", marginRight: "20px" }}>
                      <BlackIntro
                        style={{ marginBottom: editContact && "0px" }}
                      >
                        <FormattedMessage id="houseNumber" />
                      </BlackIntro>
                      <RenderField
                        showField={editContact}
                        fieldName="number"
                        value={user.number}
                        withColumn={false}
                      />
                    </div>
                    <div>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <BlackIntro
                          style={{ marginBottom: editContact && "0px" }}
                        >
                          <FormattedMessage id="addition" />
                        </BlackIntro>
                        <span style={{ paddingLeft: "10px", marginTop: "5px" }}>
                          (<FormattedMessage id="notRequired" />)
                        </span>
                      </div>
                      {editContact ? (
                        <Field name="addition">
                          {({ field, form }) => (
                            <CustomInput
                              onChange={(e) => {
                                form.setFieldValue("addition", e.target.value);
                                form.values.addition = e.target.value;
                                // form.setValues('addition', e.target.value)
                              }}
                              field={field}
                              form={form}
                              style={{ width: "200px" }}
                            />
                          )}
                        </Field>
                      ) : (
                        <BlackP1 style={{ marginTop: "-18px" }}>
                          {user.addition}
                        </BlackP1>
                      )}
                    </div>
                  </DetailContainer>
                </CustomRow>

                <CustomRow>
                  <DetailContainer>
                    <div style={{ width: "30%", marginRight: "20px" }}>
                      <BlackIntro
                        style={{ marginBottom: editContact && "0px" }}
                      >
                        <FormattedMessage id="postcode" />
                      </BlackIntro>
                      <RenderField
                        showField={editContact}
                        fieldName="postcode"
                        value={user.postcode}
                        withColumn={false}
                      />
                    </div>
                    <div>
                      <BlackIntro
                        style={{ marginBottom: editContact && "0px" }}
                      >
                        <FormattedMessage id="residence" />
                      </BlackIntro>
                      <RenderField
                        showField={editContact}
                        fieldName="city"
                        value={user.city}
                        withColumn={false}
                      />
                    </div>
                  </DetailContainer>
                </CustomRow>
              </React.Fragment>
            )}

            <RenderField
              showField={editContact}
              fieldName="mobilephone"
              value={user.mobilephone || user.phone}
              headerName="phone"
            />
            <RenderField
              showField={editContact}
              fieldName="email"
              value={user.email}
              headerName="email-address"
            />
            <RenderField
              showField={editContact}
              fieldName="confirmEmail"
              value={user.email}
              headerName="repeatEmail"
            />
          </React.Fragment>
        ) : null}
        <SubmitButton
          onNext={() => {
            if (editContact || editCompany) return;
            else history.push("/vignets-decision");
          }}
          disabled={isUpdating || isloading}
        />
      </React.Fragment>
    </ProtectedLayout>
  );
};

PersonalInfo.propTypes = {
  isloading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  user: PropTypes.shape({}).isRequired,
  isUpdatingError: PropTypes.string,
  isUpdating: PropTypes.bool.isRequired,
  updateOwner: PropTypes.func.isRequired,
  getOwner: PropTypes.func.isRequired,
};

PersonalInfo.defaultProps = {
  error: null,
  isUpdatingError: null,
};

export default PersonalInfo;
