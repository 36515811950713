import { connect } from "react-redux";

import { Creators } from "../../../../services/redux/vignets/actions";
import Summary from "./components";

const mapStateToProps = (state) => {
  const {
    isActivatingGetDriveTypes,
    activateGetDriveTypes,
    isSavingActivatedData,
    isUploadingActivatingPhotos,
    error,
    isGettingObjectTypes,
    objectTypes,
  } = state.vignets;
  return {
    isActivatingGetDriveTypes,
    activateGetDriveTypes,
    error,
    isSavingActivatedData,
    isUploadingActivatingPhotos,
    isGettingObjectTypes,
    objectTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDriveTypes: () => {
      dispatch(Creators.activateGetDriveTypes());
    },
    getObjectTypes: () => {
      dispatch(Creators.activateGetObjectTypes());
    },
    saveData: (data) => {
      dispatch(Creators.activateSaveData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
