import React from "react";
import { Button, ErrorMessage } from "@amsterdam/asc-ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import PropTypes from "prop-types";

import AuthWrapper from "../../components/Wrapper";
import { FormattedMessage } from "react-intl";
import CustomInput from "../../../../components/Input";
import CustomSpinner from "../../../../components/Spinner";

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("invalidEmail").required("emailRequired"),
});

const Wrapper = styled.div`
  width: 70%;
  margin-bottom: 10px;

  @media only screen and (max-width: 468px) {
    width: 100%;
  }
`;

const ResetPasswordForm = ({ isloading, error, resetPassword }) => {
  return (
    <AuthWrapper
      title={<FormattedMessage id="forgot-password" />}
      info={<FormattedMessage id="forgot-message" />}
    >
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={ResetPasswordSchema}
        onSubmit={(values) => {
          resetPassword(values.email);
        }}
      >
        {() => (
          <Form>
            <Wrapper>
              <Field
                name="email"
                component={CustomInput}
                label={<FormattedMessage id="email-address" />}
                style={{ width: "300px" }}
              />
            </Wrapper>
            {isloading ? <CustomSpinner /> : null}
            {error && <ErrorMessage message={error} />}
            <Button variant="secondary" type="submit" disabled={isloading}>
              <FormattedMessage id="send" />
            </Button>
          </Form>
        )}
      </Formik>
    </AuthWrapper>
  );
};

ResetPasswordForm.propTypes = {
  isloading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  resetPassword: PropTypes.func.isRequired,
};

ResetPasswordForm.defaultProps = {
  error: null,
};

export default ResetPasswordForm;
