import React from "react";
import PropTypes from "prop-types";
import { ChevronDown, Close, Document, Download } from "@amsterdam/asc-assets";
import { Link, Button } from "@amsterdam/asc-ui";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Files from "react-files";

import CustomLink from "../../../../components/Link/link";
import { UploadFileContext } from ".";
import { CustomDivider, CustomColumn } from "../../../../components";
import {
  BlackIntro,
  BlackP1,
  CustomErrorMessage,
} from "../../../../components/Typography";

const UploadWrapper = styled.div`
  background-color: #f5f5f5;
  padding-top: 40px;
  text-align: center;
  height: 100px;
`;

const removedFilesId = [];
const LigbaRemoveFilesId = [];

const removeFile = (fileType, fileId, callback) => {
  if (fileType === "Ligplaatsbewijs") {
    LigbaRemoveFilesId.push(fileId);
  } else {
    removedFilesId.push(fileId);
  }
  callback({ type: `${fileType}.delete`, id: fileId });
};

const RenderUploadedFiles = ({
  filesUploaded,
  callback,
  fileType,
  showDocumentSummary,
}) => {
  return filesUploaded.map((data) => (
    <React.Fragment key={data.id}>
      <BlackP1>
        <Document height={14} /> {data.name}
        {!showDocumentSummary && (
          <Button
            variant="textButton"
            onClick={(e) => {
              e.preventDefault();
              removeFile(fileType, data.id, callback);
            }}
            style={{ float: "right" }}
          >
            <Close height={14} /> &nbsp;&nbsp;
            <FormattedMessage id="toClear" />
          </Button>
        )}
      </BlackP1>
      <CustomDivider style={{ marginTop: "-20px" }} />
    </React.Fragment>
  ));
};

RenderUploadedFiles.propTypes = {
  filesUploaded: PropTypes.shape({}).isRequired,
  callback: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
  showDocumentSummary: PropTypes.bool.isRequired,
};

const DocumentUpload = ({
  vignet,
  showRequiredDocumentError,
  setShowRequiredDocumentError,
  setShowDocumentSummary,
  showDocumentSummary,
  setShowSummary,
  setShowPayment,
  documentRef,
}) => {
  let { state, dispatch } = React.useContext(UploadFileContext);
  return (
    <div style={{ marginTop: "20px" }}>
      <CustomColumn size={12}>
        <BlackIntro style={{ marginBottom: "20px" }}>
          <FormattedMessage id="documents" />
        </BlackIntro>
      </CustomColumn>
      {showDocumentSummary && (
        <CustomLink
          variant="inline"
          style={{ marginTop: "-40px", position: "absolute", right: "50px" }}
          onClick={(e) => {
            e.preventDefault();
            setShowDocumentSummary(false);
            setShowRequiredDocumentError(false);
            setShowSummary(false);
            setShowPayment(false);
          }}
        >
          <FormattedMessage id="change" />
        </CustomLink>
      )}
      {vignet.documents?.map((data) => (
        <React.Fragment key={data}>
          <BlackIntro>
            <FormattedMessage
              id={
                data === "Ligplaatsbewijs"
                  ? "NononTheFingHeader"
                  : "onThefingHeader"
              }
            />
          </BlackIntro>
          {!showDocumentSummary && (
            <React.Fragment>
              <BlackP1 style={{ marginTop: "-20px" }}>
                <FormattedMessage
                  id={
                    data === "Ligplaatsbewijs"
                      ? "NononTheFingDescription"
                      : "onThefindDescription"
                  }
                  values={{ br: <br /> }}
                />
              </BlackP1>
              <UploadWrapper>
                <Files
                  ref={documentRef}
                  className="files-dropzone-list"
                  style={{ height: "100px" }}
                  onChange={(files) => {
                    setShowRequiredDocumentError(false);
                    let ids =
                      data === "Ligplaatsbewijs"
                        ? LigbaRemoveFilesId
                        : removedFilesId;
                    dispatch({
                      type: data,
                      payload: files.filter(
                        (prevFile) => !ids.includes(prevFile.id)
                      ),
                    });
                  }}
                  onError={(err) => console.log("error", err)}
                  multiple
                  accepts={[
                    ".png",
                    ".jpg",
                    ".jpeg",
                    "application/pdf",
                    ".gif",
                    ".doc",
                    ".docx",
                  ]}
                  maxFiles={10}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  <BlackP1>
                    <FormattedMessage id="uploadText" />
                    &nbsp;
                    <Link variant="inline">
                      <FormattedMessage id="selectFiles" />
                    </Link>
                    &nbsp;&nbsp;
                    <Download
                      height={10}
                      size={10}
                      style={{ color: "#004699" }}
                    />
                  </BlackP1>
                </Files>
              </UploadWrapper>
              <BlackP1 style={{ marginTop: "3px" }}>
                <Link variant="inline">
                  <FormattedMessage id="documentUpload" />
                  &nbsp;
                  <ChevronDown
                    height={14}
                    size={14}
                    style={{ marginTop: "5px" }}
                  />
                </Link>
              </BlackP1>
            </React.Fragment>
          )}
          {data === "Ontheffing" && state.Ontheffing && (
            <RenderUploadedFiles
              filesUploaded={state.Ontheffing}
              fileType={data}
              callback={dispatch}
              showDocumentSummary={showDocumentSummary}
            />
          )}
          {data === "Ligplaatsbewijs" && state.Ligplaatsbewijs && (
            <RenderUploadedFiles
              filesUploaded={state.Ligplaatsbewijs}
              fileType={data}
              callback={dispatch}
              showDocumentSummary={showDocumentSummary}
            />
          )}
        </React.Fragment>
      ))}
      {showRequiredDocumentError && (
        <CustomErrorMessage id="requiredDocument" />
      )}
    </div>
  );
};

DocumentUpload.propTypes = {
  vignet: PropTypes.shape({}).isRequired,
  showRequiredDocumentError: PropTypes.bool.isRequired,
  setShowRequiredDocumentError: PropTypes.func.isRequired,
  setShowDocumentSummary: PropTypes.func.isRequired,
  showDocumentSummary: PropTypes.bool.isRequired,
  setShowSummary: PropTypes.func.isRequired,
  setShowPayment: PropTypes.func.isRequired,
};

export default DocumentUpload;
