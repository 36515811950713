import { connect } from "react-redux";

import { Creators } from "../../../../services/redux/payments/actions";
import { Creators as VignetCreators } from "../../../../services/redux/vignets/actions";
import PaymentChoice from "./components";

const mapStateToProps = (state) => {
  const {
    isGettingMooringPaymentTypes,
    error,
    mooringPaymentTypes,
  } = state.payments;
  return {
    isGettingMooringPaymentTypes,
    error,
    mooringPaymentTypes,
    isValidatingVoucherCode: state.vignets.isValidatingVoucherCode,
    voucherApiError: state.vignets.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentTypes: () => {
      dispatch(Creators.getMooringPaymentTypes());
    },
    validateVoucherCode: (data) => {
      dispatch(VignetCreators.validateVoucherCode(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentChoice);
