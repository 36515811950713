import { connect } from "react-redux";

import { Creators } from "../../../../services/redux/vignets/actions";
import DeliveryOptions from "./components";

const mapStateToProps = (state) => {
  const { isGettingDeliveryTypes, error, deliveryTypes } = state.vignets;
  return {
    isGettingDeliveryTypes,
    error,
    deliveryTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveryTypes: (data) => {
      dispatch(Creators.getDeliveryTypes(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOptions);
