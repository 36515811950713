import React from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import mapStyles from "./mapStyles";
import { GOOGLE_MAPS_API_KEY } from "../../../../../services/api/maps";
const libraries = ["places"];
const mapContainerStyle = {
  height: "50vh",
  width: "50vw",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
const center = {
  lat: 52.377956,
  lng: 4.89707,
};

export default function App({ locations }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  var pickup_locations_new = [];

  if (locations && !loaded) {
    for (const element of locations.pickup_locations) {
      element["lat"] = Number(element["Latitude"]);
      element["lng"] = Number(element["Longitude"]);
      pickup_locations_new.push(element);
    }
    setMarkers(pickup_locations_new);
    setLoaded(true);
  }

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(13);
  }, []);

  if (loadError) return "Daar ging iets niet goed!";
  if (!isLoaded) return "Kaart laden...";

  return (
    <div>
      <Locate panTo={panTo} />

      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={12}
        center={center}
        options={options}
        // onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {markers.map((marker) => (
          <Marker
            key={`${marker.lat}-${marker.lng}`}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() => {
              setSelected(marker);
            }}
            icon={{
              url: `/location.png`,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
        ))}

        {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "10px",
              }}
            >
              <h2>{selected.Name}</h2>
              {selected.Website && (
                <>
                  <b style={{ fontWeight: "bold" }}>Website: </b>
                  <a href={selected.Website}>{selected.Website}</a>
                  <br />
                </>
              )}

              <b style={{ fontWeight: "bold" }}>Adres: </b>
              <a
                target="_blank"
                href={String(
                  "https://www.google.com/maps/search/?api=1&query=" +
                    selected.Name +
                    selected.Address +
                    "," +
                    selected.Postcode
                )}
              >
                {selected.Address}, {selected.Postcode}
              </a>

              {selected.Website && (
                <>
                  {" "}
                  <br />
                  <br /> <i>Kijk voor de openingstijden op de website.</i>
                </>
              )}
              <br />
              <br />
              {selected.ExtraText && (
                <>
                  <b>Extra informatie:</b>
                  <br />
                  {selected.ExtraText}
                </>
              )}
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  );
}

function Locate({ panTo }) {
  return (
    <a
      className="locate"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      {/* <img src="/compass.svg" alt="compass" /> */}
      {/* <b>Navigeer naar mijn locatie</b> */}
    </a>
  );
}
