import React from "react";
import PropTypes from "prop-types";

import {
  formatAmountToNL,
  getBankNameFromIssuer,
  getDriveName,
} from "../../../../utils/helpers";
import SummaryPage from "../../Summary";

const SummaryManagement = ({
  vignet,
  driveTypes,
  paymentChoice,
  paymentValues,
  readOnlyMode,
  lang,
  onNext,
  nextTitle,
  disabled,
  children,
}) => {
  const getYesOrNo = (vignet) => {
    if (vignet.object_marina === "0") {
      return lang === "nl" ? "Nee" : "No";
    }

    return lang === "nl" ? "Ja" : "Yes";
  };
  return (
    <SummaryPage
      nextTitle={nextTitle}
      onNext={onNext}
      disabled={disabled}
      readOnlyMode={readOnlyMode}
      vignetProperties={[
        {
          label: "vignetNumber",
          value: vignet.vignet_number,
        },
        {
          label: "boatname",
          value: vignet.object_name,
        },
        {
          label: "drive",
          value: getDriveName(driveTypes, vignet.object_drive_type_id),
        },
        {
          label: "marinaQuestion",
          value: getYesOrNo(vignet),
        },
        {
          label: "vignetType",
          value: `${vignet.vignet_type} ${
            lang === "nl" ? "vignet" : "vignette"
          }`,
        },
        {
          label: "totalCost",
          value: `€ ${formatAmountToNL(vignet.vignet_cost)}`,
        },
      ]}
      isExtend
      payProperties={[
        {
          label: "pay",
          value: paymentChoice,
        },
        {
          ...(paymentValues.issuer && {
            label: "chooseBank",
            value: getBankNameFromIssuer(paymentValues.issuer),
          }),
        },
        {
          ...(paymentValues.account_holder && {
            label: "accountHolder",
            value: paymentValues.account_holder,
          }),
        },
        {
          ...(paymentValues.account_number && {
            label: "accountNumber",
            value: paymentValues.account_number,
          }),
        },
      ]}
    >
      {children}
    </SummaryPage>
  );
};

SummaryManagement.propTypes = {
  vignet: PropTypes.shape({}).isRequired,
  driveTypes: PropTypes.shape({}).isRequired,
  paymentChoice: PropTypes.string.isRequired,
  paymentValues: PropTypes.shape({}),
};

SummaryManagement.defaultProps = {
  paymentValues: {},
};

export default SummaryManagement;
