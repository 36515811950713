import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { history } from "../../../../../utils/helpers";
import { NewVignetteLayout } from "../..";
import PaymentOptions from "../../../components/PaymentChoice";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../../../constants";
import TransitStorage from "../../../../../utils/storage";

const PaymentChoice = ({
  isGettingPaymentTypes,
  error,
  paymentTypes,
  getPaymentTypes,
}) => {
  useEffect(() => {
    getPaymentTypes();
  }, [getPaymentTypes]);

  const [showCardError, setShowCardError] = useState(false);

  const moveToNextPage = (values) => {
    const selectedPaymentChoice = paymentTypes.filter(
      (payment) => payment.id === values.payment_type_id
    )[0];
    if (selectedPaymentChoice.name.includes("card")) {
      // check if the values include card_service_code

      localStorage.setItem("card_service_code", values.card_service_code);
      if (!values.card_service_code) {
        setShowCardError(true);
        return;
      }
    }
    setShowCardError(false);
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/new-vignette/summary`);
  };

  return (
    <NewVignetteLayout
      step={3}
      title={<FormattedMessage id="pay" />}
      isLoading={isGettingPaymentTypes}
      error={error}
      hasForm
      initialValues={{ ...TransitStorage.getNewVignettePaymentInfo() }}
      onSubmit={(values) => {
        TransitStorage.persistPaymentSchema(values);
        moveToNextPage(values);
      }}
    >
      <PaymentOptions
        paymentTypes={paymentTypes}
        showCardError={showCardError}
        setShowCardError={setShowCardError}
      />
    </NewVignetteLayout>
  );
};

export default PaymentChoice;
