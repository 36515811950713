import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useIndexedDB } from "react-indexed-db";

import { history } from "../../../../../utils/helpers";
import { ActivateVignetteLayout } from "../..";
import DownloadSummary from "../../../components/download-summary";
import TransitStorage from "../../../../../utils/storage";

const Done = ({
  isGettingActivateSummary,
  error,
  summaryLink,
  getSummaryLink,
}) => {
  const { clear } = useIndexedDB("photos");
  const { vignetInfo } = useState(
    TransitStorage.getActivateVignetteSchema()?.vignetInfo.vignet
  );

  useEffect(() => {
    clear().then(() => {});
    getSummaryLink();
    // eslint-disable-next-line
  }, [getSummaryLink]);
  return (
    <ActivateVignetteLayout
      step={4}
      title={<FormattedMessage id="dayStep6" />}
      subtitle={
        <FormattedMessage
          values={{ br: <br /> }}
          id="activateVignetteDoneDescription1"
        />
      }
      isLoading={isGettingActivateSummary}
      error={error}
      hasForm
      initialValues={{ agree_1: "", agree_2: "" }}
      onSubmit={(values) => {
        history.push("/mooring-request/done");
      }}
      disabled
    >
      <DownloadSummary
        transitVignette={
          <FormattedMessage
            id="activateTransitFinalDetail"
            values={{
              br: <br />,
              vignet: vignetInfo,
            }}
          />
        }
        summaryLink={summaryLink?.summary_link}
      />
    </ActivateVignetteLayout>
  );
};

export default Done;
