import { createReducer } from "reduxsauce";
import { normalizeData } from "../../../utils/helpers";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  isGettingPaymentTypes: false,
  error: null,
  paymentTypes: [],

  isPayingAccountNumber: false,
  paidAccountNumber: null,
  isPayingAccountNumberError: null,

  isPayingBankPayment: false,
  isPayingBankPaymentError: null,

  isCheckingIbanRequest: false,
  ibanValid: false,
  ibanValidationError: null,

  // TRANSIT VIGNETTE
  isGettingNewPaymentTypes: false,
  newPaymentTypes: null,

  isMakingNewPayment: false,
  mooringPayment: null,

  isGettingMooringPaymentTypes: false,
  mooringPaymentTypes: null,

  isMakingMooringPayment: false,
};

export const paymentTypes = (state = INITIAL_STATE, action) => {
  return { ...state, isGettingPaymentTypes: true, error: false };
};

export const paymentTypesSuccess = (state = INITIAL_STATE, action) => {
  const formattedData = action.responseData.types.map((data) => {
    if (data.issuer_list) {
      data.issuer_list = normalizeData(data.issuer_list);
    }
    return data;
  });
  return {
    ...state,
    isGettingPaymentTypes: false,
    error: null,
    paymentTypes: formattedData,
  };
};

export const paymentTypesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingPaymentTypes: false,
    error: action.error,
  };
};

export const accountNumber = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPayingAccountNumber: true,
    isPayingAccountNumberError: false,
  };
};

export const accountNumberSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPayingAccountNumber: false,
    isPayingAccountNumberError: null,
    paidAccountNumber: action.responseData,
  };
};

export const accountNumberFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPayingAccountNumber: false,
    isPayingAccountNumberError: action.error,
  };
};

export const bankPaymentRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPayingBankPayment: true,
    isPayingBankPaymentError: null,
  };
};

export const bankPaymentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPayingBankPayment: false,
    isPayingBankPaymentError: null,
  };
};

export const bankPaymentFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPayingBankPayment: false,
    isPayingBankPaymentError: action.error,
  };
};

export const checkIbanValidationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCheckingIbanRequest: true,
    ibanValidationError: null,
    ibanValid: false,
  };
};

export const checkIbanValidationSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCheckingIbanRequest: false,
    ibanValidationError: null,
    ibanValid: true,
  };
};

export const checkIbanValidationError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCheckingIbanRequest: false,
    ibanValidationError: action.error,
    ibanValid: false,
  };
};

export const refreshIbanCheck = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ibanValid: false,
    isCheckingIbanRequest: false,
    ibanValidationError: null,
  };
};

// TRANSIT VIGNETTES
export const getPaymentTypes = (state = INITIAL_STATE, action) => {
  return { ...state, isGettingPaymentTypes: true, error: false };
};

export const getPaymentTypesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingPaymentTypes: false,
    error: null,
    paymentTypes: action.responseData,
  };
};

export const getPaymentTypesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingPaymentTypes: false,
    error: action.error,
  };
};

export const makeNewPayment = (state = INITIAL_STATE, action) => {
  return { ...state, isMakingNewPayment: true, error: false };
};

export const makeNewPaymentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isMakingNewPayment: false,
    error: null,
    mooringPayment: action.responseData,
  };
};

export const makeNewPaymentFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isMakingNewPayment: false,
    error: action.error,
  };
};

export const getMooringPaymentTypes = (state = INITIAL_STATE, action) => {
  return { ...state, isGettingMooringPaymentTypes: true, error: false };
};

export const getMooringPaymentTypesSuccess = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isGettingMooringPaymentTypes: false,
    error: null,
    mooringPaymentTypes: action.responseData,
  };
};

export const getMooringPaymentTypesFailure = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isGettingMooringPaymentTypes: false,
    error: action.error,
  };
};

export const makeMooringPayment = (state = INITIAL_STATE, action) => {
  return { ...state, isMakingMooringPayment: true, error: false };
};

export const makeMooringPaymentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isMakingMooringPayment: false,
    error: null,
  };
};

export const makeMooringPaymentFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isMakingMooringPayment: false,
    error: action.error,
  };
};

export const HANDLERS = {
  [Types.GET_PAYMENT_TYPES_REQUEST]: paymentTypes,
  [Types.GET_PAYMENT_TYPES_SUCCESS]: paymentTypesSuccess,
  [Types.GET_PAYMENT_TYPES_FAILURE]: paymentTypesFailure,

  [Types.ACCOUNT_NUMBER_PAYMENT_REQUEST]: accountNumber,
  [Types.ACCOUNT_NUMBER_PAYMENT_SUCCESS]: accountNumberSuccess,
  [Types.ACCOUNT_NUMBER_PAYMENT_FAILURE]: accountNumberFailure,

  [Types.BANK_PAYMENT_REQUEST]: bankPaymentRequest,
  [Types.BANK_PAYMENT_SUCCESS]: bankPaymentSuccess,
  [Types.BANK_PAYMENT_FAILURE]: bankPaymentFailure,

  [Types.CHECK_IBAN_VALIDATION_REQUEST]: checkIbanValidationRequest,
  [Types.CHECK_IBAN_VALIDATION_SUCCESS]: checkIbanValidationSuccess,
  [Types.CHECK_IBAN_VALIDATION_FAILURE]: checkIbanValidationError,

  [Types.REFRESH_IBAN_VALIDATION]: refreshIbanCheck,

  // TRANSIT VIGNETTES
  [Types.GET_NEW_PAYMENT_TYPES]: getPaymentTypes,
  [Types.GET_NEW_PAYMENT_TYPES_SUCCESS]: getPaymentTypesSuccess,
  [Types.GET_NEW_PAYMENT_TYPES_FAILURE]: getPaymentTypesFailure,

  [Types.MAKE_NEW_PAYMENT]: makeNewPayment,
  [Types.MAKE_NEW_PAYMENT_SUCCESS]: makeNewPaymentSuccess,
  [Types.MAKE_NEW_PAYMENT_FAILURE]: makeNewPaymentFailure,

  [Types.GET_MOORING_PAYMENT_TYPES]: getMooringPaymentTypes,
  [Types.GET_MOORING_PAYMENT_TYPES_SUCCESS]: getMooringPaymentTypesSuccess,
  [Types.GET_MOORING_PAYMENT_TYPES_FAILURE]: getMooringPaymentTypesFailure,

  [Types.MOORING_MAKE_PAYMENT]: makeMooringPayment,
  [Types.MOORING_MAKE_PAYMENT_SUCCESS]: makeMooringPaymentSuccess,
  [Types.MOORING_MAKE_PAYMENT_FAILURE]: makeMooringPaymentFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
