import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import ChooseVignette from "./ChooseVignette";
import PersonalInfo from "./PersonalInfo";
import DeliveryOptions from "./DeliveryOptions";
import PaymentChoice from "./PaymentChoice";
import Summary from "./Summary";
import Done from "./Done";
import AppLayout from "..";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../constants";

const steps = [
  {
    label: "newVignetteStep1",
    route: `${TRANSIT_VIGNETTE_ROUTE}/new-vignette`,
  },
  {
    label: "newVignetteStep2",
    route: `${TRANSIT_VIGNETTE_ROUTE}/new-vignette/personal-info`,
  },
  {
    label: "newVignetteStep3",
    route: `${TRANSIT_VIGNETTE_ROUTE}/new-vignette/delivery-options`,
  },
  {
    label: "newVignetteStep4",
    route: `${TRANSIT_VIGNETTE_ROUTE}/new-vignette/payment-choice`,
  },
  {
    label: "newVignetteStep5",
    route: `${TRANSIT_VIGNETTE_ROUTE}/new-vignette/summary`,
  },
  {
    label: "newVignetteStep6",
    route: `${TRANSIT_VIGNETTE_ROUTE}/new-vignette/done`,
  },
];

export const NewVignetteLayout = ({
  step,
  title,
  subtitle,
  children,
  ...rest
}) => {
  useEffect(() => {
    if (step > 0 && !localStorage.getItem("newVignette")) {
      window.location = `${TRANSIT_VIGNETTE_ROUTE}/new-vignette`;
      return;
    }
    if (step === 5) {
      localStorage.removeItem("newVignette");
    }
  }, [step]);

  return (
    <AppLayout
      headerTitle={<FormattedMessage id="newVignetteTitle" />}
      step={step}
      steps={steps}
      title={title}
      subtitle={subtitle}
      {...rest}
    >
      {children}
    </AppLayout>
  );
};

const NewVignetteRoute = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={ChooseVignette} />
      <Route
        exact
        path={`${match.url}/personal-info`}
        component={PersonalInfo}
      />
      <Route
        exact
        path={`${match.url}/delivery-options`}
        component={DeliveryOptions}
      />
      <Route
        exact
        path={`${match.url}/payment-choice`}
        component={PaymentChoice}
      />
      <Route exact path={`${match.url}/summary`} component={Summary} />
      <Route exact path={`${match.url}/done`} component={Done} />
    </Switch>
  );
};

export default NewVignetteRoute;
