import { call, put, takeLeading } from "redux-saga/effects";
import { Creators, Types } from "../../redux/profile/actions";
import {
  getOwnerDetails as getOwnerDetailsApi,
  updateOwnerDetails as updateOwnerDetailsApi,
} from "../../api/profile";
import { history } from "../../../utils/helpers";

export function* getOwnerDetails(actions) {
  try {
    const response = yield call(getOwnerDetailsApi);
    yield put(Creators.getOwnerSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getOwnerFailure(error_message));
  }
}

export function* watchGetOwner() {
  yield takeLeading(Types.GET_OWNER_REQUEST, getOwnerDetails);
}

export function* updateOwnerDetails(actions) {
  try {
    const { data } = actions;
    const response = yield call(updateOwnerDetailsApi, { ...data });
    yield put(Creators.updateOwnerSuccess(response.data));
    history.push("/vignets-decision");
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.updateOwnerFailure(error_message));
  }
}

export function* watchUpdateOwner() {
  yield takeLeading(Types.UPDATE_OWNER_REQUEST, updateOwnerDetails);
}
