import { call, put, takeLeading } from "redux-saga/effects";
import { Creators, Types } from "../../redux/vignets/actions";
import { Creators as PaymentCreators } from "../../redux/payments/actions";
import {
  getVignetDetails as getVignetDetailsApi,
  cancelVignet as cancelVignetApi,
  getVignet as getVignetApi,
  getDriveTypes as getDriveTypesApi,
  updateVignet as updateVignetApi,
  uploadDocuments as uploadDocumentsApi,
  getSummaryPDFLink as getSummaryPDFLinkApi,

  // TRANSIT VIGNETTES
  getVignetteTypes as getVignetteTypesApi,
  getDeliveryTypes as getDeliveryTypesApi,
  checkMooringVignet as checkMooringVignetApi,
  validatePassageOwner as validatePassageOwnerApi,
  validatePassageVignet as validatePassageVignetApi,
  validateVoucherCode as validateVoucherCodeApi,

  // ACTIVATE VIGNETTES
  activateCheckVignet as activateCheckVignetApi,
  activateGetDriveTypes as activateGetDriveTypesApi,
  activateUpdateVignet as activateUpdateVignetApi,
  activateUpdateOwner as activateUpdateOwnerApi,
  activateValidateVignet as activateValidateVignetApi,
  activateValidateOwner as activateValidateOwnerApi,
  transitUploadDocuments as transitUploadDocumentsApi,
  activateSaveData as activateSaveDataApi,
  activateGetSummary as activateGetSummaryApi,
  newVignetteCountries as newVignetteCountriesApi,
  mooringSummary as mooringSummaryApi,
  newVignetteSummary as newVignetteSummaryApi,
  activateGetObjectTypes as activateGetObjectTypesApi,
} from "../../api/vignets";
import {
  completeActiveVignetFlow,
  getNextAvailableVignet,
  history,
} from "../../../utils/helpers";
import TransitStorage from "../../../utils/storage";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../constants";

export function* getVignets(actions) {
  try {
    const response = yield call(getVignetDetailsApi);
    yield put(Creators.getVignetsSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getVignetsFailure(error_message));
  }
}

export function* watchGetVignets() {
  yield takeLeading(Types.GET_VIGNETS_REQUEST, getVignets);
}

export function* cancelVignet(actions) {
  try {
    const { data } = actions;
    const response = yield call(cancelVignetApi, { ...data });
    yield put(Creators.cancelVignetSuccess(response.data));
    completeActiveVignetFlow();
    const nextVignet = getNextAvailableVignet();
    if (nextVignet) {
      window.location = `/push-decision?vignet_id=${nextVignet.vignet_id}&decision=${nextVignet.decision}`;
    } else window.location = "/done";
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.cancelVignetFailure(error_message));
  }
}

export function* watchCancelVignet() {
  yield takeLeading(Types.CANCEL_VIGNET_REQUEST, cancelVignet);
}

export function* getVignet(actions) {
  try {
    const { vignet_id } = actions;
    const response = yield call(getVignetApi, { vignet_id });
    yield put(Creators.getVignetSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getVignetFailure(error_message));
  }
}

export function* watchGetVignet() {
  yield takeLeading(Types.GET_VIGNET_REQUEST, getVignet);
}

export function* getDriveTypes(actions) {
  try {
    const response = yield call(getDriveTypesApi);
    yield put(Creators.getDriveTypesSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getDriveTypesFailure(error_message));
  }
}

export function* watchGetDriveTypes() {
  yield takeLeading(Types.GET_DRIVE_TYPES_REQUEST, getDriveTypes);
}

export function* updateVignet(actions) {
  try {
    const { data } = actions;
    const response = yield call(updateVignetApi, { ...data });
    yield put(Creators.updateVignetSuccess(response.data));
    yield put(Creators.getVignetRequest(data.vignet_id));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.updateVignetFailure(error_message));
  }
}

export function* watchUpdateVignet() {
  yield takeLeading(Types.UPDATE_VIGNET_REQUEST, updateVignet);
}

export function* uploadDocument(actions) {
  try {
    const { data } = actions;
    const response = yield call(uploadDocumentsApi, { data });
    yield put(Creators.uploadDocumentsSuccess(response.data));
    // push generic payment. it uses same api has account number
    yield put(PaymentCreators.accountNumberPaymentRequest(data.values));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.uploadDocumentsFailure(error_message));
  }
}

export function* watchUploadDocument() {
  yield takeLeading(Types.UPLOAD_DOCUMENTS_REQUEST, uploadDocument);
}

export function* downloadSummary(actions) {
  try {
    const response = yield call(getSummaryPDFLinkApi);
    yield put(Creators.getSummaryLinkSuccess(response.data.summary_link));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getSummaryLinkFailure(error_message));
  }
}

export function* watchDownloadSummary() {
  yield takeLeading(Types.GET_SUMMARY_LINK_REQUEST, downloadSummary);
}

// TRANSIT VIGNETTES
export function* getVignetTypes(actions) {
  try {
    const response = yield call(getVignetteTypesApi);
    yield put(Creators.getVignetteTypesSuccess(response.data.vignet_types));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getVignetteTypesFailure(error_message));
  }
}

export function* watchGetVignetTypes() {
  yield takeLeading(Types.GET_VIGNETTE_TYPES, getVignetTypes);
}

export function* getDeliveryTypes(actions) {
  try {
    const { data } = actions;
    const response = yield call(getDeliveryTypesApi, data);
    yield put(Creators.getDeliveryTypesSuccess(response.data.delivery_types));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getDeliveryTypesFailure(error_message));
  }
}

export function* watchGetDeliveryTypes() {
  yield takeLeading(Types.GET_DELIVERY_TYPES, getDeliveryTypes);
}

export function* activateGetObjectTypes(actions) {
  try {
    const response = yield call(activateGetObjectTypesApi);
    yield put(
      Creators.activateGetObjectTypesSuccess(response.data.object_types)
    );
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.activateGetObjectTypesFailure(error_message));
  }
}

export function* watchactivateGetObjectTypes() {
  yield takeLeading(Types.ACTIVATE_GET_OBJECT_TYPES, activateGetObjectTypes);
}

export function* checkMooringVignet(actions) {
  try {
    const { data } = actions;
    const response = yield call(checkMooringVignetApi, { ...data });
    yield put(Creators.checkMooringVignetteSuccess(response.data));
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/mooring-request/payment-guide`);
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.checkMooringVignetteFailure(error_message));
  }
}

export function* watchCheckMooringVignette() {
  yield takeLeading(Types.CHECK_MOORING_VIGNETTE, checkMooringVignet);
}

// ACTIVATE VIGNETTES
export function* activateCheckVignet(actions) {
  try {
    const { data } = actions;
    const response = yield call(activateCheckVignetApi, { ...data });
    yield put(Creators.activateCheckVignetteSuccess(response.data));
    localStorage.setItem("access_token", response.data.access_token);
    localStorage.setItem("photos_required", !!response.data.photos_required);
    localStorage.setItem("photos_count", response.data.photos_count);
    const fields = response.data.fields;
    if (fields.email) {
      // it means user already has info and should be a read only view alone
      delete response.data.access_token;

      const boatDetails = {
        object_drive_type_id: fields.object_drive_type_id.value,
        object_length: fields.object_length.value,
        object_width: fields.object_width.value,
        object_name: fields.object_name.value,
        object_type_id: fields.object_type_id.value,
      };
      TransitStorage.persistActivateBoatDetailsSchema(boatDetails);
      const personalInfo = {
        addition: fields.addition.value,
        address: fields.address.value,
        bsn: fields.bsn.value,
        city: fields.city.value,
        company_name: fields.company_name.value,
        country_id: fields.country_id.value,
        email: fields.email.value,
        initials: fields.initials.value,
        kvk: fields.kvk.value,
        last_name: fields.last_name.value,
        mobile_phone: fields.mobile_phone.value,
        number: fields.number.value,
        postcode: fields.postcode.value,
        prefix: fields.prefix.value,
        vignet_type: fields.vignet_type.value,
      };
      personalInfo.confirmEmail = fields.email.value;
      TransitStorage.persistActivatePersonalInfoSchema(personalInfo);
      TransitStorage.persistActivateFieldsSchema(fields);
      localStorage.setItem("read_only", true);
    }
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/personal-info`);
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.activateCheckVignetteFailure(error_message));
  }
}

export function* watchActivateCheckVignette() {
  yield takeLeading(Types.ACTIVATE_CHECK_VIGNETTE, activateCheckVignet);
}

export function* activateGetDriveTypes(actions) {
  try {
    const response = yield call(activateGetDriveTypesApi);
    yield put(Creators.activateGetDriveTypesSuccess(response.data.drive_types));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.activateGetDriveTypesFailure(error_message));
  }
}

export function* watchActivateGetDriveTypes() {
  yield takeLeading(Types.ACTIVATE_GET_DRIVE_TYPES, activateGetDriveTypes);
}

export function* activateUpdateOwner(actions) {
  try {
    const { data } = actions;
    yield call(activateUpdateOwnerApi, { ...data });
    yield put(Creators.activateUpdateOwnerSuccess());
    yield put(
      Creators.activateUpdateVignet(
        TransitStorage.getActivateBoatDetailsSchema()
      )
    );
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.activateUpdateOwnerFailure(error_message));
  }
}

export function* watchActivateUpdateOwner() {
  yield takeLeading(Types.ACTIVATE_UPDATE_OWNER, activateUpdateOwner);
}

export function* activateUpdateVignet(actions) {
  try {
    const { data } = actions;
    yield call(activateUpdateVignetApi, { ...data });
    yield put(Creators.activateUpdateVignetSuccess());
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/done`);
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.activateUpdateVignetFailure(error_message));
  }
}

export function* watchActivateUpdateVignet() {
  yield takeLeading(Types.ACTIVATE_UPDATE_VIGNET, activateUpdateVignet);
}

export function* activateValidateVignet(actions) {
  try {
    const { data } = actions;
    yield call(activateValidateVignetApi, { ...data });
    yield put(Creators.activateValidateVignetSuccess());
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/summary`);
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.activateValidateVignetFailure(error_message));
  }
}

export function* watchActivateValidateVignet() {
  yield takeLeading(Types.ACTIVATE_VALIDATE_VIGNET, activateValidateVignet);
}

export function* activateValidateOwner(actions) {
  try {
    const { data } = actions;
    yield call(activateValidateOwnerApi, { ...data });
    yield put(Creators.activateValidateOwnerSuccess());
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/vignette`);
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.activateValidateOwnerFailure(error_message));
  }
}

export function* watchActivateValidateOwner() {
  yield takeLeading(Types.ACTIVATE_VALIDATE_OWNER, activateValidateOwner);
}

export function* activateUploadPhotos(actions) {
  try {
    const { data } = actions;
    yield call(transitUploadDocumentsApi, { ...data });
    yield put(Creators.activateUploadDocumentsSuccess());
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/done`);
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.activateUploadDocumentsFailure(error_message));
  }
}

export function* watchActivateUploadPhotos() {
  yield takeLeading(Types.ACTIVATE_UPLOAD_DOCUMENTS, activateUploadPhotos);
}

export function* validatePassageOwner(actions) {
  try {
    const { data } = actions;
    yield call(validatePassageOwnerApi, { ...data });
    yield put(Creators.validatePassageOwnerSuccess());
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/new-vignette/delivery-options`);
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.validatePassageOwnerFailure(error_message));
  }
}

export function* watchValidatePassageOwner() {
  yield takeLeading(Types.VALIDATE_PASSAGE_OWNER, validatePassageOwner);
}

export function* validatePassageVignet(actions) {
  try {
    const { data } = actions;
    yield call(validatePassageVignetApi, { ...data });
    yield put(Creators.validatePassageVignetSuccess());
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/new-vignette/personal-info`);
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.validatePassageVignetFailure(error_message));
  }
}

export function* watchValidatePassageVignet() {
  yield takeLeading(Types.VALIDATE_PASSAGE_VIGNET, validatePassageVignet);
}

export function* activateSaveData(actions) {
  try {
    const { data } = actions;
    yield call(activateSaveDataApi, { ...data });
    yield put(Creators.activateSaveDataSuccess());
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/done`);
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.activateSaveDataFailure(error_message));
  }
}

export function* watchActivateSaveData() {
  yield takeLeading(Types.ACTIVATE_SAVE_DATA, activateSaveData);
}

export function* activateGetSummary(actions) {
  try {
    const response = yield call(activateGetSummaryApi);
    yield put(Creators.activateGetSummarySuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.activateGetSummaryFailure(error_message));
  }
}

export function* watchActivateGetSummary() {
  yield takeLeading(Types.ACTIVATE_GET_SUMMARY, activateGetSummary);
}

export function* newVignetteCountries(actions) {
  try {
    const response = yield call(newVignetteCountriesApi);
    yield put(Creators.newVignetteCountriesSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.newVignetteCountriesFailure(error_message));
  }
}

export function* watchGetCountries() {
  yield takeLeading(Types.NEW_VIGNETTE_COUNTRIES, newVignetteCountries);
}

export function* mooringSummary(actions) {
  try {
    const response = yield call(mooringSummaryApi);
    yield put(Creators.mooringSummarySuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.mooringSummaryFailure(error_message));
  }
}

export function* watchGetMooringSummary() {
  yield takeLeading(Types.MOORING_SUMMARY, mooringSummary);
}

export function* newVignetteSummary(actions) {
  try {
    const response = yield call(newVignetteSummaryApi);
    yield put(Creators.newVignetteSummarySuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.newVignetteSummaryFailure(error_message));
  }
}

export function* watchGetNewVignetteSummary() {
  yield takeLeading(Types.NEW_VIGNETTE_SUMMARY, newVignetteSummary);
}

export function* validateVoucherCode(actions) {
  try {
    const { data } = actions;
    const response = yield call(validateVoucherCodeApi, { ...data });
    yield put(Creators.validateVoucherCodeSuccess(response.data));
    history.push(`${TRANSIT_VIGNETTE_ROUTE}/mooring-request/summary`);
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.validateVoucherCodeFailure(error_message));
  }
}

export function* watchValidateVoucherCode() {
  yield takeLeading(Types.VALIDATE_VOUCHER_CODE, validateVoucherCode);
}
