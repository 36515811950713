import { createTypes } from "reduxsauce";

export default createTypes(
  `
  GET_PAYMENT_TYPES_REQUEST
  GET_PAYMENT_TYPES_SUCCESS
  GET_PAYMENT_TYPES_FAILURE

  ACCOUNT_NUMBER_PAYMENT_REQUEST
  ACCOUNT_NUMBER_PAYMENT_SUCCESS
  ACCOUNT_NUMBER_PAYMENT_FAILURE

  BANK_PAYMENT_REQUEST
  BANK_PAYMENT_SUCCESS
  BANK_PAYMENT_FAILURE

  BANK_PAYMENT_REQUEST
  BANK_PAYMENT_SUCCESS
  BANK_PAYMENT_FAILURE

  CHECK_IBAN_VALIDATION_REQUEST
  CHECK_IBAN_VALIDATION_SUCCESS
  CHECK_IBAN_VALIDATION_FAILURE

  REFRESH_IBAN_VALIDATION





  GET_NEW_PAYMENT_TYPES
  GET_NEW_PAYMENT_TYPES_SUCCESS
  GET_NEW_PAYMENT_TYPES_FAILURE

  MAKE_NEW_PAYMENT
  MAKE_NEW_PAYMENT_SUCCESS
  MAKE_NEW_PAYMENT_FAILURE

  GET_MOORING_PAYMENT_TYPES
  GET_MOORING_PAYMENT_TYPES_SUCCESS
  GET_MOORING_PAYMENT_TYPES_FAILURE

  MOORING_MAKE_PAYMENT
  MOORING_MAKE_PAYMENT_SUCCESS
  MOORING_MAKE_PAYMENT_FAILURE
`,
  {}
);
