import { createTypes } from "reduxsauce";

export default createTypes(
  `
  GET_OWNER_REQUEST
  GET_OWNER_SUCCESS
  GET_OWNER_FAILURE

  UPDATE_OWNER_REQUEST
  UPDATE_OWNER_SUCCESS
  UPDATE_OWNER_FAILURE
`,
  {}
);
