import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { Field } from "formik";
import { Select } from "@amsterdam/asc-ui";
import styled from "styled-components";
import { connect } from "react-redux";

import SubmitButton from "../../../components/Button";
import CustomInput, { Label as InputLabel } from "../../../components/Input";
import { DetailContainer } from "../../../components";
import { Creators } from "../../../services/redux/vignets/actions";
import { CustomErrorMessage } from "../../../components/Typography";
import { SelectWrapper } from "../ActivateVignette/VignetDetails/components";
import CustomSpinner from "../../../components/Spinner";
import TransitStorage from "../../../utils/storage";

export const ContactSchema = Yup.object().shape({
  initials: Yup.string().required("required"),
  lastname: Yup.string().required("required"),
  address: Yup.string().required("required"),
  number: Yup.string().required("required"),
  postcode: Yup.string().required("required"),
  city: Yup.string().required("required"),
  bsn: Yup.string(),
  country_id: Yup.string().min(2).required("required"),
  phonenumber: Yup.string()
    .required("required")
    .matches(/^[+]?\d+$/, "phoneNumberValidation")
    .max(45, "required"),
  email: Yup.string().email("transitEmailRequired").required("required"),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], "transitEmailNoMatch")
    .required("required"),
});

export const CompanySchema = Yup.object().shape({
  kvk: Yup.string().required("required"),
  company: Yup.string().required("required"),
});

const FormWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const SelectContainer = styled.div`
  width: 300px;
`;

const PersonalInput = styled(CustomInput)`
  width: 300px;
`;

const PersonalSplitWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

const PersonalSplitInput = styled(CustomInput)`
  width: 140px;
`;

const NotRequiredLabel = styled.span`
  padding-left: 10px;
  margin-top: 5px;
`;

const PersonalInfoForm = ({
  setShowCompany,
  showCompany,
  readOnly,
  getCountries,
  isGettingCountries,
  lang,
  countries,
  defaultCountry,
  initialsFieldLabel = "initials",
  lastNameField = "lastname",
  phoneField = "phonenumber",
}) => {
  const [selectedCountry, setSelectedCountry] = useState(
    localStorage.getItem("country")
  );
  const fields = TransitStorage.getActivateFieldsSchema();
  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    if (fields?.country_id?.value && countries.length > 0 && !selectedCountry) {
      const country = countries?.filter(
        (data) => data.id === fields.country_id.value
      )[0]?.name;
      if (country) {
        localStorage.setItem("country", country);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  const memoizedHandleSumbit = useCallback(() => {
    return;
  }, []);

  if (isGettingCountries) {
    return <CustomSpinner />;
  }
  return (
    <React.Fragment>
      <FormWrapper>
        {showCompany ? (
          <>
            <Field
              name={"company"}
              label={<FormattedMessage id="companyDetails" />}
              component={PersonalInput}
            />
            <Field
              name={"kvk"}
              label={<FormattedMessage id="chamberCommerce" />}
              component={PersonalInput}
            />
          </>
        ) : null}
        <Field
          name={"initials"}
          label={<FormattedMessage id={initialsFieldLabel} />}
          component={PersonalInput}
          disabled={fields && fields?.["initials"]?.isEditable === false}
        />
        <Field
          name={lastNameField}
          label={<FormattedMessage id="lastname" />}
          component={PersonalInput}
          disabled={fields && fields?.[lastNameField]?.isEditable === false}
        />
        <div>
          <PersonalSplitWrapper>
            <InputLabel htmlFor={"insertion"}>
              <FormattedMessage id="insertion" />
            </InputLabel>
            <NotRequiredLabel>
              (<FormattedMessage id="notRequired" />)
            </NotRequiredLabel>
          </PersonalSplitWrapper>
          <Field
            name={"prefix"}
            component={PersonalInput}
            disabled={fields && fields?.["prefix"]?.isEditable === false}
          />
        </div>
        <Field
          name={"address"}
          label={<FormattedMessage id="street" />}
          component={PersonalInput}
          disabled={fields && fields?.["address"]?.isEditable === false}
        />

        <DetailContainer>
          <div className="mr-20">
            <InputLabel htmlFor={"number"}>
              <FormattedMessage id="houseNumber" />
            </InputLabel>
            <Field
              name={"number"}
              component={PersonalSplitInput}
              disabled={fields && fields?.["number"]?.isEditable === false}
            />
          </div>
          <div>
            <PersonalSplitWrapper>
              <InputLabel htmlFor={"addition"}>
                <FormattedMessage id="addition" />
              </InputLabel>
              <NotRequiredLabel>
                (<FormattedMessage id="notRequired" />)
              </NotRequiredLabel>
            </PersonalSplitWrapper>
            <Field
              name={"addition"}
              component={PersonalSplitInput}
              disabled={fields && fields?.["addition"]?.isEditable === false}
            />
          </div>
        </DetailContainer>
        <DetailContainer>
          <div className="mr-20">
            <InputLabel htmlFor={"postcode"}>
              <FormattedMessage id="postcode" />
            </InputLabel>
            <Field
              name={"postcode"}
              component={PersonalSplitInput}
              disabled={fields && fields?.["postcode"]?.isEditable === false}
            />
          </div>
          <div>
            <InputLabel htmlFor={"residence"}>
              <FormattedMessage id="residence" />
            </InputLabel>
            <Field
              name={"city"}
              component={PersonalSplitInput}
              disabled={fields && fields?.["city"]?.isEditable === false}
            />
          </div>
        </DetailContainer>
        <InputLabel>
          <FormattedMessage id="country" />
        </InputLabel>
        <SelectContainer>
          <Field name="country_id">
            {({ field, form }) => (
              <SelectWrapper className="mb-20">
                <Select
                  id="country_id"
                  defaultValue={defaultCountry}
                  onChange={(e) => {
                    if (e.target.value) {
                      const country = countries?.filter(
                        (country) => country.id === e.target.value
                      )[0].name;

                      localStorage.setItem("country", country);

                      setSelectedCountry(country);
                    }
                    form.setFieldValue("country_id", e.target.value);
                  }}
                  error={form.errors.country_id}
                  disabled={
                    fields && fields?.["country_id"]?.isEditable === false
                  }
                >
                  <option value={""}>
                    {lang === "nl" ? "Selecteer land" : "Select country"}
                  </option>
                  {countries?.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </Select>
                {form.errors.country_id && form.touched.country_id && (
                  <div className="mt-10">
                    <CustomErrorMessage id="selectChoice" />
                  </div>
                )}
              </SelectWrapper>
            )}
          </Field>
        </SelectContainer>
        {selectedCountry === "Nederland" && (
          <Field
            name={"bsn"}
            required
            label={<FormattedMessage id="bsn" />}
            component={PersonalInput}
            disabled={fields && fields?.["bsn"]?.isEditable === false}
          />
        )}
        <Field
          name={phoneField}
          label={<FormattedMessage id="telephone" />}
          component={PersonalInput}
          disabled={fields && fields?.[phoneField]?.isEditable === false}
        />
        <Field
          name={"email"}
          label={<FormattedMessage id="email" />}
          component={PersonalInput}
          disabled={fields && fields?.["email"]?.isEditable === false}
        />
        <Field
          name={"confirmEmail"}
          label={<FormattedMessage id="repeatEmail" />}
          component={PersonalInput}
          disabled={fields && fields?.["confirmEmail"]?.isEditable === false}
        />
      </FormWrapper>
      <SubmitButton onNext={memoizedHandleSumbit} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { countries, isGettingCountries, countryError } = state.vignets;
  return {
    countries,
    isGettingCountries,
    error: countryError,
    lang: state.app.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => {
      dispatch(Creators.newVignetteCountries());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoForm);
