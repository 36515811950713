import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { Field } from "formik";
import styled from "styled-components";

import { BlackP1 } from "../../../../../components/Typography";
import CustomInput from "../../../../../components/Input";
import SubmitButton from "../../../../../components/Button";
import VignetImage from "../../../../../assets/amsterdam.png";
import { DayVignetLayout } from "../..";
import { CustomRow, CustomColumn } from "../../../../../components";
import TransitStorage from "../../../../../utils/storage";

const Image = styled.img`
  width: 500px;
  height: 250px;
  margin-top: 40px;
  object-fit: contain;
`;

const VerificationInput = styled(CustomInput)`
  width: 300px;
`;

const VignetSchema = Yup.object().shape({
  vignet: Yup.string().required("required"),
  repeatVignetNumber: Yup.string()
    .required("required")
    .oneOf([Yup.ref("vignet"), null], "transitVignetteNoMatch"),
});

const VignetVerification = ({
  isCheckingMooringVignette,
  error,
  checkMooringVignette,
}) => {
  const memoizedHandleSumbit = useCallback(() => {
    return;
  }, []);

  return (
    <DayVignetLayout
      step={0}
      title={<FormattedMessage id="dayVignetteVerificationTitle" />}
      subtitle={
        <FormattedMessage id="dayVignetteDescription" values={{ br: <br /> }} />
      }
      isLoading={isCheckingMooringVignette}
      error={error}
      hasForm
      initialValues={{ ...TransitStorage.getDayVignetteDetailsSchema() }}
      validationSchema={VignetSchema}
      onSubmit={(values) => {
        TransitStorage.persistDayVignetteDetailsSchema(values);
        checkMooringVignette(values);
      }}
    >
      <React.Fragment>
        <Field
          name="vignet"
          component={VerificationInput}
          label={<FormattedMessage id="dayVignetteNumber" />}
        />
        <Field
          name="repeatVignetNumber"
          component={VerificationInput}
          label={
            <FormattedMessage
              values={{ br: <br /> }}
              id="repeatvignetteNumber"
            />
          }
        />
        <CustomRow>
          <CustomColumn size={3}>
            <Image src={VignetImage} alt="vignette" />
          </CustomColumn>
        </CustomRow>
        <BlackP1 style={{ marginTop: "8px" }}>
          <FormattedMessage
            id="vignettePrivacyStatement"
            values={{
              link: (chunks) => (
                <a
                  href="https://www.amsterdam.nl/privacy/specifieke/privacyverklaring-parkeren-verkeer-bouw/verkeersmanagement-gracht-privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </BlackP1>
        <SubmitButton onNext={memoizedHandleSumbit} showPrevious={false} />
      </React.Fragment>
    </DayVignetLayout>
  );
};

export default VignetVerification;
