import React, { useEffect, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useIndexedDB } from "react-indexed-db";

import SubmitButton from "../../../../../components/Button";
import { ActivateVignetteLayout } from "../..";
import SummaryDetails from "../../../components/Summary";
import TransitStorage from "../../../../../utils/storage";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../../../constants";
import { UPLOAD_ACTIVATE_PHOTOS } from "../../../../../services/api/transit-urls";
import { transitApiFileRequest } from "../../../../../services/api/apiRequest";

const Summary = ({
  isActivatingGetDriveTypes,
  activateGetDriveTypes,
  error,
  getDriveTypes,
  saveData,
  isSavingActivatedData,
  isUploadingActivatingPhotos,
  isGettingObjectTypes,
  getObjectTypes,
  objectTypes,
}) => {
  const [photos, setPhotos] = useState();
  const { getAll } = useIndexedDB("photos");
  useEffect(() => {
    getDriveTypes();
    getAll().then((data) => {
      // const files = data.map((obj) => obj.file);
      setPhotos(data);
    });
    // eslint-disable-next-line
  }, [getDriveTypes]);

  useEffect(() => {
    getObjectTypes();
  }, [getObjectTypes]);

  const memoizedHandleSumbit = useCallback(() => {
    return;
  }, []);
  const user = TransitStorage.getActivatePersonalInfoSchema();
  const vingetInfo = TransitStorage.getActivateBoatDetailsSchema();

  let submittingData = false;

  const summaryData = [
    {
      title: "step1",
      route: `${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/personal-info`,
      info: [
        {
          label: "dayVignetteFirstName",
          value: user.initials,
        },
        {
          label: "lastname",
          value: user.last_name,
        },
        {
          label: "insertion",
          value: user?.prefix,
        },
        {
          label: "street",
          value: user.address,
        },
        {
          label: "houseNumber",
          value: user.number,
        },
        {
          label: "addition",
          value: user.addition,
        },
        {
          label: "postcode",
          value: user.postcode,
        },
        {
          label: "residence",
          value: user.city,
        },
        {
          label: "country",
          value: localStorage.getItem("country") || "Nederland",
        },
        {
          label: "phone",
          value: user.mobile_phone,
        },
        {
          label: "email",
          value: user.email,
        },
      ],
    },
    {
      title: "activateVignetteStep3",
      route: `${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/vignette`,
      info: [
        {
          label: "length",
          value: vingetInfo.object_length + " meter",
        },
        {
          label: "width",
          value: vingetInfo.object_width + " meter",
        },
        {
          label: "vesselType",
          value: objectTypes?.filter(
            (data) => data.id === vingetInfo.object_type_id
          )[0]?.name,
        },
        {
          label: "boatName2",
          value: vingetInfo.object_name,
        },
        {
          label: "drive",
          value: activateGetDriveTypes?.filter(
            (drive) => drive.id === vingetInfo.object_drive_type_id
          )[0]?.name,
        },
      ],
    },
  ];

  if (user.company) {
    summaryData.unshift({
      title: "activateVignetteSummaryCompany",
      route: `${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/personal-info`,
      info: [
        {
          label: "companyDetails",
          value: user.company,
        },
        {
          label: "chamberCommerce",
          value: user.kvk,
        },
      ],
    });
  }
  return (
    <ActivateVignetteLayout
      step={3}
      title={<FormattedMessage id="dayStep5" />}
      subtitle={
        <FormattedMessage
          id="activateVignetteSummaryDescription"
          values={{
            vignet_number: TransitStorage.getActivateVignetteSchema()
              ?.vignetInfo.vignet,
          }}
        />
      }
      isLoading={
        isActivatingGetDriveTypes ||
        isSavingActivatedData ||
        isUploadingActivatingPhotos ||
        isGettingObjectTypes
      }
      error={error}
      hasForm
      initialValues={{ agree_1: "", agree_2: "" }}
      onSubmit={(values) => {
        if (!submittingData) {
          submittingData = true;
          transitApiFileRequest("POST", UPLOAD_ACTIVATE_PHOTOS, photos).finally(
            () => {
              saveData({ ...user, ...vingetInfo });
              submittingData = false;
            }
          );
        }
      }}
    >
      <React.Fragment>
        <SummaryDetails data={summaryData} />
        <SubmitButton
          taskflow={false}
          onNext={memoizedHandleSumbit}
          nextTitle="sendNow"
        />
      </React.Fragment>
    </ActivateVignetteLayout>
  );
};

export default Summary;
