import React from "react";
import { Link } from "@amsterdam/asc-ui";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import AuthWrapper from "../../components/Wrapper";
import { history } from "../../../../utils/helpers";

const Wrapper = styled.div`
  width: 70%;
  margin-bottom: 10px;

  @media only screen and (max-width: 468px) {
    width: 100%;
  }
`;

const SetPasswordFeedback = (props) => {
  return (
    <AuthWrapper
      title={<FormattedMessage id="setPasswordChanged" />}
      info={<FormattedMessage id="setPasswordChangedMessage" />}
    >
      <Wrapper>
        <Link
          variant="inline"
          style={{ fontFamily: "Avenir Next LT Pro Regular" }}
          onClick={(e) => {
            e.preventDefault();
            history.push("/");
          }}
        >
          <FormattedMessage id="backToLogin" />
        </Link>
      </Wrapper>
    </AuthWrapper>
  );
};

export default SetPasswordFeedback;
