import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Layout from "../../../components/Layouts";
import AuthBackgroundImage from "../../../assets/iname.jpg";
import { BlackH1, BlackP1 } from "../../../components/Typography";

const CardWrapper = styled.div`
  justify-content: center;
  background-color: #fff;
  padding: 40px 9rem;
  margin-top: 100px;
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-30%, 0%);

  @media only screen and (max-width: 468px) {
    padding: 15px;
    width: 100%;
    position: relative;
    top: 0px;
  }
`;

const CustomHeader = styled(BlackH1)`
  padding-bottom: 1rem;
  font-weight: 500;
`;

const Image = styled.img`
  width: 100%;
  height: 350px;

  @media only screen and (max-width: 468px) {
    height: 0px;
  }
`;

const MobileImage = styled.img`
  @media only screen and (max-width: 468px) {
    width: calc(100% + 30px);
    margin-top: -100px;
    margin-left: -30px;
    padding-bottom: 20px;
  }
  @media only screen and (min-width: 468px) {
    height: 0px;
  }
`;

const Container = styled.div`
  position: relative;
  margin-bottom: 50%;

  @media only screen and (max-width: 468px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const AuthWrapper = (props) => {
  const { title, info, children } = props;
  return (
    <Layout>
      <Container>
        <CardWrapper>
          <MobileImage src={AuthBackgroundImage} alt="background image" />
          <CustomHeader forwardedAs="h1">{title}</CustomHeader>
          <BlackP1>{info}</BlackP1>
          {children}
        </CardWrapper>
        <Image src={AuthBackgroundImage} alt="background image" />
      </Container>
    </Layout>
  );
};

AuthWrapper.propTypes = {
  title: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default AuthWrapper;
