import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { checkAuth } from "../../../utils/helpers";
import VignetteVerification from "./VignetteActivation";
import PersonalInfo from "./PersonalInfo";
import VignetDetails from "./VignetDetails";
import Summary from "./Summary";
import Done from "./Done";
import AppLayout from "..";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../constants";
import TransitStorage from "../../../utils/storage";

const steps = [
  {
    label: "activateVignetteStep1",
    route: `${TRANSIT_VIGNETTE_ROUTE}/activate-vignette`,
  },
  {
    label: "activateVignetteStep2",
    route: `${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/personal-info`,
  },
  {
    label: "activateVignetteStep3",
    route: `${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/vignette`,
  },
  {
    label: "newVignetteStep5",
    route: `${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/summary`,
  },
  {
    label: "newVignetteStep6",
    route: `${TRANSIT_VIGNETTE_ROUTE}/activate-vignette/done`,
  },
];

export const ActivateVignetteLayout = ({
  step,
  title,
  subtitle,
  children,
  ...rest
}) => {
  useEffect(() => {
    const vignet = TransitStorage.getActivateVignetteSchema()?.vignetInfo
      .vignet;
    if (step > 0 && (!checkAuth() || !vignet)) {
      window.location = `${TRANSIT_VIGNETTE_ROUTE}/activate-vignette`;
      return;
    }
    if (step === 4) {
      localStorage.removeItem("activate");
    }
  }, [step]);
  return (
    <AppLayout
      headerTitle={<FormattedMessage id="activateVignetteTitle" />}
      step={step}
      steps={steps}
      title={title}
      subtitle={subtitle}
      {...rest}
    >
      {children}
    </AppLayout>
  );
};

const ActivateVignetteRoute = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={VignetteVerification} />
      <Route
        exact
        path={`${match.url}/personal-info`}
        component={PersonalInfo}
      />
      <Route exact path={`${match.url}/vignette`} component={VignetDetails} />
      <Route exact path={`${match.url}/summary`} component={Summary} />
      <Route exact path={`${match.url}/done`} component={Done} />
    </Switch>
  );
};

export default ActivateVignetteRoute;
