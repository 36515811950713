import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { DayVignetLayout } from "../..";
import DownloadSummary from "../../../components/download-summary";
import { BlackH2, BlackP1 } from "../../../../../components/Typography";

const Done = ({
  getSummaryLink,
  summaryLink,
  isGettingSummaryLink,
  summaryLinkError,
}) => {
  useEffect(() => {
    getSummaryLink();
  }, [getSummaryLink]);
  return (
    <DayVignetLayout
      step={5}
      title={<FormattedMessage id="dayStep6" />}
      subtitle={
        <FormattedMessage
          id="newVignetteSummaryDescription"
          values={{ br: <br /> }}
        />
      }
      isLoading={isGettingSummaryLink}
      error={summaryLinkError}
      disabled
    >
      <>
        <BlackH2 as="h2" style={{ marginTop: "-20px" }}>
          <FormattedMessage id="step4" />
        </BlackH2>
        <BlackP1 style={{ marginBottom: "50px" }}>
          <FormattedMessage
            id="newVignetteSummaryInfo"
            values={{ br: <br /> }}
          />
        </BlackP1>
        <DownloadSummary
          summaryLink={summaryLink?.summary_link}
          transitVignette={
            <FormattedMessage
              id="newVignetteTransitFinalDetail"
              values={{
                br: <br />,
                vignet: localStorage.getItem("request_number"),
              }}
            />
          }
        />
      </>
    </DayVignetLayout>
  );
};

export default Done;
