import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { NewVignetteLayout } from "../..";
import DownloadSummary from "../../../components/download-summary";

const Done = ({
  getSummaryLink,
  summaryLink,
  isGettingSummaryLink,
  summaryLinkError,
}) => {
  useEffect(() => {
    getSummaryLink();
  }, [getSummaryLink]);
  return (
    <NewVignetteLayout
      step={5}
      title={<FormattedMessage id="dayStep6" />}
      subtitle={
        <FormattedMessage
          values={{ br: <br /> }}
          id="transitDownloadSubTitle"
        />
      }
      isLoading={isGettingSummaryLink}
      error={summaryLinkError}
      disabled
    >
      <DownloadSummary summaryLink={summaryLink?.summary_link} />
    </NewVignetteLayout>
  );
};

export default Done;
