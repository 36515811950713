import { Spinner, themeColor } from "@amsterdam/asc-ui";
import styled from "styled-components";

const BHGSPinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const CustomSpinner = () => {
  return <BHGSPinner color={themeColor("secondary")} size={100} />;
};

export default CustomSpinner;
