import {
  apiFileRequest,
  apiRequest,
  transitApiRequest,
  transitApiFileRequest,
} from "../apiRequest";
import {
  CANCEL_VIGNET,
  GET_VIGNET_DETAILS,
  GET_VIGNET,
  GET_DRIVE_TYPES,
  UPDATE_VIGNET,
  UPLOAD_DOCUMENTS,
  DOWNLOAD_SUMMARY,
} from "../urls";
import {
  GET_VIGNETTE_TYPES,
  GET_DELIVERY_TYPES,
  ACTIVATE_GET_OBJECT_TYPES,
  CHECK_MOORING_VIGNET,
  CHECK_ACTIVATE_VIGNET,
  GET_ACTIVATE_DRIVE_TYPES,
  UPDATE_ACTIVATE_VIGNET,
  UPDATE_ACTIVATE_OWNER,
  VALIDATE_ACTIVATE_VIGNET,
  VALIDATE_ACTIVATE_OWNER,
  UPLOAD_ACTIVATE_PHOTOS,
  VALIDATE_PASSAGE_OWNER,
  VALIDATE_PASSAGE_VIGNET,
  ACTIVATE_SAVE_DATA,
  ACTIVATE_DOWNLOAD_SUMMARY,
  NEW_VIGNETTE_GET_COUNTRIES,
  MOORING_SUMMARY,
  NEW_VIGNETTE_SUMMARY,
  VALIDATE_VOUCHER_CODE,
} from "../transit-urls";

export const getVignetDetails = () => {
  return apiRequest("GET", GET_VIGNET_DETAILS, null);
};

export const cancelVignet = (data) => {
  return apiRequest("POST", CANCEL_VIGNET, data);
};

export const getVignet = (data) => {
  return apiRequest("POST", GET_VIGNET, data);
};

export const getDriveTypes = () => {
  return apiRequest("GET", GET_DRIVE_TYPES, null);
};

export const updateVignet = (data) => {
  return apiRequest("POST", UPDATE_VIGNET, data);
};

export const uploadDocuments = (data) => {
  return apiFileRequest("POST", UPLOAD_DOCUMENTS, data);
};

export const getSummaryPDFLink = () => {
  return apiRequest("GET", DOWNLOAD_SUMMARY, null);
};

// TRANSIT VIGNETTES
export const getVignetteTypes = () => {
  return transitApiRequest("GET", GET_VIGNETTE_TYPES, null);
};

export const getDeliveryTypes = (data) => {
  return transitApiRequest("POST", GET_DELIVERY_TYPES, data);
};

export const activateGetObjectTypes = () => {
  return transitApiRequest("GET", ACTIVATE_GET_OBJECT_TYPES, null);
};

// MOORING APIS
export const checkMooringVignet = (data) => {
  return transitApiRequest("POST", CHECK_MOORING_VIGNET, data);
};

// PASSAGE APIS
export const activateCheckVignet = (data) => {
  return transitApiRequest("POST", CHECK_ACTIVATE_VIGNET, data);
};

export const activateGetDriveTypes = () => {
  return transitApiRequest("GET", GET_ACTIVATE_DRIVE_TYPES, null);
};

export const activateUpdateVignet = (data) => {
  return transitApiRequest("POST", UPDATE_ACTIVATE_VIGNET, data);
};

export const activateUpdateOwner = (data) => {
  return transitApiRequest("POST", UPDATE_ACTIVATE_OWNER, data);
};

export const activateValidateVignet = (data) => {
  return transitApiRequest("POST", VALIDATE_ACTIVATE_VIGNET, data);
};

export const activateValidateOwner = (data) => {
  return transitApiRequest("POST", VALIDATE_ACTIVATE_OWNER, data);
};

export const transitUploadDocuments = (data) => {
  return transitApiFileRequest("POST", UPLOAD_ACTIVATE_PHOTOS, data);
};

export const validatePassageOwner = (data) => {
  return transitApiRequest("POST", VALIDATE_PASSAGE_OWNER, data);
};

export const validatePassageVignet = (data) => {
  return transitApiRequest("POST", VALIDATE_PASSAGE_VIGNET, data);
};

export const activateSaveData = (data) => {
  return transitApiRequest("POST", ACTIVATE_SAVE_DATA, data);
};

export const activateGetSummary = () => {
  return transitApiRequest("GET", ACTIVATE_DOWNLOAD_SUMMARY, null);
};

export const newVignetteCountries = () => {
  return transitApiRequest("GET", NEW_VIGNETTE_GET_COUNTRIES, null);
};

export const mooringSummary = () => {
  return transitApiRequest("GET", MOORING_SUMMARY, null);
};

export const newVignetteSummary = () => {
  return transitApiRequest("GET", NEW_VIGNETTE_SUMMARY, null);
};

export const validateVoucherCode = (data) => {
  return transitApiRequest("POST", VALIDATE_VOUCHER_CODE, data);
};
