import { connect } from "react-redux";

import { Creators } from "../../../services/redux/auth/actions";
import ResetPassword from "./components";

const mapStateToProps = (state) => {
  const { resettingPassword, resetPasswordError } = state.auth;
  return {
    isloading: resettingPassword,
    error: resetPasswordError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (email) => {
      dispatch(Creators.resetPasswordRequest(email));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
