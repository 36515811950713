import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { BlackIntro, BlackP1 } from "../Typography";

export const Label = styled(BlackIntro)`
  margin: 0px;
  padding-bottom: 5px;
`;

const Input = styled.input`
  appearance: none;
  font-size: 16px;
  border: solid 1px #767676;
  border-radius: 0;
  box-sizing: border-box;
  line-height: 18px;
  padding: 10px;
  width: 100%;
  height: 44px;
  margin-bottom: 1rem;
  margin-top: 0.2rem;
  ${({ error }) =>
    !error &&
    `
    &:hover {
      border-color: #323232;
    }
  `}
  ${({ error }) => error && ` border-color: #ec0000;`}
`;

const CustomInput = ({
  label,
  field,
  form: { touched, errors },
  isSpecial,
  ...rest
}) => {
  return (
    <React.Fragment>
      <Label htmlFor={field.name}>{label}</Label>
      {isSpecial ? (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Input
              {...field}
              {...rest}
              error={touched[field.name] && errors[field.name]}
            />

            <BlackP1 style={{ marginTop: "10px", marginLeft: "8px" }}>
              meter
            </BlackP1>
          </div>
        </>
      ) : (
        <Input
          {...field}
          {...rest}
          error={touched[field.name] && errors[field.name]}
        />
      )}
      {touched[field.name] && errors[field.name] && (
        <BlackP1
          style={{
            marginTop: "-12px",
            marginBottom: "4px",
            color: "#EC0000",
            fontWeight: "500",
            fontFamily: "Avenir Next LT Pro Demi",
          }}
        >
          <FormattedMessage id={errors[field.name]} />
        </BlackP1>
      )}
    </React.Fragment>
  );
};

export default CustomInput;
