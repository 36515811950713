import { connect } from "react-redux";

import { Creators } from "../../../../services/redux/vignets/actions";
import Done from "./components";

const mapStateToProps = (state) => {
  const { isGettingActivateSummary, error, summaryLink } = state.vignets;
  return {
    isGettingActivateSummary,
    error,
    summaryLink,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSummaryLink: () => {
      dispatch(Creators.activateGetSummary());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Done);
