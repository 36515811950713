import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { Select, Label, Radio } from "@amsterdam/asc-ui";
import { FormattedMessage } from "react-intl";

import { CustomErrorMessage } from "../../../../components/Typography";
import { CustomRadioGroup } from "../../../../components";

export const DriveDropDown = ({ vignet, driveTypes }) => {
  return (
    <Field name="object_drive_type_id">
      {({ field, form }) => (
        <div style={{ marginTop: "-20px", width: "250px" }}>
          <Select
            id="object_drive_type_id"
            defaultValue={vignet.object_drive_type_id}
            onChange={(e) =>
              form.setFieldValue("object_drive_type_id", e.target.value)
            }
            error={form.errors.object_drive_type_id}
          >
            <option value="">Selecteer Aandrijving</option>
            {driveTypes.map((data) => (
              <option key={data.drive_type_id} value={data.drive_type_id}>
                {data.drive_type_name}
              </option>
            ))}
          </Select>
          {form.errors.object_drive_type_id && (
            <div style={{ marginTop: "10px" }}>
              <CustomErrorMessage id="selectChoice" />
            </div>
          )}
        </div>
      )}
    </Field>
  );
};

DriveDropDown.propTypes = {
  vignet: PropTypes.shape({}).isRequired,
  driveTypes: PropTypes.shape({}).isRequired,
};

export const MarinaChoice = () => {
  return (
    <Field name="object_marina">
      {({ field, form }) => (
        <CustomRadioGroup name={field.name} style={{ marginTop: "-20px" }}>
          <Label htmlFor="1" label={<FormattedMessage id="yes" />}>
            <Radio
              required={true}
              onChange={(e) => form.setFieldValue(field.name, "1")}
              id={"1"}
              checked={field.value === "1"}
              variant="tertiary"
              value={"1"}
            />
          </Label>
          <div style={{ marginLeft: "5px" }} />
          <Label htmlFor="0" label={<FormattedMessage id="no" />}>
            <Radio
              required={true}
              onChange={(e) => form.setFieldValue(field.name, "0")}
              id={"0"}
              checked={field.value === "0"}
              variant="tertiary"
              value={"0"}
            />
          </Label>
        </CustomRadioGroup>
      )}
    </Field>
  );
};
