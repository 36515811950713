import React from "react";
import PropTypes from "prop-types";
import { Label, Checkbox, Alert } from "@amsterdam/asc-ui";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";

import { CustomColumn, CustomRow } from "../../../../components";
import CustomSpinner from "../../../../components/Spinner";
import {
  BlackH3,
  BlackP1,
  BlackH2,
  BlackIntro,
  CustomErrorMessage,
  ForwradBlackH3,
} from "../../../../components/Typography";
import SubmitButton from "../../../../components/Button";
import { history } from "../../../../utils/helpers";
import { Checkmark } from "@amsterdam/asc-assets";

const CheckboxWrapper = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  margin: 20px 20px 20px 0px;
`;

const PaymentSuccessWrapper = styled(Alert)`
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid #00a03c;
  width: 60%;
  margin-top: 20px;
`;

const SuccessfulPaymentFeedback = () => {
  return (
    <PaymentSuccessWrapper>
      <BlackIntro style={{ marginBottom: "0px", display: "inline" }}>
        <Checkmark
          height={22}
          size={40}
          color="#00A03C"
          style={{
            display: "inline",
            color: "#00A03C",
            paddingTop: "10px",
            paddingRight: "10px",
          }}
        />
        <FormattedMessage id="paymentSuccessfulTitle" />
      </BlackIntro>
      <BlackP1 style={{ marginLeft: "20px" }}>
        <FormattedMessage id="paymentSuccessfulDescription" />
      </BlackP1>
    </PaymentSuccessWrapper>
  );
};

const RenderDetails = ({ label, value }) => (
  <CustomRow>
    <CustomColumn size={3}>
      <BlackP1 style={{ marginBottom: "0px" }}>
        <FormattedMessage id={label} />
      </BlackP1>
    </CustomColumn>
    <CustomColumn size={6}>
      <BlackIntro style={{ marginBottom: "14px" }}>{value}</BlackIntro>
    </CustomColumn>
  </CustomRow>
);

const SummaryPage = ({
  isloading,
  user,
  vignetProperties,
  payProperties,
  isExtend,
  nextTitle,
  onNext,
  disabled,
  readOnlyMode,
  children,
}) => {
  if (isloading) {
    return <CustomSpinner />;
  }

  return (
    <Formik
      initialValues={{
        agree_1: readOnlyMode ? "yes" : "",
        agree_2: readOnlyMode ? "yes" : "",
      }}
      onSubmit={onNext}
    >
      <Form>
        <CustomRow>
          <CustomColumn size={4}>
            <BlackH2 forwardedAs="h3">
              <FormattedMessage id="verifyInfo" />
            </BlackH2>
          </CustomColumn>
        </CustomRow>
        <CustomRow>
          <CustomColumn size={6}>
            <BlackP1 style={{ marginTop: "15px" }}>
              <FormattedMessage id="summaryDetail" />
            </BlackP1>
          </CustomColumn>
        </CustomRow>
        <BlackH3
          forwardedAs="h4"
          style={{ marginTop: 10, marginBottom: "10px" }}
        >
          <FormattedMessage id="personalInfoTitle" />
        </BlackH3>
        {[
          {
            label: "BSN",
            value: user.bsn,
          },
          {
            label: "initials",
            value: user.initials,
          },
          {
            label: "lastname",
            value: user.lastname,
          },
        ].map((data) => (
          <RenderDetails
            key={data.label}
            label={data.label}
            value={data.value}
          />
        ))}
        <ForwradBlackH3
          forwardedAs="h4"
          style={{ marginBottom: "12px", marginTop: "5px" }}
        >
          <FormattedMessage id="contactDetails" />
        </ForwradBlackH3>
        {[
          {
            label: "street",
            value: user.address,
          },
          {
            label: "houseNumber",
            value: user.number,
          },
          {
            label: "addition",
            value: user.addition,
          },
          {
            label: "postcode",
            value: user.postcode,
          },
          {
            label: "residence",
            value: user.city,
          },

          {
            label: "phone",
            value: user.mobilephone,
          },
          {
            label: "email-address",
            value: user.email,
          },
        ].map((data) => (
          <RenderDetails
            key={data.label}
            label={data.label}
            value={data.value}
          />
        ))}
        <ForwradBlackH3
          forwardedAs="h4"
          style={{ marginBottom: "12px", marginTop: "5px" }}
        >
          <FormattedMessage id={isExtend ? "vignetDetails" : "step3"} />
        </ForwradBlackH3>
        {vignetProperties.map((data) => (
          <RenderDetails
            key={data.label}
            label={data.label}
            value={data.value}
          />
        ))}
        {isExtend ? (
          <>
            <ForwradBlackH3
              forwardedAs="h4"
              style={{ marginBottom: "12px", marginTop: "5px" }}
            >
              <FormattedMessage id="paymentMethod" />
            </ForwradBlackH3>
            {payProperties.map((data) => {
              if (!data.label) return null;
              return (
                <RenderDetails
                  key={data.label}
                  label={data.label}
                  value={data.value}
                />
              );
            })}
          </>
        ) : null}

        <CustomRow>
          <CustomColumn size={6}>
            <CheckboxWrapper>
              <Field
                name="agree_1"
                validate={(value) => {
                  if (!value) return "you must agree";
                }}
              >
                {({ field, form }) => (
                  <React.Fragment>
                    <Label
                      htmlFor="agree_1"
                      label={<FormattedMessage id="checkbox1" />}
                    >
                      <Checkbox
                        id="agree_1"
                        name="agree_1"
                        onChange={(e) =>
                          readOnlyMode
                            ? null
                            : form.setFieldValue(
                                "agree_1",
                                form.values.agree_1 !== "yes" ? "yes" : ""
                              )
                        }
                        checked={field.value === "yes"}
                        value={"yes"}
                      />
                    </Label>
                    {form.errors.agree_1 && form.touched.agree_1 && (
                      <CustomErrorMessage id="cancellationRequired" />
                    )}
                  </React.Fragment>
                )}
              </Field>
            </CheckboxWrapper>
          </CustomColumn>
        </CustomRow>
        <CustomRow>
          <CustomColumn size={6}>
            <CheckboxWrapper>
              <Field
                name="agree_2"
                validate={(value) => {
                  if (!value) return "you must agree";
                }}
              >
                {({ field, form }) => (
                  <React.Fragment>
                    <Label
                      htmlFor="agree_2"
                      label={<FormattedMessage id="checkbox2" />}
                    >
                      <Checkbox
                        id="agree_2"
                        name="agree_2"
                        onChange={(e) =>
                          readOnlyMode
                            ? null
                            : form.setFieldValue(
                                "agree_2",
                                form.values.agree_2 !== "yes" ? "yes" : ""
                              )
                        }
                        checked={field.value === "yes"}
                        value={"yes"}
                      />
                    </Label>
                    {form.errors.agree_2 && form.touched.agree_2 && (
                      <CustomErrorMessage id="cancellationRequired" />
                    )}
                  </React.Fragment>
                )}
              </Field>
            </CheckboxWrapper>
            {children}
          </CustomColumn>
          {readOnlyMode && <SuccessfulPaymentFeedback />}
        </CustomRow>
        <SubmitButton
          nextTitle={readOnlyMode ? "next" : nextTitle}
          onNext={() => null}
          onPrevious={() =>
            readOnlyMode ? history.push("/vignets-decision") : history.goBack()
          }
          disabled={disabled}
        />
      </Form>
    </Formik>
  );
};

SummaryPage.propTypes = {
  isloading: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  vignetProperties: PropTypes.shape({}).isRequired,
  isExtend: PropTypes.bool,
  payProperties: PropTypes.shape({}),
};

SummaryPage.defaultProps = {
  isExtend: false,
  payProperties: {},
};

export default SummaryPage;
