import { connect } from "react-redux";

import { Creators } from "../../../../services/redux/vignets/actions";
import PersonalInfo from "./components";

const mapStateToProps = (state) => {
  const { isValidatingOwner, error } = state.vignets;
  return {
    isValidatingOwner,
    error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    validateOwner: (data) => {
      dispatch(Creators.activateValidateOwner(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
