import React from "react";
import { Button } from "@amsterdam/asc-ui";
import { FormattedMessage } from "react-intl";

import { BlackH2, BlackP1 } from "../../../components/Typography";

const DownloadSummary = ({ summaryLink, transitVignette }) => {
  return (
    <>
      <Button
        variant="primary"
        onClick={(e) => {
          e.preventDefault();
          window.location = summaryLink;
        }}
        className="mt-inverse-20"
      >
        <React.Fragment>
          <FormattedMessage id="download" />
          <FormattedMessage id="resume" />
        </React.Fragment>
      </Button>

      <BlackH2 as="h2" className="mt-20">
        <FormattedMessage id="ask" />?
      </BlackH2>
      <BlackP1>{transitVignette}</BlackP1>
    </>
  );
};

DownloadSummary.defaultProps = {
  transitVignette: (
    <FormattedMessage
      values={{ br: <br />, vignet: localStorage.getItem("request_number") }}
      id="transitFinalDetail"
    />
  ),
};
export default DownloadSummary;
