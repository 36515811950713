import { connect } from "react-redux";

import { Creators } from "../../../../services/redux/vignets/actions";
import VignetteDetails from "./components";

const mapStateToProps = (state) => {
  const {
    isActivatingGetDriveTypes,
    activateGetDriveTypes,
    error,
    isValidatingActivateVignet,
    isUploadingActivatingPhotos,
    isGettingObjectTypes,
    objectTypes,
  } = state.vignets;
  return {
    isActivatingGetDriveTypes,
    activateGetDriveTypes,
    error,
    isValidatingActivateVignet,
    isUploadingActivatingPhotos,
    isGettingObjectTypes,
    objectTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDriveTypes: () => {
      dispatch(Creators.activateGetDriveTypes());
    },
    getObjectTypes: () => {
      dispatch(Creators.activateGetObjectTypes());
    },
    validateVignet: (data, photos) => {
      dispatch(Creators.activateValidateVignet(data, photos));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VignetteDetails);
