import { Checkmark, ChevronDown, ChevronUp } from "@amsterdam/asc-assets";
import React from "react";
import styled from "styled-components";

import StepByStepItemStyle, {
  BackgroundStyle,
  CircleStyle,
  CircleWrapperStyle,
  ContentWrapperStyle,
} from "./StepByStepItemStyle";
import StepByStepTitle from "./StepByStepTitle";

export const STEPBYSTEPITEM = "step-by-step-item";

const CustomChevronUp = styled(ChevronUp)`
  padding-left: 10px;
  padding-top: 5px;
  color: #767676;
`;

const CustomCheveronDown = styled(ChevronDown)`
  padding-left: 10px;
  padding-top: 5px;
  color: #767676;
`;

const StepByStepItem = ({
  active,
  checked,
  children,
  circleBackgroundColor,
  customSize,
  disabled,
  disabledTextColor,
  done: doneProp,
  doneTextColor,
  heading,
  headingProps,
  highlightActive,
  href,
  largeCircle,
  onClick,
  dropDownUp,
  vignet,
  ...otherProps
}) => {
  const clickable = !!(href || onClick);

  // The `checked` item is `done`, but the `active` item is not `done`
  const done = (doneProp || checked) && !active;

  // All "inactive" items are `small` by default, except when `customSize` has been set
  const small = (!customSize && !active) || (customSize && !largeCircle);

  // @TODO: also enable the ENTER key to act as onClick
  const handleOnClick = (event) => {
    onClick && onClick(event);
  };

  return (
    <StepByStepItemStyle
      {...{
        active,
        clickable,
        disabled,
        disabledTextColor,
        done,
        doneTextColor,
        heading,
        highlightActive,
        href,
      }}
      data-testid={STEPBYSTEPITEM}
      aria-label={clickable ? heading : ""}
      as={clickable ? "a" : "div"}
      onClick={handleOnClick}
      role={clickable ? "menuitem" : ""}
      tabIndex={clickable && !disabled && !active ? 0 : -1}
      {...otherProps}
    >
      {active && highlightActive && <BackgroundStyle />}
      <CircleWrapperStyle {...{ done }}>
        <CircleStyle
          size={13}
          {...{ active, checked, circleBackgroundColor, done, small, vignet }}
        >
          {checked && <Checkmark />}
        </CircleStyle>
      </CircleWrapperStyle>
      <ContentWrapperStyle>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: active ? "-15px" : "-25px",
          }}
        >
          <StepByStepTitle
            {...{ children, customSize, heading, headingProps, small, active }}
            vignet={vignet}
          />
          {dropDownUp === true && (
            <CustomChevronUp height={18} size={20} color="#767676" />
          )}
          {dropDownUp === false && (
            <CustomCheveronDown height={18} size={20} color="#767676" />
          )}
        </div>
        {children}
      </ContentWrapperStyle>
    </StepByStepItemStyle>
  );
};

export default StepByStepItem;
