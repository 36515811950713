import { connect } from "react-redux";

import { Creators } from "../../../services/redux/vignets/actions";
import DownloadSummary from "./components";

const mapStateToProps = (state) => {
  const { isGettingSummaryLink, summaryLink, summaryLinkError } = state.vignets;
  return {
    isGettingSummaryLink,
    summaryLink,
    summaryLinkError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSummaryLink: () => {
      dispatch(Creators.getSummaryLinkRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadSummary);
