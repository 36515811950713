import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { List, ListItem } from "@amsterdam/asc-ui";

import SubmitButton from "../../../../../components/Button";
import { BlackP1 } from "../../../../../components/Typography";
import { history } from "../../../../../utils/helpers";
import { DayVignetLayout } from "../..";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../../../constants";

const ListWrapper = styled.div`
  margin-top: 5px;
  margin-left: 15px;
`;

const DayVignetPaymentGuide = () => {
  return (
    <DayVignetLayout
      step={1}
      title={<FormattedMessage id="dayVignettePaymentGuideTitle" />}
      subtitle={
        <FormattedMessage
          id="dayVignettePaymentGuideDescription"
          values={{ br: <br /> }}
        />
      }
      isLoading={false}
      error={false}
    >
      <div className="mt-inverse-10">
        <ListWrapper>
          <List>
            <ListItem>
              <BlackP1
                style={{
                  fontFamily: "Avenir Next LT Pro Demi",
                  marginBottom: 2,
                }}
              >
                • &nbsp;&nbsp;{" "}
                <FormattedMessage id="dayVignettePaymentGuideItem1" />
              </BlackP1>
            </ListItem>
            <ListItem>
              <BlackP1 style={{ fontFamily: "Avenir Next LT Pro Demi" }}>
                • &nbsp;&nbsp;{" "}
                <FormattedMessage id="dayVignettePaymentGuideItem2" />
              </BlackP1>
            </ListItem>
          </List>
        </ListWrapper>
        <BlackP1 style={{ marginTop: "-40px" }}>
          <FormattedMessage
            id="dayVignettePaymentGuideInfo"
            values={{ br: <br /> }}
          />
        </BlackP1>
        <SubmitButton
          onNext={() => {
            history.push(
              `${TRANSIT_VIGNETTE_ROUTE}/mooring-request/personal-info`
            );
          }}
        />
      </div>
    </DayVignetLayout>
  );
};

export default DayVignetPaymentGuide;
