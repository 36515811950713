import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CustomSpinner from "../../../../components/Spinner";
import { Creators } from "../../../../services/redux/vignets/actions";
import { parseQuery } from "../../../../utils/helpers";
import CancelVignet from "../../CancelVignet";
import ExtendVignette from "../../ExtendVignette";

class SendToDecisionMadeComponent extends React.Component {
  componentDidMount() {
    const { getAllVignets, getVignet, location } = this.props;
    getAllVignets();
    if (location.search) {
      const { vignet_id } = parseQuery(location.search);
      getVignet(vignet_id);
    }
  }

  shouldComponentUpdate(props) {
    // we don't the component to rerender if the updateVignet action gets called
    // because after we update the vignet, we need to refetch the vignet to get the cost
    // if it rerenders, it'll mess up with the state by reloading which would lose the ExtendVignet component state
    return !props.updatedVignet.status;
  }

  render() {
    const {
      isGettingVignet,
      isFetchingAllVignets,
      location,
      vignet,
      vignets,
    } = this.props;
    if (isGettingVignet || isFetchingAllVignets) {
      return <CustomSpinner />;
    } else if (!isGettingVignet && vignet && vignets) {
      const { decision } = parseQuery(location.search);

      if (decision === "cancel") {
        return <CancelVignet vignet={vignet} />;
      }
      return (
        <ExtendVignette
          vignet={vignet}
          idealPaymentfailed={parseQuery(location.search).failed}
          location={location}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    isGettingVignet: state.vignets.isGettingVignet,
    vignet: state.vignets.vignet,
    vignets: state.vignets.vignets,
    isFetchingAllVignets: state.vignets.isloading,
    updatedVignet: state.vignets.updatedVignet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVignet: (vignet_id) => {
      dispatch(Creators.getVignetRequest(vignet_id));
    },
    getAllVignets: () => {
      dispatch(Creators.getVignetsRequest());
    },
  };
};

SendToDecisionMadeComponent.propTypes = {
  getAllVignets: PropTypes.func.isRequired,
  getVignet: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  updatedVignet: PropTypes.shape({}).isRequired,
  isGettingVignet: PropTypes.bool.isRequired,
  isFetchingAllVignets: PropTypes.bool.isRequired,
  vignet: PropTypes.shape({}).isRequired,
  vignets: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendToDecisionMadeComponent);
