import React from "react";
import { Radio, Label } from "@amsterdam/asc-ui";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";

import { CustomRadioGroup } from "../../../../components";
import { CustomErrorMessage } from "../../../../components/Typography";

const DecisionForm = ({ vignet }) => {
  return (
    <Field
      name={vignet.vignet_id}
      validate={(value) => {
        if (!value) return "you must pick";
      }}
    >
      {({ field, form }) => (
        <>
          <CustomRadioGroup
            name={field.name}
            style={{ marginTop: "-10px", marginBottom: "0px" }}
            error={
              form.errors[vignet.vignet_id] && form.touched[vignet.vignet_id]
            }
          >
            <Label
              htmlFor={`${field.name}renew`}
              label={<FormattedMessage id="renew" />}
            >
              <Radio
                onChange={(e) =>
                  form.setFieldValue(vignet.vignet_id, `${field.name}renew`)
                }
                id={`${field.name}renew`}
                checked={field.value === `${field.name}renew`}
                variant="tertiary"
                value={`${field.name}renew`}
              />
            </Label>
            <div style={{ marginLeft: "8px" }} />
            <Label
              htmlFor={`${field.name}cancel`}
              label={<FormattedMessage id="cancel" />}
            >
              <Radio
                onChange={(e) =>
                  form.setFieldValue(vignet.vignet_id, `${field.name}cancel`)
                }
                id={`${field.name}cancel`}
                variant="tertiary"
                checked={field.value === `${field.name}cancel`}
                value={`${field.name}cancel`}
              />
            </Label>
          </CustomRadioGroup>
          {form.errors[vignet.vignet_id] && form.touched[vignet.vignet_id] && (
            <CustomErrorMessage
              id="makeChoiceAbove"
              style={{ marginTop: "0px" }}
            />
          )}
        </>
      )}
    </Field>
  );
};

DecisionForm.propTypes = {
  vignet: PropTypes.shape({}).isRequired,
};

export default DecisionForm;
