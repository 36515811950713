import { RESTITUTIE_VIGNETTE_ROUTE } from "../../../constants";
import { useLanguage } from "../../../lang";
import { PersonalInfoFormValidator } from "../FormValidator";
import PdfGenerator from "../util/PdfGenerator";
import generate from "../util/PdfGenerator";
import DownloadSummaryButton from "./DownloadSummaryButton";
import RestitutieLayout from "./Layout";

const Done = (props) => {
  const formData = PersonalInfoFormValidator.fetchFormData().data;
  console.log(formData);
  const [language, messages] = useLanguage();
  if (!formData) {
    const redirect = RESTITUTIE_VIGNETTE_ROUTE + "/personal-info";
    window.location.href = redirect;
    return (
      <div>
        <a href={redirect}>Redirecting..</a>
      </div>
    );
  }
  const info = [
    {
      key: messages.initials,
      value: formData.initials,
    },
    {
      key: messages.lastname,
      value: (formData.middleName || "") + " " + formData.lastName,
    },
    {
      key: messages.email,
      value: formData.email,
    },
    {
      key: messages.phone,
      value: formData.phoneNumber,
    },
  ];

  const contactInfo = [];

  const additionalInfo = [
    {
      key: messages.vignetNumber,
      value: formData.vignetNumber,
    },
    {
      key: messages.restitutionFormVesselName,
      value: formData.vesselName,
    },
    {
      key: messages.restitutionFormReason,
      value: formData.reason,
    },
  ];

  return (
    <RestitutieLayout title={messages.step5}>
      <div>{messages.restitutionFormDoneEmail}</div>
      <div>{messages.restitutionFormDoneDownload}</div>
      <br></br>
      <DownloadSummaryButton
        pdfData={PdfGenerator.generate(
          info,
          contactInfo,
          additionalInfo,
          messages
        )}
      ></DownloadSummaryButton>
    </RestitutieLayout>
  );
};

export default Done;
