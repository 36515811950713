import { connect } from "react-redux";

import { Creators } from "../../../../services/redux/payments/actions";
import PaymentChoice from "./components";

const mapStateToProps = (state) => {
  const { isGettingPaymentTypes, error, paymentTypes } = state.payments;
  return {
    isGettingPaymentTypes,
    error,
    paymentTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentTypes: () => {
      dispatch(Creators.getNewPaymentTypes());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentChoice);
