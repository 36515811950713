import { BASE_URL } from "../../services/api/transit-urls";

let isRequestInProgress = false;

const sendFormDataToCms = async () => {
  if (isRequestInProgress) {
    return { isValid: false, note: "Request still in progress" };
  }
  isRequestInProgress = true;
  const formData = JSON.parse(window.localStorage.getItem("restitution")).data;
  const json = JSON.stringify(formData);
  return await createRestitution(json);
};

const createRestitution = (json) => {
  return fetch(BASE_URL + "/apiRestitutions/create", {
    method: "POST",
    mode: "cors",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
    body: json,
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        return { isValid: false, note: response.status };
      }
      return response.json();
    })
    .then((data) => {
      if (data.isValid === false && data.note) {
        return data;
      }
      const restitution = JSON.parse(data.restitution);
      window.localStorage.setItem("request_number", "");
      return { isValid: true, note: "Success" };
    })
    .catch((e) => {
      return { isValid: false, note: e };
    })
    .finally(() => {
      isRequestInProgress = false;
    });
};

export default sendFormDataToCms;
