import React, {
  useState,
  useReducer,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { FormattedMessage } from "react-intl";
import { Field } from "formik";
import * as Yup from "yup";
import { Select } from "@amsterdam/asc-ui";
import styled from "styled-components";
import { initDB, IndexedDB, useIndexedDB } from "react-indexed-db";

import SubmitButton from "../../../../../components/Button";
import { ActivateVignetteLayout } from "../..";
import { CustomErrorMessage } from "../../../../../components/Typography";
import CustomInput from "../../../../../components/Input";
import { CustomRow, CustomColumn } from "../../../../../components";
import DocumentUpload from "./document-upload";
import TransitStorage from "../../../../../utils/storage";
import { DBConfig } from "../../../../../utils/helpers";

initDB(DBConfig);

const DetailsInput = styled(CustomInput)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

export const UploadFileContext = React.createContext();
let initialState = {
  Ligplaatsbewijs: null,
  Ontheffing: null,
};

let reducer = (state, action) => {
  switch (action.type) {
    case "Ontheffing":
      return { ...state, Ontheffing: action.payload };
    case "Ontheffing.delete":
      return {
        ...state,
        Ontheffing: null,
      };
    case "Ligplaatsbewijs":
      return { ...state, Ligplaatsbewijs: action.payload };
    case "Ligplaatsbewijs.delete":
      return {
        ...state,
        Ligplaatsbewijs: null,
      };
    case "hydrate":
      return {
        ...state,
        Ontheffing: action.payload,
      };
    default:
      return { ...state };
  }
};

const VignetSchema = Yup.object().shape({
  object_length: Yup.string().required("required"),
  object_width: Yup.string().required("required"),
  object_name: Yup.string().required("required").typeError("required"),
  object_drive_type_id: Yup.string().required("required"),
  object_type_id: Yup.string().required("required"),
});

const VignetteDetails = ({
  isActivatingGetDriveTypes,
  getDriveTypes,
  activateGetDriveTypes,
  error,
  isValidatingActivateVignet,
  validateVignet,
  isUploadingActivatingPhotos,
  isGettingObjectTypes,
  getObjectTypes,
  objectTypes,
}) => {
  useEffect(() => {
    getDriveTypes();
  }, [getDriveTypes]);

  useEffect(() => {
    getObjectTypes();
  }, [getObjectTypes]);

  const [showRequiredDocumentError, setShowRequiredDocumentError] = useState(
    false
  );

  const documentRef = useRef();
  let [state, dispatch] = useReducer(reducer, initialState);
  let uploadedFiles = { state, dispatch };

  const memoizedHandleSumbit = useCallback(() => {
    if (
      JSON.parse(localStorage.getItem("photos_required")) === true &&
      (Number(localStorage.getItem("photos_count")) === 2
        ? !uploadedFiles.state.Ontheffing ||
          !uploadedFiles.state.Ligplaatsbewijs
        : !uploadedFiles.state.Ontheffing)
    ) {
      setShowRequiredDocumentError(true);
    } else {
      console.log("got here");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles.state.Ontheffing, uploadedFiles.state.Ligplaatsbewijs]);
  const { add, getAll, deleteRecord } = useIndexedDB("photos");

  const fields = TransitStorage.getActivateFieldsSchema();

  return (
    <UploadFileContext.Provider value={uploadedFiles}>
      <ActivateVignetteLayout
        step={2}
        title={<FormattedMessage id="activateVignetteStep3" />}
        subtitle={
          <FormattedMessage
            id="activateVignetteDetailsDescription"
            values={{ br: <br /> }}
          />
        }
        isLoading={
          isActivatingGetDriveTypes ||
          isValidatingActivateVignet ||
          isUploadingActivatingPhotos ||
          isGettingObjectTypes
        }
        error={error}
        hasForm
        validationSchema={VignetSchema}
        initialValues={{ ...TransitStorage.getActivateBoatDetailsSchema() }}
        onSubmit={(values) => {
          if (showRequiredDocumentError) return;
          validateVignet(values);
          TransitStorage.persistActivateBoatDetailsSchema(values);
        }}
      >
        <IndexedDB
          name="MyDB"
          version={1}
          objectStoresMeta={[
            {
              store: "people",
              storeConfig: { keyPath: "id", autoIncrement: true },
              storeSchema: [],
            },
          ]}
        >
          <CustomRow>
            <CustomColumn size={3}>
              <Field
                name="object_length"
                component={DetailsInput}
                isSpecial
                label={<FormattedMessage id="boatLength" />}
                disabled={
                  fields && fields && !fields?.["object_length"]?.isEditable
                }
              />
            </CustomColumn>
            <CustomColumn size={3}>
              <Field
                name="object_width"
                component={DetailsInput}
                isSpecial
                label={<FormattedMessage id="boatWidth" />}
                disabled={fields && !fields?.["object_width"]?.isEditable}
              />
            </CustomColumn>
            <CustomColumn size={3} />
          </CustomRow>
          <CustomRow>
            <CustomColumn size={3}>
              <Field name="object_drive_type_id">
                {({ field, form }) => (
                  <SelectWrapper>
                    <Select
                      id="object_drive_type_id"
                      label={<FormattedMessage id="drive" />}
                      defaultValue={
                        TransitStorage.getActivateBoatDetailsSchema()
                          ?.object_drive_type_id
                      }
                      onChange={(e) =>
                        form.setFieldValue(
                          "object_drive_type_id",
                          e.target.value
                        )
                      }
                      error={form.errors.object_drive_type_id}
                      disabled={
                        fields && !fields?.["object_drive_type_id"]?.isEditable
                      }
                    >
                      <option value="">Selecteer Aandrijving</option>
                      {activateGetDriveTypes?.map((data) => (
                        <option key={data.id} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                    </Select>
                    {form.errors.object_drive_type_id &&
                      form.touched.object_drive_type_id && (
                        <div className="mt-10">
                          <CustomErrorMessage id="selectChoice" />
                        </div>
                      )}
                  </SelectWrapper>
                )}
              </Field>
            </CustomColumn>
            <CustomColumn size={3}>
              <Field name="object_type_id">
                {({ field, form }) => (
                  <SelectWrapper>
                    <Select
                      id="object_type_id"
                      label={<FormattedMessage id="vesselType" />}
                      defaultValue={
                        TransitStorage.getActivateBoatDetailsSchema()
                          ?.object_type_id
                      }
                      onChange={(e) =>
                        form.setFieldValue("object_type_id", e.target.value)
                      }
                      error={form.errors.object_type_id}
                      disabled={
                        fields && !fields?.["object_type_id"]?.isEditable
                      }
                    >
                      <option value="">Selecteer</option>
                      {objectTypes?.map((data) => (
                        <option key={data.id} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                    </Select>
                    {form.errors.object_type_id && form.touched.object_type_id && (
                      <div className="mt-10">
                        <CustomErrorMessage id="selectChoice" />
                      </div>
                    )}
                  </SelectWrapper>
                )}
              </Field>
            </CustomColumn>
            <CustomColumn size={3}>
              <Field
                name="object_name"
                component={DetailsInput}
                label={<FormattedMessage id="boatName2" />}
                disabled={fields && !fields?.["object_name"]?.isEditable}
              />
            </CustomColumn>
          </CustomRow>
          <CustomRow>
            <CustomColumn size={10}>
              <DocumentUpload
                documentRef={documentRef}
                documents={
                  Number(localStorage.getItem("photos_count")) === 2
                    ? ["Ontheffing", "Ligplaatsbewijs"]
                    : ["Ontheffing"]
                }
                showRequiredDocumentError={showRequiredDocumentError}
                setShowRequiredDocumentError={setShowRequiredDocumentError}
                persistImage={add}
                hydrateImage={getAll}
                deletePersistedImage={deleteRecord}
              />
            </CustomColumn>
          </CustomRow>
          <SubmitButton onNext={memoizedHandleSumbit} />
        </IndexedDB>
      </ActivateVignetteLayout>
    </UploadFileContext.Provider>
  );
};

export default VignetteDetails;
