import React from "react";
import { Row, ErrorMessage } from "@amsterdam/asc-ui";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import Layout from "../../components/Layouts";
import CustomSpinner from "../../components/Spinner";

import Steppers from "./components/steppers";
import { CustomColumn } from "../../components";
import { BlackH2, BlackP1 } from "../../components/Typography";

const CustomParagraph = styled.h1`
  padding-bottom: 20px;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #767676;
  color: #767676;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  font-family: Avenir Next LT Pro Demi;
`;

const HeaderWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const ProtectedLayout = (props) => {
  const {
    title,
    titleMore,
    subtitle,
    children,
    isSubmitting,
    isLoading,
    error,
    hasForm,
    initialValues,
    validationSchema,
    onSubmit,
    step,
    showSteppers,
  } = props;
  return (
    <Layout showLogout>
      <Row>
        <CustomColumn size={3} small={0}>
          {showSteppers ? (
            <Steppers step={step} activeVignet={props.activeVignet} />
          ) : null}
        </CustomColumn>
        <CustomColumn size={9}>
          <HeaderWrapper>
            <CustomParagraph forwardedAs="h1">
              <FormattedMessage id="personalInfoHeader" />
            </CustomParagraph>
            <BlackH2 forwardedAs="h2">
              {title} {titleMore}
            </BlackH2>
            <BlackP1 style={{ marginTop: "8px" }}>{subtitle}</BlackP1>
          </HeaderWrapper>
          <Wrapper>
            {error && <ErrorMessage message={error} />}
            {isSubmitting ? <CustomSpinner /> : null}
            {isLoading ? (
              <CustomSpinner />
            ) : hasForm ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, errors }) => (
                  <Form>{React.cloneElement(children, { handleSubmit })}</Form>
                )}
              </Formik>
            ) : (
              children
            )}
          </Wrapper>
        </CustomColumn>
      </Row>
    </Layout>
  );
};

ProtectedLayout.propTypes = {
  headerTitle: PropTypes.object,
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  showSteppers: PropTypes.bool,
  titleMore: PropTypes.string,
};

ProtectedLayout.defaultProps = {
  headerTitle: <FormattedMessage id="personalInfoHeader" />,
  showSteppers: true,
  titleMore: null,
};

const mapStateToProps = (state) => {
  return {
    vignets: state.vignets.vignets,
    activeVignet: state.vignets.vignet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedLayout);
