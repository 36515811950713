class TransitStorage {
  static storage = localStorage;
  static clear() {
    TransitStorage.storage.clear();
  }

  static stringifyData(data) {
    return JSON.stringify(data);
  }

  static initializeActivateVignetteForms() {
    const formSchema = {
      VIGNETTE: {
        location: {
          vignetteChoice: "",
        },
        vignetInfo: {
          vignet: "",
        },
      },
      PERSONAL_INFO: {
        initials: "",
        prefix: "",
        last_name: "",
        address: "",
        number: "",
        addition: "",
        postcode: "",
        city: "",
        email: "",
        country_id: "a97ab21f-9eb7-11eb-8ff2-0602b2001534",
        confirmEmail: "",
        mobile_phone: "",
        kvk: "",
        company: "",
      },
      BOAT_DETAILS: {
        object_length: "",
        object_width: "",
        object_name: "",
        object_drive_type_id: "",
        object_type_id: "",
      },
      FIELDS: {},
    };

    if (localStorage.getItem("activate")) {
      // this means the form has already been intialized and it could have data persisted from user
      return;
    }

    localStorage.setItem("activate", this.stringifyData(formSchema));
  }

  static getActivateVignetteSchema() {
    TransitStorage.initializeActivateVignetteForms();
    const activateSchema = JSON.parse(localStorage.getItem("activate"));
    return activateSchema?.VIGNETTE;
  }

  static persistActivateVignetteSchema(data) {
    const activateSchema = JSON.parse(localStorage.getItem("activate"));
    activateSchema.VIGNETTE.location.vignetteChoice = data.vignet_type;
    activateSchema.VIGNETTE.vignetInfo.vignet = data.vignet;
    localStorage.setItem("activate", this.stringifyData(activateSchema));
  }

  static getActivateFieldsSchema() {
    const activateSchema = JSON.parse(localStorage.getItem("activate"));
    return activateSchema?.FIELDS;
  }

  static persistActivateFieldsSchema(data) {
    const activateSchema = JSON.parse(localStorage.getItem("activate"));
    activateSchema.FIELDS = data;
    localStorage.setItem("activate", this.stringifyData(activateSchema));
  }

  static getActivatePersonalInfoSchema() {
    const activateSchema = JSON.parse(localStorage.getItem("activate"));
    return activateSchema?.PERSONAL_INFO;
  }

  static persistActivatePersonalInfoSchema(data) {
    const activateSchema = JSON.parse(localStorage.getItem("activate"));
    activateSchema.PERSONAL_INFO = data;
    localStorage.setItem("activate", this.stringifyData(activateSchema));
  }

  static getActivateBoatDetailsSchema() {
    const activateSchema = JSON.parse(localStorage.getItem("activate"));
    return activateSchema?.BOAT_DETAILS;
  }

  static persistActivateBoatDetailsSchema(data) {
    const activateSchema = JSON.parse(localStorage.getItem("activate"));
    activateSchema.BOAT_DETAILS = data;
    localStorage.setItem("activate", this.stringifyData(activateSchema));
  }

  static intializeNewVignetteSchema() {
    const formSchema = {
      VIGNETTE: {
        vignetKind: "",
      },
      PERSONAL_INFO: {
        initials: "",
        lastname: "",
        prefix: "",
        address: "",
        number: "",
        addition: "",
        postcode: "",
        city: "",
        phonenumber: "",
        country_id: "a97ab21f-9eb7-11eb-8ff2-0602b2001534",
        email: "",
        confirmEmail: "",
        company: "",
        kvk: "",
      },
      DELIVERY_INFO: {
        deliveryType: "",
      },
      PAYMENT: {
        payment_type_id: "",
        card_service_code: "",
      },
    };

    if (localStorage.getItem("newVignette")) {
      // this means the form has already been intialized and it could have data persisted from user
      return;
    }

    localStorage.setItem("newVignette", this.stringifyData(formSchema));
  }

  static getNewVignetteDetailsSchema() {
    TransitStorage.intializeNewVignetteSchema();
    const activateSchema = JSON.parse(localStorage.getItem("newVignette"));
    return activateSchema?.VIGNETTE;
  }

  static persistNewVignetteDetailsSchema(data) {
    const newVignetteSchema = JSON.parse(localStorage.getItem("newVignette"));
    newVignetteSchema.VIGNETTE = data;
    localStorage.setItem("newVignette", this.stringifyData(newVignetteSchema));
  }

  static initializeNewVignetteMeta() {
    const data = {
      vignet_cost: "",
      vignet_name: "",
    };

    if (localStorage.getItem("newVignetteMeta")) {
      return;
    }

    localStorage.setItem("newVignetteMeta", this.stringifyData(data));
  }

  static getNewVignetteMetaDetails() {
    TransitStorage.initializeNewVignetteMeta();
    return JSON.parse(localStorage.getItem("newVignetteMeta"));
  }

  static persistNewVignetteMeta(data) {
    localStorage.setItem("newVignetteMeta", this.stringifyData(data));
  }

  static getNewVignettePersonalInfoSchema() {
    const newVignetteSchema = JSON.parse(localStorage.getItem("newVignette"));
    return newVignetteSchema?.PERSONAL_INFO;
  }

  static persistNewVignettePersonalInfoSchema(data) {
    const newVignetteSchema = JSON.parse(localStorage.getItem("newVignette"));
    newVignetteSchema.PERSONAL_INFO = data;
    localStorage.setItem("newVignette", this.stringifyData(newVignetteSchema));
  }

  static getNewVignetteDeliveryInfoSchema() {
    const newVignetteSchema = JSON.parse(localStorage.getItem("newVignette"));
    return newVignetteSchema?.DELIVERY_INFO;
  }

  static persistNewVignetteDeliveryInfoSchema(data) {
    const newVignetteSchema = JSON.parse(localStorage.getItem("newVignette"));
    newVignetteSchema.DELIVERY_INFO = data;
    localStorage.setItem("newVignette", this.stringifyData(newVignetteSchema));
  }

  static persistDeliveryInfoMeta(data) {
    TransitStorage.storage.setItem(
      "newVignetteDeliveryMeta",
      this.stringifyData(data)
    );
  }

  static getDeliveryInfoMeta() {
    const data = TransitStorage.storage.getItem("newVignetteDeliveryMeta");
    return JSON.parse(data);
  }

  static getNewVignettePaymentInfo() {
    const newVignetteSchema = JSON.parse(localStorage.getItem("newVignette"));
    return newVignetteSchema?.PAYMENT;
  }

  static persistPaymentSchema(data) {
    const newVignetteSchema = JSON.parse(localStorage.getItem("newVignette"));
    newVignetteSchema.PAYMENT = data;
    localStorage.setItem("newVignette", this.stringifyData(newVignetteSchema));
  }

  static intializeDayVignetteSchema() {
    const formSchema = {
      VIGNETTE: {
        vignet: "",
        repeatVignetNumber: "",
      },
      PERSONAL_INFO: {
        initials: "",
        lastname: "",
        prefix: "",
        mobile_phone: "",
        email: "",
        repeatEmail: "",
        repeatTelephone: "",
      },
      PAYMENT: {
        payment_type_id: "",
        card_service_code: "",
        voucher_code: "",
      },
    };

    if (localStorage.getItem("dayVignette")) {
      // this means the form has already been intialized and it could have data persisted from user
      return;
    }

    localStorage.setItem("dayVignette", this.stringifyData(formSchema));
  }

  static getDayVignetteDetailsSchema() {
    TransitStorage.intializeDayVignetteSchema();
    const dayVignetteSchema = JSON.parse(localStorage.getItem("dayVignette"));
    return dayVignetteSchema?.VIGNETTE;
  }

  static persistDayVignetteDetailsSchema(data) {
    const dayVignetteSchema = JSON.parse(localStorage.getItem("dayVignette"));
    dayVignetteSchema.VIGNETTE = data;
    localStorage.setItem("dayVignette", this.stringifyData(dayVignetteSchema));
  }

  static getDayVignettePersonalInfoSchema() {
    const dayVignetteSchema = JSON.parse(localStorage.getItem("dayVignette"));
    return dayVignetteSchema?.PERSONAL_INFO;
  }

  static persistDayVignettePersonalInfoSchema(data) {
    const dayVignetteSchema = JSON.parse(localStorage.getItem("dayVignette"));
    dayVignetteSchema.PERSONAL_INFO = data;
    localStorage.setItem("dayVignette", this.stringifyData(dayVignetteSchema));
  }

  static getDayVignettePaymentInfo() {
    const dayVignetteSchema = JSON.parse(localStorage.getItem("dayVignette"));
    return dayVignetteSchema?.PAYMENT;
  }

  static persistDayPaymentSchema(data) {
    const dayVignetteSchema = JSON.parse(localStorage.getItem("dayVignette"));
    dayVignetteSchema.PAYMENT = data;
    localStorage.setItem("dayVignette", this.stringifyData(dayVignetteSchema));
  }
}

export default TransitStorage;
