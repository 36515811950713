import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import CustomInput from "../../../../components/Input";
import { FormattedMessage } from "react-intl";

import { CustomColumn, CustomRow } from "../../../../components";
import { BlackIntro, BlackP1 } from "../../../../components/Typography";

const Content = ({ showField, fieldName, value, ...rest }) => (
  <React.Fragment>
    {showField ? (
      <Field name={fieldName} component={CustomInput} {...rest} />
    ) : (
      <BlackP1 style={{ marginTop: "-18px" }}>{value}</BlackP1>
    )}
  </React.Fragment>
);

const RenderField = ({
  showField,
  fieldName,
  value,
  withColumn,
  headerName,
  ...rest
}) => {
  return (
    <React.Fragment>
      {withColumn ? (
        <CustomRow>
          <CustomColumn size={12}>
            <BlackIntro style={{ marginBottom: showField && "0px" }}>
              <FormattedMessage id={headerName} />
            </BlackIntro>
          </CustomColumn>
          <CustomColumn size={12} large={4}>
            <Content
              showField={showField}
              fieldName={fieldName}
              value={value}
              {...rest}
            />
          </CustomColumn>
        </CustomRow>
      ) : (
        <Content
          showField={showField}
          fieldName={fieldName}
          value={value}
          {...rest}
        />
      )}
    </React.Fragment>
  );
};

RenderField.propTypes = {
  showField: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  withColumn: PropTypes.bool,
  headerName: PropTypes.string,
};

RenderField.defaultProps = {
  withColumn: true,
  headerName: null,
};

export default RenderField;
