import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import VignetVerification from "./VignetVerification";
import PaymentGuide from "./PaymentGuide";
import PersonalInfo from "./PersonalInfo";
import PaymentChoice from "./PaymentChoice";
import Summary from "./Summary";
import Done from "./Done";
import AppLayout from "..";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../constants";

const steps = [
  {
    label: "dayStep1",
    route: `${TRANSIT_VIGNETTE_ROUTE}/mooring-request`,
  },
  {
    label: "dayStep2",
    route: `${TRANSIT_VIGNETTE_ROUTE}/mooring-request/payment-guide`,
  },
  {
    label: "dayStep3",
    route: `${TRANSIT_VIGNETTE_ROUTE}/mooring-request/personal-info`,
  },
  {
    label: "dayStep4",
    route: `${TRANSIT_VIGNETTE_ROUTE}/mooring-request/payment-choice`,
  },
  {
    label: "dayStep5",
    route: `${TRANSIT_VIGNETTE_ROUTE}/mooring-request/summary`,
  },
  {
    label: "dayStep6",
    route: `${TRANSIT_VIGNETTE_ROUTE}/mooring-request/done`,
  },
];

export const DayVignetLayout = ({
  step,
  title,
  subtitle,
  children,
  ...rest
}) => {
  useEffect(() => {
    if (step > 0 && !localStorage.getItem("dayVignette")) {
      window.location = `${TRANSIT_VIGNETTE_ROUTE}/mooring-request`;
      return;
    }
    if (step === 5) {
      localStorage.removeItem("dayVignette");
    }
  }, [step]);

  return (
    <AppLayout
      headerTitle={<FormattedMessage id="dayVignetteTitle" />}
      step={step}
      steps={steps}
      title={title}
      subtitle={subtitle}
      {...rest}
    >
      {children}
    </AppLayout>
  );
};

const DayVignetteRoutes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={VignetVerification} />
      <Route
        exact
        path={`${match.url}/payment-guide`}
        component={PaymentGuide}
      />
      <Route
        exact
        path={`${match.url}/personal-info`}
        component={PersonalInfo}
      />
      <Route
        exact
        path={`${match.url}/payment-choice`}
        component={PaymentChoice}
      />
      <Route exact path={`${match.url}/summary`} component={Summary} />
      <Route exact path={`${match.url}/done`} component={Done} />
    </Switch>
  );
};

export default DayVignetteRoutes;
