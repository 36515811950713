import { connect } from "react-redux";

import { Creators } from "../../../services/redux/vignets/actions";
import VignetsDecision from "./components";

const mapStateToProps = (state) => {
  const { isloading, error, vignets } = state.vignets;
  return {
    isloading,
    error,
    vignets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVignets: () => {
      dispatch(Creators.getVignetsRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VignetsDecision);
