import { connect } from "react-redux";

import { Creators } from "../../../services/redux/profile/actions";
import { Creators as VignetCreators } from "../../../services/redux/vignets/actions";
import Summary from "./components";

const mapStateToProps = (state) => {
  const { isloading, error, user } = state.profile;
  const { isGettingVignet, isGettingVignetError } = state.vignets;
  return {
    isloading,
    error,
    user,
    isGettingVignet,
    isGettingVignetError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOwner: () => {
      dispatch(Creators.getOwnerRequest());
    },
    getVignet: (vignet_id) => {
      dispatch(VignetCreators.getVignetRequest(vignet_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
