import { connect } from "react-redux";

import { Creators } from "../../../../services/redux/vignets/actions";
import PersonalInfo from "./components";

const mapStateToProps = (state) => {
  return {
    isValidatingPassageOwner: state.vignets.isValidatingPassageOwner,
    error: state.vignets.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    validateOwner: (data, nextRoute) => {
      dispatch(Creators.validatePassageOwner(data, nextRoute));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
