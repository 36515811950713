import { connect } from "react-redux";

import { Creators } from "../../../services/redux/profile/actions";
import PersonalInfo from "./components";

const mapStateToProps = (state) => {
  const { isloading, error, user, isUpdating, isUpdatingError } = state.profile;
  return {
    isloading,
    error,
    user,
    isUpdating,
    isUpdatingError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOwner: () => {
      dispatch(Creators.getOwnerRequest());
    },
    updateOwner: (data) => {
      dispatch(Creators.updateOwnerRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
