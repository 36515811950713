import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "../../rootReducer";
import rootSaga from "../sagas";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware].filter(Boolean);

const store = createStore(rootReducer, applyMiddleware(...middlewares));
sagaMiddleware.run(rootSaga);

export default store;
