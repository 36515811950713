import React, { useEffect } from "react";
import { Button } from "@amsterdam/asc-ui";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import AppLayout from "../../";
import { BlackP1, BlackIntro } from "../../../../components/Typography";
import TransitStorage from "../../../../utils/storage";

const ModuleWrapper = styled.div`
  margin-top: 40px;
`;

const HomeBlackIntro = styled(BlackIntro)`
  margin-bottom: 10px;
  margin-top: 0px;
`;

const HomeBlackP1 = styled(BlackP1)`
  margin-bottom: 10px;
`;

const HomePage = ({
  goToActivate,
  goToRequestVignette,
  goToMooringRequest,
}) => {
  useEffect(() => {
    TransitStorage.clear();
  }, []);
  return (
    <AppLayout
      step={5}
      headerTitle={<FormattedMessage id="homePageTitle" />}
      title={<FormattedMessage id="homePageSubTitle" />}
      subtitle={
        <FormattedMessage id="homePageDescription" values={{ br: <br /> }} />
      }
      showSteppers={false}
      isLoading={false}
      error={false}
    >
      <div className="mt-inverse-20">
        <HomeBlackIntro forwardedAs="h2">
          <FormattedMessage id="homePageActivateVignetTitle" />
        </HomeBlackIntro>
        <HomeBlackP1>
          <FormattedMessage
            id="homePageActivateVignetDescription"
            values={{ br: <br /> }}
          />
        </HomeBlackP1>
        <Button variant="primary" onClick={() => goToActivate()}>
          <FormattedMessage id="homePageActivateButton" />
        </Button>
      </div>

      <ModuleWrapper>
        <HomeBlackIntro forwardedAs="h2">
          <FormattedMessage id="homePageRequestVignetTitle" />
        </HomeBlackIntro>
        <HomeBlackP1>
          <FormattedMessage
            id="homePageRequestVignetDescription"
            values={{ br: <br /> }}
          />
        </HomeBlackP1>
        <Button variant="primary" onClick={() => goToRequestVignette()}>
          <FormattedMessage id="homePageRequestButton" />
        </Button>
      </ModuleWrapper>

      <ModuleWrapper>
        <HomeBlackIntro forwardedAs="h2">
          <FormattedMessage id="dayVignettePaymentGuideTitle" />
        </HomeBlackIntro>
        <HomeBlackP1>
          <FormattedMessage
            id="homePageMoorVignetDescription"
            values={{ br: <br /> }}
          />
        </HomeBlackP1>
        <Button variant="primary" onClick={() => goToMooringRequest()}>
          <FormattedMessage id="homePageMoorButton" />
        </Button>
      </ModuleWrapper>
    </AppLayout>
  );
};

export default HomePage;
