import { connect } from "react-redux";

import { Creators } from "../../../../services/redux/payments/actions";
import Summary from "./components";

const mapStateToProps = (state) => {
  const { error, isMakingMooringPayment } = state.payments;
  return { error, isMakingMooringPayment };
};
const mapDispatchToProps = (dispatch) => {
  return {
    makeMooringPayment: () => {
      dispatch(Creators.mooringMakePayment());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
