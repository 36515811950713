import React from "react";
import { BlackIntro, BlackP1 } from "../Typography";

const StepByStepTitle = ({
  children,
  customSize,
  heading,
  headingProps,
  small,
  vignet,
  active,
}) =>
  vignet ? (
    <BlackIntro
      {...headingProps}
      style={{
        paddingLeft: 10,
        marginTop: "15px",
        fontFamily: active
          ? "Avenir Next LT Pro Demi"
          : "Avenir Next LT Pro Regular",
      }}
      strong={customSize}
    >
      {heading} {vignet}
    </BlackIntro>
  ) : small ? (
    <BlackP1
      gutterBottom={children ? 12 : 0}
      strong={customSize}
      {...headingProps}
    >
      {heading}
    </BlackP1>
  ) : (
    <BlackIntro gutterBottom={children ? 12 : 0} {...headingProps}>
      {heading}
      {/* active vignet title */}
    </BlackIntro>
  );
export default StepByStepTitle;
