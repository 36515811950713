import { connect } from "react-redux";

import { Creators } from "../../../services/redux/vignets/actions";
import { Creators as ProfileCreators } from "../../../services/redux/profile/actions";
import { Creators as PaymentCreators } from "../../../services/redux/payments/actions";
import ExtendVignette from "./components";

const mapStateToProps = (state) => {
  const {
    isGettingDriveTypes,
    isGettingDriveTypesError,
    driveTypes,

    isUpdatingVignet,
    isUpdatingVignetError,
    updatedVignet,

    isGettingVignet,
    isGettingVignetError,
    vignet,

    uploadingDocument,
    uploadDocumentError,
  } = state.vignets;
  const {
    isPayingAccountNumber,
    isPayingAccountNumberError,
    isPayingBankPayment,
    isPayingBankPaymentError,
    isGettingPaymentTypes,
    isCheckingIbanRequest,
    ibanValidationError,
    ibanValid,
  } = state.payments;
  return {
    isGettingDriveTypes,
    isGettingDriveTypesError,
    driveTypes,

    isUpdatingVignet,
    isUpdatingVignetError,
    updatedVignet,

    isGettingVignet,
    isGettingVignetError,
    vignet,

    isUploading: uploadingDocument,
    uploadError: uploadDocumentError,

    isPayingViaBankAccount: isPayingAccountNumber,
    isPayingAccountNumberError,

    isPayingBankPayment,
    isPayingBankPaymentError,

    isCheckingIbanRequest,
    ibanValidationError,
    ibanValid,

    isGettingPaymentTypes,
    lang: state.app.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDriveTypes: () => {
      dispatch(Creators.getDriveTypesRequest());
    },
    updateVignet: (data) => {
      dispatch(Creators.updateVignetRequest(data));
    },
    uploadDocuments: (data) => {
      dispatch(Creators.uploadDocumentsRequest(data));
    },
    payViaAccountNumber: (data) => {
      dispatch(PaymentCreators.accountNumberPaymentRequest(data));
    },
    payViaBankPayment: (data) => {
      dispatch(PaymentCreators.bankPaymentRequest(data));
    },
    getPaymentTypes: () => {
      dispatch(PaymentCreators.getPaymentTypesRequest());
    },
    validateIban: (data) => {
      dispatch(PaymentCreators.checkIbanValidationRequest(data));
    },
    refreshIbanValidation: () => {
      dispatch(PaymentCreators.refreshIbanValidation());
    },
    getOwner: () => {
      dispatch(ProfileCreators.getOwnerRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtendVignette);
