import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { GlobalStyle, ThemeProvider } from "@amsterdam/asc-ui";

import { useLanguage } from "./lang";
import { history, PrivateRoute } from "./utils/helpers";
import Login from "./containers/Auth/Login";
import ResetPassword from "./containers/Auth/ResetPassword";
import ResetPasswordFeedback from "./containers/Auth/ResetPassword/components/ResetPasswordFeedback";
import SetPassword from "./containers/Auth/SetPassword";
import SetPasswordFeedback from "./containers/Auth/SetPassword/components/SetPasswordFeedback";

import PersonalInfo from "./containers/ProtectedRoutes/PersonalInfo";
import VignetDecision from "./containers/ProtectedRoutes/VignetDecision";
import SendToDecisionMadeComponent from "./containers/ProtectedRoutes/VignetDecision/components/redirect";
import CancelVignet from "./containers/ProtectedRoutes/CancelVignet";
import Summary from "./containers/ProtectedRoutes/Summary";
import DownloadSummary from "./containers/ProtectedRoutes/DownloadSummary";
import ExtendVignette from "./containers/ProtectedRoutes/ExtendVignette";
import PaymentSuccessCallback from "./containers/ProtectedRoutes/ExtendVignette/components/PaymentSuccessCallback";

// Transit vignette
import HomePage from "./containers/TransitVignette/Home";
import ActivateVignetteRoute from "./containers/TransitVignette/ActivateVignette";
import DayVignetteRoutes from "./containers/TransitVignette/RequestDayVignette";
import NewVignetteRoute from "./containers/TransitVignette/NewVignette";
import { TRANSIT_VIGNETTE_ROUTE, RESTITUTIE_VIGNETTE_ROUTE } from "./constants";
import RestitutieRoutes from "./containers/Restitutie/RestitutieRoutes";

const NotFound = () => {
  return <h1>Not Found</h1>;
};

const App = () => {
  const [language, messages] = useLanguage();
  return (
    <IntlProvider locale={language} messages={messages}>
      <ThemeProvider>
        <GlobalStyle />
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/reset-password" exact component={ResetPassword} />
            <Route
              path="/reset-password/feedback"
              exact
              component={ResetPasswordFeedback}
            />
            <Route path="/reset/:token" exact component={SetPassword} />
            <Route
              path="/set-password-feedback"
              exact
              component={SetPasswordFeedback}
            />
            <PrivateRoute
              path="/personal-info"
              exact
              component={PersonalInfo}
            />
            <PrivateRoute
              path="/vignets-decision"
              exact
              component={VignetDecision}
            />
            <PrivateRoute
              path="/cancel-vignet"
              exact
              component={CancelVignet}
            />
            <PrivateRoute
              path="/extend-vignet"
              exact
              component={ExtendVignette}
            />
            <PrivateRoute path="/summary" exact component={Summary} />
            <PrivateRoute
              path="/push-decision"
              exact
              component={SendToDecisionMadeComponent}
            />
            <PrivateRoute
              path="/payment-success-callback"
              exact
              component={PaymentSuccessCallback}
            />
            <PrivateRoute path="/done" exact component={DownloadSummary} />

            {/* TRANSIT VIGNETTE */}

            <Route path={TRANSIT_VIGNETTE_ROUTE} exact component={HomePage} />
            <Route
              path={`${TRANSIT_VIGNETTE_ROUTE}/activate-vignette`}
              component={ActivateVignetteRoute}
            />
            <Route
              path={`${TRANSIT_VIGNETTE_ROUTE}/mooring-request`}
              component={DayVignetteRoutes}
            />
            <Route
              path={`${TRANSIT_VIGNETTE_ROUTE}/new-vignette`}
              component={NewVignetteRoute}
            />
            <Route
              path={`${RESTITUTIE_VIGNETTE_ROUTE}`}
              component={RestitutieRoutes}
            />
            <Route path="*" exact component={NotFound} />
          </Switch>
        </Router>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
