import React, { useEffect } from "react";
import { Button } from "@amsterdam/asc-ui";
import { FormattedMessage } from "react-intl";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import ProtectedRoutes from "../..";
import { BlackH2, BlackP1 } from "../../../../components/Typography";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DownloadSummary = ({
  isGettingSummaryLink,
  summaryLink,
  summaryLinkError,
  getSummaryLink,
}) => {
  useEffect(() => {
    getSummaryLink();
  }, [getSummaryLink]);
  return (
    <ProtectedRoutes
      step={5}
      title={<FormattedMessage id="step5" />}
      subtitle={
        <FormattedMessage id="downloadSubTitle" values={{ da: "bolt babe" }} />
      }
      showSteppers={false}
      isLoading={isGettingSummaryLink}
      error={summaryLinkError}
    >
      <BlackH2 forwardedAs="h2">
        <FormattedMessage id="step4" />
      </BlackH2>
      <Button variant="primary" onClick={() => (window.location = summaryLink)}>
        <React.Fragment>
          <FormattedMessage id="download" />
          <FormattedMessage id="step4" />
        </React.Fragment>
      </Button>

      <BlackH2 as="h2" style={{ marginTop: "20px" }}>
        <FormattedMessage id="ask" />?
      </BlackH2>
      <BlackP1>
        <FormattedMessage id="finalDetail" />
      </BlackP1>
    </ProtectedRoutes>
  );
};

export default DownloadSummary;
