import { connect } from "react-redux";
import PaymentGuide from "./components";

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGuide);
