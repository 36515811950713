import styled from "styled-components";
import { Link } from "@amsterdam/asc-ui";

const CustomLink = styled(Link)`
  display: inline;
  float: right;
  cursor: pointer;
  text-decoration: underline;
  height: 22px;
  width: 44px;
  color: #004699;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  font-family: Avenir Next LT Pro Demi;
`;

export default CustomLink;
