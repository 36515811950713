import React, { memo } from "react";
import { ChevronLeft } from "@amsterdam/asc-assets";
import { Button, Link } from "@amsterdam/asc-ui";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { history } from "../../utils/helpers";

const Wrapper = styled.div`
  background-color: #e6e6e6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin-top: 5em;
`;

const PreviousButton = styled(Link)`
  padding-top: 10px;
  text-decoration: none;
  color: #004699;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  cursor: pointer;
  font-family: Avenir Next LT Pro Demi;
`;

const SubmitButton = (props) => {
  return (
    <Wrapper>
      <PreviousButton
        variant="inline"
        onClick={(e) => {
          props.onPrevious ? props.onPrevious() : history.goBack();
        }}
      >
        {props.showPrevious && (
          <>
            <ChevronLeft height={16} className="pt-5 pr-5" />
            <FormattedMessage id="previous" />
          </>
        )}
      </PreviousButton>
      <Button
        variant="secondary"
        taskflow={props.taskflow}
        onClick={(e) => {
          props.onNext();
        }}
        type="submit"
        disabled={props.disabled}
        style={{ fontFamily: "Avenir Next LT Pro Demi" }}
      >
        <FormattedMessage id={props.nextTitle || "next"} />
      </Button>
    </Wrapper>
  );
};

SubmitButton.defaultProps = {
  showPrevious: true,
  taskflow: true,
};

export default memo(SubmitButton);
