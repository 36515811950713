import React from "react";
import {
  Header,
  MenuItem,
  MenuButton,
  MenuInline,
  Footer,
  FooterBottom,
  FooterTop,
  FooterSection,
  Row,
  Column,
  Link,
} from "@amsterdam/asc-ui";
import { Logout } from "@amsterdam/asc-assets";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Creators } from "../../services/redux/app/actions";
import { logUserOut } from "../../utils/helpers";
import { BlackP1 } from "../../components/Typography";

const Container = styled.div`
  width: 99%;
  margin: 0 auto;

  @media only screen and (max-width: 468px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const Layout = (props) => {
  return (
    <Container>
      <Header
        tall
        fullWidth
        navigation={
          <MenuInline>
            {props.showLogout ? (
              <React.Fragment>
                <MenuItem>
                  <MenuButton
                    iconLeft={<Logout />}
                    onClick={() => logUserOut()}
                  >
                    <FormattedMessage id="logout" />
                  </MenuButton>
                </MenuItem>
              </React.Fragment>
            ) : null}
            {/* <MenuItem>
              <MenuButton
                iconLeft={<ChevronRight />}
                onClick={() => {
                  const lang = props.language === "nl" ? "en" : "nl";
                  props.setLanguage(lang);
                  localStorage.setItem("lang", lang);
                  window.location.reload();
                }}
              >
                {props.language === "nl" ? "English" : "Nederlands"}
              </MenuButton>
            </MenuItem> */}
          </MenuInline>
        }
      />
      {props.children}
      <Footer style={{ marginTop: 200 }}>
        <FooterTop>
          <Row>
            <Column
              wrap
              span={{
                small: 10,
                medium: 10,
                big: 10,
                large: 10,
                xLarge: 2,
              }}
            ></Column>
            <Column
              wrap
              span={{
                small: 10,
                medium: 10,
                big: 10,
                large: 10,
                xLarge: 10,
              }}
            >
              <FooterSection
                title={<FormattedMessage id="contact" />}
                hideAt="tabletM"
              >
                <BlackP1>
                  <FormattedMessage id="footer-first-paragraph" />
                </BlackP1>
                <BlackP1>
                  <FormattedMessage id="footer-second-paragraph" />
                </BlackP1>
                <BlackP1>
                  <FormattedMessage id="footer-third-paragraph" />
                </BlackP1>
                <BlackP1>
                  <FormattedMessage id="footer-fourth-paragraph" />
                  <Link
                    href="https://formulieren.amsterdam.nl/TripleForms/DirectRegelen/formulier/nl-NL/evAmsterdam/Klachtenformulier.aspx/"
                    target="_blank"
                    variant="inline"
                    style={{
                      color: "#FFFFFF",
                    }}
                  >
                    <FormattedMessage id="mailForm" />
                  </Link>
                </BlackP1>
              </FooterSection>
            </Column>
          </Row>
        </FooterTop>
        <FooterBottom>
          <Link href="https://www.amsterdam.nl/" target="_blank" inList>
            <FormattedMessage id="go-to-bhg" />
          </Link>
          <Link href="https://www.amsterdam.nl/privacy/" inList target="_blank">
            <FormattedMessage id="privacy" />
          </Link>
        </FooterBottom>
      </Footer>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.app.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (language) => {
      dispatch(Creators.languageChange(language));
    },
  };
};

Layout.propTypes = {
  showLogout: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  showLogout: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
