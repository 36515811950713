import React from "react";
import styled from "styled-components";
import { Heading, Paragraph } from "@amsterdam/asc-ui";
import { FormattedMessage } from "react-intl";

export const BlackH1 = styled(Heading)`
  color: #000000;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 38px;
  font-family: Avenir Next LT Pro Demi;

  @media only screen and (max-width: 540px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const BlackH2 = styled(Heading)`
  color: #000000;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 30px;
  font-family: Avenir Next LT Pro Demi;

  @media only screen and (max-width: 540px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const BlackH3 = styled(Heading)`
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  font-family: Avenir Next LT Pro Demi;
`;

export const BlackP1 = styled(Paragraph)`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  font-family: Avenir Next LT Pro Regular;
`;

export const BlackIntro = styled(Paragraph)`
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  font-family: Avenir Next LT Pro Demi;

  @media only screen and (max-width: 540px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ForwradBlackH3 = styled(Heading)`
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  font-family: Avenir Next LT Pro Demi;

  @media only screen and (max-width: 540px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ErrorMessage = styled(BlackP1)`
  margin-top: -4px;
  margin-bottom: 4px;
  color: #ec0000;
  font-family: Avenir Next LT Pro Demi;
`;

export const CustomErrorMessage = ({ id }) => (
  <ErrorMessage>
    <FormattedMessage id={id} />
  </ErrorMessage>
);
