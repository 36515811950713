import { Field } from "formik";
import React from "react";
import styled from "styled-components";

import CustomInput from "../../../../components/Input";
import {
  CustomErrorMessage,
  ErrorMessage,
} from "../../../../components/Typography";

export const isPaymentVoucher = (name) => {
  return name?.toLowerCase().includes("voucher");
};

const VoucherInputWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  width: 200px;
`;

const VoucherCodeInput = ({
  paymentTypes,
  selectedPaymentType,
  paymentObject,
  showVoucherError,
  voucherApiError,
}) => {
  const isVoucherPaymentAvailable = () => {
    return paymentTypes?.filter((payment) => isPaymentVoucher(payment.name))
      .length;
  };
  console.log(voucherApiError);
  if (
    isVoucherPaymentAvailable() &&
    isPaymentVoucher(selectedPaymentType) &&
    isPaymentVoucher(paymentObject.name)
  ) {
    return (
      <VoucherInputWrapper>
        <Field
          name="voucher_code"
          component={CustomInput}
          error={showVoucherError || voucherApiError}
          placeholder="Enter voucher code"
        />
        {showVoucherError && (
          <div>
            <CustomErrorMessage id="selectChoice" />
          </div>
        )}
        {voucherApiError && (
          <div>
            <ErrorMessage>{voucherApiError}</ErrorMessage>
          </div>
        )}
      </VoucherInputWrapper>
    );
  }

  return null;
};

export default VoucherCodeInput;
