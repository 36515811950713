import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    getOwnerRequest: [],
    getOwnerSuccess: ["responseData"],
    getOwnerFailure: ["error"],

    updateOwnerRequest: ["data"],
    updateOwnerSuccess: ["responseData"],
    updateOwnerFailure: ["error"],
  },
  {}
);
