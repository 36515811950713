import { call, put, takeLeading } from "redux-saga/effects";
import { Creators, Types } from "../../redux/auth/actions";
import {
  login as loginApi,
  resetPassword as resetPasswordApi,
  setPassword as setPasswordApi,
} from "../../api/auth";
import { history } from "../../../utils/helpers";

export function* login(actions) {
  try {
    const { email, password } = actions;
    const response = yield call(loginApi, { email, password });
    yield put(Creators.loginSuccess(response.data));
    localStorage.setItem("access_token", response.data.access_token);
    history.push("/personal-info");
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.loginFailure(error_message));
  }
}

export function* watchLogin() {
  yield takeLeading(Types.LOGIN_REQUEST, login);
}

export function* resetPassword(actions) {
  try {
    const { email } = actions;
    const response = yield call(resetPasswordApi, {
      email,
      base_url: document.location.host,
    });
    yield put(Creators.resetPasswordSuccess(response.data));
    history.push("/reset-password/feedback");
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.resetPasswordFailure(error_message));
  }
}

export function* watchResetPassword() {
  yield takeLeading(Types.RESET_PASSWORD_REQUEST, resetPassword);
}

export function* setPassword(actions) {
  try {
    const { password, token } = actions;
    const response = yield call(setPasswordApi, {
      password,
      token,
    });
    yield put(Creators.setPasswordSuccess(response.data));
    history.push("/set-password-feedback");
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.setPasswordFailure(error_message));
  }
}

export function* watchSetPassword() {
  yield takeLeading(Types.SET_PASSWORD_REQUEST, setPassword);
}
