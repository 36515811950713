import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  isloading: false,
  error: null,
  user: {},

  isUpdating: false,
  isUpdatingError: null,
};

export const getOwner = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: true, error: null };
};

export const getOwnerSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    error: null,
    user: action.responseData,
  };
};

export const getOwnerFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    error: action.error,
  };
};

export const updateOwner = (state = INITIAL_STATE, action) => {
  return { ...state, isUpdating: true, isUpdatingError: null };
};

export const updateOwnerSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdating: false,
    isUpdatingError: null,
  };
};

export const updateOwnerFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdating: false,
    isUpdatingError: action.error,
  };
};

export const HANDLERS = {
  [Types.GET_OWNER_REQUEST]: getOwner,
  [Types.GET_OWNER_SUCCESS]: getOwnerSuccess,
  [Types.GET_OWNER_FAILURE]: getOwnerFailure,

  [Types.UPDATE_OWNER_REQUEST]: updateOwner,
  [Types.UPDATE_OWNER_SUCCESS]: updateOwnerSuccess,
  [Types.UPDATE_OWNER_FAILURE]: updateOwnerFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
