import React, { useEffect } from "react";

import CustomSpinner from "../../../../components/Spinner";
import { completeActiveVignetFlow } from "../../../../utils/helpers";

const PaymentSuccessCallback = () => {
  useEffect(() => {
    completeActiveVignetFlow();
    const previousPaidVignetId = localStorage.getItem("previous");
    window.location = `/push-decision?vignet_id=${previousPaidVignetId}&decision=renew&success=true`;
  });

  return <CustomSpinner />;
};

export default PaymentSuccessCallback;
