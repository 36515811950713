import { themeColor } from "@amsterdam/asc-ui";
import React from "react";

import passPropsToChildren from "./PassProps";
import StepByStepNavigationStyle from "./StepByStepNavigationStyle";

const StepByStepNavigation = ({
  children: childrenProp,
  customSize,
  disabledTextColor,
  doneTextColor,
  highlightActive,
  ...otherProps
}) => {
  if (!childrenProp) {
    return null;
  }

  // Pass the props defined below to all the children
  const { children } = passPropsToChildren(childrenProp, {
    customSize,
    disabledTextColor,
    doneTextColor,
    highlightActive,
  });

  return (
    <StepByStepNavigationStyle
      aria-labelledby="menubutton"
      aria-live="polite"
      role="menu"
      {...otherProps}
    >
      {children}
    </StepByStepNavigationStyle>
  );
};

StepByStepNavigation.defaultProps = {
  disabledTextColor: themeColor("tint", "level5"),
  doneTextColor: themeColor("tint", "level4"),
};

export default StepByStepNavigation;
