import React from "react";
import { Row, ErrorMessage } from "@amsterdam/asc-ui";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";

import Layout from "../../components/Layouts";
import CustomSpinner from "../../components/Spinner";
import Steppers from "./components/steppers";
import { CustomColumn } from "../../components";
import { BlackH2, BlackP1 } from "../../components/Typography";

const CustomParagraph = styled.h1`
  padding-bottom: 20px;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #767676;
  color: #767676;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  font-family: Avenir Next LT Pro Demi;
`;

const HeaderWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 13px;
  min-height: 300px;
`;

const AppWrapper = styled.div`
  width: 80%;
  margin: auto;
`;

const AppLayout = (props) => {
  const {
    title,
    headerTitle,
    titleMore,
    subtitle,
    children,
    isSubmitting,
    isLoading,
    error,
    hasForm,
    initialValues,
    validationSchema,
    onSubmit,
    step,
    steps,
    showSteppers,
    disabled,
  } = props;
  return (
    <AppWrapper>
      <Layout showLogout={false}>
        <Row>
          <CustomColumn size={3} small={0}>
            {showSteppers ? (
              <Steppers step={step} steps={steps} disabled={disabled} />
            ) : null}
          </CustomColumn>
          <CustomColumn size={9}>
            <HeaderWrapper>
              <CustomParagraph forwardedAs="h1">{headerTitle}</CustomParagraph>
              <BlackH2 forwardedAs="h2">
                {title} {titleMore}
              </BlackH2>
              <BlackP1 style={{ marginTop: "8px" }}>{subtitle}</BlackP1>
            </HeaderWrapper>
            <Wrapper>
              {error && <ErrorMessage message={error} />}
              {isSubmitting ? <CustomSpinner /> : null}
              {isLoading ? (
                <CustomSpinner />
              ) : hasForm ? (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ handleSubmit }) => (
                    <Form>
                      {React.cloneElement(children, { handleSubmit })}
                    </Form>
                  )}
                </Formik>
              ) : (
                children
              )}
            </Wrapper>
          </CustomColumn>
        </Row>
      </Layout>
    </AppWrapper>
  );
};

AppLayout.defaultProps = {
  showSteppers: true,
  titleMore: null,
  subtitle: null,
};

export default AppLayout;
