import React, { useEffect, useRef, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import SubmitButton from "../../../../../components/Button";
import { parseQuery } from "../../../../../utils/helpers";
import { DayVignetLayout } from "../..";
import SummaryDetails from "../../../components/Summary";
import TransitStorage from "../../../../../utils/storage";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../../../constants";

const Summary = ({
  isMakingMooringPayment,
  error,
  makeMooringPayment,
  location,
}) => {
  const failureMessageRef = useRef();
  useEffect(() => {
    const query = parseQuery(location?.search);
    if (query?.failedPayment && failureMessageRef.current) {
      setTimeout(() => {
        failureMessageRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 1000);
    }
  }, [location?.search]);

  const memoizedHandleSumbit = useCallback(() => {
    return;
  }, []);
  const userInfo = TransitStorage.getDayVignettePersonalInfoSchema();
  return (
    <DayVignetLayout
      step={4}
      title={<FormattedMessage id="dayStep5" />}
      subtitle={<FormattedMessage id="dayVignetteSummaryDescription" />}
      isLoading={isMakingMooringPayment}
      error={error}
      hasForm
      initialValues={{ agree_1: "", agree_2: "" }}
      onSubmit={(values) => {
        makeMooringPayment();
      }}
    >
      <React.Fragment>
        <div ref={failureMessageRef}>
          <SummaryDetails
            data={[
              {
                title: "dayStep3",
                route: `${TRANSIT_VIGNETTE_ROUTE}/mooring-request/personal-info`,
                info: [
                  {
                    label: "dayVignetteFirstName",
                    value: userInfo?.initials,
                  },
                  {
                    label: "lastname",
                    value: userInfo?.lastname,
                  },
                  {
                    label: "insertion",
                    value: userInfo?.prefix,
                  },
                  {
                    label: "telephone",
                    value: userInfo?.mobile_phone,
                  },
                  {
                    label: "email",
                    value: userInfo?.email,
                  },
                ],
              },
              // {
              //   title: "dayVignettePaymentGuideLabel",
              //   route: `${TRANSIT_VIGNETTE_ROUTE}/mooring-request/payment-guide`,
              //   disableChange: true,
              //   info: [
              //     {
              //       label: "dayVignettePaymentGuideLabel1",
              //       value: `${moment().format("DD MMMM YYYY")}, 16.00 uur`,
              //     },
              //     {
              //       label: "dayVignettePaymentGuideLabel2",
              //       value: `${moment()
              //         .add(1, "days")
              //         .format("DD MMMM YYYY")}, 16.00 uur`,
              //     },
              //   ],
              // },
              {
                title: "payment",
                route: `${TRANSIT_VIGNETTE_ROUTE}/mooring-request/payment-choice`,
                info: [
                  {
                    label: "paymentMethod2",
                    value: localStorage.getItem("paymentChoice"),
                  },
                ],
              },
            ]}
            totalStruct={[
              {
                label: "totalAmount",
                value:
                  localStorage.getItem("paymentChoice") === "Voucher"
                    ? "€ 0"
                    : "€ 30,00",
                items: [
                  {
                    label: "total",
                    value:
                      localStorage.getItem("paymentChoice") === "Voucher"
                        ? "€ 0"
                        : "€ 30,00",
                  },
                ],
              },
            ]}
            failedPayment={parseQuery(location?.search)?.failedPayment}
          />
          <SubmitButton
            taskflow={false}
            onNext={memoizedHandleSumbit}
            nextTitle="sendNow"
          />
        </div>
      </React.Fragment>
    </DayVignetLayout>
  );
};

export default Summary;
