import { call, put, takeLeading } from "redux-saga/effects";
import { Creators, Types } from "../../redux/payments/actions";
import {
  getPaymentTypes as getPaymentTypesApi,
  accountNumberPayment as accountNumberPaymentApi,
  bankPayment as bankPaymentApi,
  checkIbanValidation as checkIbanValidationApi,

  // TRANSIT VIGNETTES
  getNewPaymentTypes as getNewPaymentTypesApi,
  makeNewPayment as makeNewPaymentApi,
  getMooringPaymentTypes as getMooringPaymentTypesApi,
  makeMooringPayment as makeMooringPaymentApi,
} from "../../api/payments";
import {
  CURRENT_URL,
  getNextAvailableVignet,
  completeActiveVignetFlow,
  history,
} from "../../../utils/helpers";
import TransitStorage from "../../../utils/storage";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../constants";

export function* getPaymentTypes(actions) {
  try {
    const response = yield call(getPaymentTypesApi);
    yield put(Creators.getPaymentTypesSuccess(response.data));
    localStorage.setItem("payment_types", JSON.stringify(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getPaymentTypesFailure(error_message));
  }
}

export function* watchGetPaymentTypes() {
  yield takeLeading(Types.GET_PAYMENT_TYPES_REQUEST, getPaymentTypes);
}

export function* accountNumberPayment(actions) {
  try {
    const { data } = actions;
    const response = yield call(accountNumberPaymentApi, { ...data });
    yield put(Creators.accountNumberPaymentSuccess(response.data));
    completeActiveVignetFlow();
    const nextVignet = getNextAvailableVignet();
    if (nextVignet) {
      window.location = `/push-decision?vignet_id=${nextVignet.vignet_id}&decision=${nextVignet.decision}`;
    } else window.location = "/done";
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.accountNumberPaymentFailure(error_message));
  }
}

export function* watchPayViaAccountNumber() {
  yield takeLeading(Types.ACCOUNT_NUMBER_PAYMENT_REQUEST, accountNumberPayment);
}

export function* bankPayment(actions) {
  try {
    const { data } = actions;
    const nextVignet = getNextAvailableVignet();
    const response = yield call(bankPaymentApi, {
      ...data,
      return_url_success: `${CURRENT_URL}/payment-success-callback/`,
      return_url_cancel: `${CURRENT_URL}/push-decision?vignet_id=${nextVignet.vignet_id}&decision=${nextVignet.decision}&failed=true`,
      return_url_error: `${CURRENT_URL}/push-decision?vignet_id=${nextVignet.vignet_id}&decision=${nextVignet.decision}&failed=true`,
      return_url_reject: `${CURRENT_URL}/push-decision?vignet_id=${nextVignet.vignet_id}&decision=${nextVignet.decision}&failed=true`,
    });
    yield put(Creators.bankPaymentSuccess(response.data));
    localStorage.setItem("summary", response.data.summery);
    window.location = response.data.redirect_url;
    localStorage.setItem("previous", data.vignet_id);
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.bankPaymentFailure(error_message));
  }
}

export function* watchPayViaBankPayment() {
  yield takeLeading(Types.BANK_PAYMENT_REQUEST, bankPayment);
}

export function* checkIbanValidation(actions) {
  try {
    const { data } = actions;
    const response = yield call(checkIbanValidationApi, { ...data });
    yield put(Creators.checkIbanValidationSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.checkIbanValidationFailure(error_message));
  }
}

export function* watchCheckIbanValidation() {
  yield takeLeading(Types.CHECK_IBAN_VALIDATION_REQUEST, checkIbanValidation);
}

// TRANSIT VIGNETTES
export function* getNewPaymentTypes(actions) {
  try {
    const response = yield call(getNewPaymentTypesApi);
    yield put(Creators.getNewPaymentTypesSuccess(response.data.payment_types));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getNewPaymentTypesFailure(error_message));
  }
}

export function* watchGetNewPaymentTypes() {
  yield takeLeading(Types.GET_NEW_PAYMENT_TYPES, getNewPaymentTypes);
}

export function* makeNewPayment(actions) {
  try {
    const vignetInfo = TransitStorage.getNewVignetteDetailsSchema();
    const paymentData = {
      ...TransitStorage.getNewVignettePersonalInfoSchema(),
      vignet_type_id: vignetInfo.vignetKind,
      ...TransitStorage.getNewVignettePaymentInfo(),
      delivery_type_id: TransitStorage.getNewVignetteDeliveryInfoSchema()
        ?.deliveryType,
      vignet_count: vignetInfo.vignetNumber,
      return_url_success: `${CURRENT_URL}${TRANSIT_VIGNETTE_ROUTE}/new-vignette/done`,
      return_url_cancel: `${CURRENT_URL}${TRANSIT_VIGNETTE_ROUTE}/new-vignette/summary?failedPayment=true`,
      return_url_error: `${CURRENT_URL}${TRANSIT_VIGNETTE_ROUTE}/new-vignette/summary?failedPayment=true`,
      return_url_reject: `${CURRENT_URL}${TRANSIT_VIGNETTE_ROUTE}/new-vignette/summary?failedPayment=true`,
    };
    const response = yield call(makeNewPaymentApi, { ...paymentData });
    yield put(Creators.makeNewPaymentSuccess());
    localStorage.setItem("access_token", response.data.access_token);
    localStorage.setItem("request_number", response.data.request_number);
    window.location = response.data.redirect_url;
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.makeNewPaymentFailure(error_message));
  }
}

export function* watchmakeNewPayment() {
  yield takeLeading(Types.MAKE_NEW_PAYMENT, makeNewPayment);
}

export function* getMooringPaymentTypes(actions) {
  try {
    const response = yield call(getMooringPaymentTypesApi);
    yield put(
      Creators.getMooringPaymentTypesSuccess(response.data.payment_types)
    );
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getMooringPaymentTypesFailure(error_message));
  }
}

export function* watchGetMooringPaymentTypes() {
  yield takeLeading(Types.GET_MOORING_PAYMENT_TYPES, getMooringPaymentTypes);
}

export function* makeMooringPayment(actions) {
  try {
    const paymentData = {
      email: TransitStorage.getDayVignettePersonalInfoSchema().email,
      phonenumber: TransitStorage.getDayVignettePersonalInfoSchema()
        .mobile_phone,
      initials: TransitStorage.getDayVignettePersonalInfoSchema().initials,
      lastname: TransitStorage.getDayVignettePersonalInfoSchema().lastname,
      prefix: TransitStorage.getDayVignettePersonalInfoSchema().prefix,
      vignet: TransitStorage.getDayVignetteDetailsSchema().vignet,
      ...TransitStorage.getDayVignettePaymentInfo(),
      return_url_success: `${CURRENT_URL}${TRANSIT_VIGNETTE_ROUTE}/mooring-request/done`,
      return_url_cancel: `${CURRENT_URL}${TRANSIT_VIGNETTE_ROUTE}/mooring-request/summary?failedPayment=true`,
      return_url_error: `${CURRENT_URL}${TRANSIT_VIGNETTE_ROUTE}/mooring-request/summary?failedPayment=true`,
      return_url_reject: `${CURRENT_URL}${TRANSIT_VIGNETTE_ROUTE}/mooring-request/summary?failedPayment=true`,
    };
    const response = yield call(makeMooringPaymentApi, { ...paymentData });
    yield put(Creators.mooringMakePaymentSuccess());
    localStorage.setItem("access_token", response.data.access_token);
    localStorage.setItem("request_number", response.data.request_number);
    if (localStorage.getItem("paymentChoice") === "Voucher") {
      history.push(`${TRANSIT_VIGNETTE_ROUTE}/mooring-request/done`);
    } else {
      window.location = response.data.redirect_url;
    }
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.mooringMakePaymentFailure(error_message));
  }
}

export function* watchMakeMooringPayment() {
  yield takeLeading(Types.MOORING_MAKE_PAYMENT, makeMooringPayment);
}
