import { createTypes } from "reduxsauce";

export default createTypes(
  `
  LOGIN_REQUEST
  LOGIN_SUCCESS
  LOGIN_FAILURE

  RESET_PASSWORD_REQUEST
  RESET_PASSWORD_SUCCESS
  RESET_PASSWORD_FAILURE

  SET_PASSWORD_REQUEST
  SET_PASSWORD_SUCCESS
  SET_PASSWORD_FAILURE
 
  LOGOUT
`,
  {}
);
