import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import {
  CustomColumn,
  CustomRow,
  DetailContainer,
} from "../../../../components";
import CustomLink from "../../../../components/Link/link";
import {
  BlackH3,
  BlackIntro,
  BlackP1,
} from "../../../../components/Typography";

const ShowDefaultVignet = ({
  vignet,
  setShouldEdit,
  setShowSummary,
  setShowPayment,
  setShowDocumentSummary,
  shouldEdit,
  setDefaultDriveTypeError,
  readOnlyMode,
  setShowFailedPayment,
}) => {
  return (
    <React.Fragment>
      <BlackIntro style={{ fontWeight: "600" }}>
        <FormattedMessage id="boatName" />
      </BlackIntro>
      <BlackP1 style={{ paddingBottom: "15px", marginTop: "-10px" }}>
        {vignet.object_name}
      </BlackP1>
      <BlackIntro style={{ fontWeight: "600" }}>
        <FormattedMessage id="vignetNumber" />
      </BlackIntro>
      <BlackP1 style={{ paddingBottom: "15px", marginTop: "-10px" }}>
        {vignet.vignet_number}
      </BlackP1>
      <DetailContainer>
        <div style={{ width: "70%", marginBottom: "10px" }}>
          <BlackH3 forwardedAs="h3">
            <FormattedMessage id="vignetDetails" />
          </BlackH3>
        </div>
        <div style={{ width: "30%" }}>
          {!shouldEdit && !readOnlyMode && (
            <CustomLink
              variant="inline"
              onClick={(e) => {
                e.preventDefault();
                setShouldEdit(true);
                setShowSummary(false);
                setShowPayment(false);
                setShowDocumentSummary(false);
                setDefaultDriveTypeError(false);
                setShowFailedPayment(false);
              }}
            >
              <FormattedMessage id="change" />
            </CustomLink>
          )}
        </div>
      </DetailContainer>
      <CustomRow>
        <CustomColumn size={2}>
          <BlackIntro>
            <FormattedMessage id="length" />
          </BlackIntro>
        </CustomColumn>
        <CustomColumn size={2}>
          <BlackIntro>
            <FormattedMessage id="width" />
          </BlackIntro>
        </CustomColumn>
        <CustomColumn size={4} />
      </CustomRow>
      {!shouldEdit && (
        <CustomRow style={{ marginTop: "-10px" }}>
          <CustomColumn size={2}>
            <BlackP1>{vignet.object_length} meter</BlackP1>
            <br />
          </CustomColumn>
          <CustomColumn size={2}>
            <BlackP1>{vignet.object_width} meter</BlackP1>
          </CustomColumn>
          <CustomColumn size={4} />
        </CustomRow>
      )}
    </React.Fragment>
  );
};

ShowDefaultVignet.propTypes = {
  vignet: PropTypes.shape({}).isRequired,
  setShouldEdit: PropTypes.func.isRequired,
  setShowSummary: PropTypes.func.isRequired,
  setShowPayment: PropTypes.func.isRequired,
  setShowDocumentSummary: PropTypes.func.isRequired,
  shouldEdit: PropTypes.bool.isRequired,
};

export default ShowDefaultVignet;
