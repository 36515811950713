import React from "react";
import { Button, Link, ErrorMessage } from "@amsterdam/asc-ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import AuthWrapper from "../../components/Wrapper";
import CustomInput from "../../../../components/Input";
import CustomSpinner from "../../../../components/Spinner";
import { history } from "../../../../utils/helpers";
import { BlackH2 } from "../../../../components/Typography";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("invalidEmail").required("emailRequired").trim(),
  password: Yup.string().required("passwordRequird").trim(),
});

const Wrapper = styled.div`
  width: 70%;

  @media only screen and (max-width: 468px) {
    width: 100%;
  }
`;

const CustomHeader = styled(BlackH2)`
  padding-bottom: 0.68em;
  margin-top: -0.2em;
`;

const LoginForm = ({ isloading, error, login }) => {
  return (
    <AuthWrapper
      title={<FormattedMessage id="welcome" />}
      info={<FormattedMessage id="login-info" />}
    >
      <Helmet>
        <meta
          http-equiv="clear-site-data"
          content='"cache","storage","cookies"'
        />
      </Helmet>
      <CustomHeader forwardedAs="h2">
        <FormattedMessage id="login" />
      </CustomHeader>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          login(values.email, values.password);
        }}
      >
        {() => (
          <Form>
            <Wrapper>
              <Field
                name="email"
                component={CustomInput}
                label={<FormattedMessage id="email-address" />}
                style={{ width: "300px" }}
              />
              <Field
                name="password"
                type="password"
                component={CustomInput}
                label={<FormattedMessage id="password" />}
                style={{ width: "300px" }}
              />
            </Wrapper>
            <div style={{ margin: "5px 0px 25px 0px" }}>
              <Link
                href="#"
                variant="inline"
                style={{ fontFamily: "Avenir Next LT Pro Regular" }}
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/reset-password");
                }}
              >
                <FormattedMessage id="forgot-password" />?
              </Link>
            </div>
            {isloading ? <CustomSpinner /> : null}
            {error && <ErrorMessage message={error} />}
            <Button variant="secondary" type="submit" disabled={isloading}>
              <FormattedMessage id="login" />
            </Button>
          </Form>
        )}
      </Formik>
    </AuthWrapper>
  );
};

LoginForm.propTypes = {
  isloading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  login: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  error: null,
};

export default LoginForm;
