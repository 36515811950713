import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { mergeSchemas } from "../../../../../utils/helpers";
import { NewVignetteLayout } from "../..";
import PersonalInfoForm, {
  ContactSchema,
  CompanySchema,
} from "../../../components/personal-info-form";
import TransitStorage from "../../../../../utils/storage";

const PersonalInfo = ({ validateOwner, isValidatingPassageOwner, error }) => {
  const [showCompany, setShowCompany] = useState(
    TransitStorage.getNewVignettePersonalInfoSchema()?.kvk
  );
  return (
    <NewVignetteLayout
      step={1}
      title={<FormattedMessage id="step1" />}
      subtitle={<FormattedMessage id="newVignettePersonalInfoDescription" />}
      isLoading={isValidatingPassageOwner}
      error={error}
      hasForm
      initialValues={{ ...TransitStorage.getNewVignettePersonalInfoSchema() }}
      validationSchema={
        showCompany ? mergeSchemas(ContactSchema, CompanySchema) : ContactSchema
      }
      onSubmit={(values) => {
        if (!showCompany) {
          // We need to remove this from the form as the user has used the checkbox to state that
          // the vignette is not for a company even if they have previously entered info for company info.
          delete values.company;
          delete values.kvk;
        }
        TransitStorage.persistNewVignettePersonalInfoSchema(values);
        validateOwner(values);
      }}
    >
      <PersonalInfoForm
        setShowCompany={setShowCompany}
        showCompany={showCompany}
        initialsFieldLabel="dayVignetteFirstName"
        defaultCountry={
          TransitStorage.getNewVignettePersonalInfoSchema()?.country_id
        }
      />
    </NewVignetteLayout>
  );
};

export default PersonalInfo;
