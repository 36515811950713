import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  isloading: false,
  error: null,
  vignets: {},

  isCancelling: false,
  cancellingError: null,
  cancelVignetReason: null,
  cancelledVignet: false,

  isGettingVignet: false,
  isGettingVignetError: null,
  vignet: null,

  isGettingDriveTypes: false,
  isGettingDriveTypesError: null,
  driveTypes: [],

  isUpdatingVignet: false,
  isUpdatingVignetError: null,
  updatedVignet: {},

  uploadingDocument: false,
  uploadDocumentError: null,

  isGettingSummaryLink: false,
  summaryLink: null,
  summaryLinkError: null,

  // TANSIT VIGNETTES
  isGettingVignetteTypes: false,
  vignetTypes: null,

  isGettingDeliveryTypes: false,
  deliveryTypes: null,

  isCheckingMooringVignette: false,
  mooringVignette: null,

  isValidatingPassageOwner: false,

  isValidatingPassageVignet: false,

  // ACTIVATE VIGNETTES
  isActivatingCheckVignette: false,

  isActivatingGetDriveTypes: false,
  activateGetDriveTypes: [],

  isUpdatingOwner: false,
  isUpdatingActivateVignet: false,

  isValidatingOwner: false,
  isValidatingActivateVignet: false,

  isUploadingActivatingPhotos: false,

  isSavingActivatedData: false,

  isGettingActivateSummary: false,

  isGettingCountries: false,
  countries: [],
  countryError: false,

  isGettingSummary: false,

  isValidatingVoucherCode: false,

  isGettingObjectTypes: false,
  objectTypes: null,
};

export const getVignets = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: true, error: false };
};

export const getVignetsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    error: null,
    vignets: action.responseData,
  };
};

export const getVignetsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    error: action.error,
  };
};

export const cancelVignet = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCancelling: true,
    cancellingError: false,
    cancelVignetReason: action.data.reason,
    cancelledVignet: false,
  };
};

export const cancelVignetSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCancelling: false,
    cancellingError: null,
    cancelledVignet: true,
  };
};

export const cancelVignetFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCancelling: false,
    cancellingError: action.error,
    cancelVignetReason: null,
    cancelledVignet: false,
  };
};

export const getVignet = (state = INITIAL_STATE, action) => {
  return { ...state, isGettingVignet: true, isGettingVignetError: null };
};

export const getVignetSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVignet: false,
    isGettingVignetError: null,
    vignet: action.responseData,
  };
};

export const getVignetFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVignet: false,
    isGettingVignetError: action.error,
    vignet: null,
  };
};

export const getDriveTypes = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingDriveTypes: true,
    isGettingDriveTypesError: false,
  };
};

export const getDriveTypesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingDriveTypes: false,
    isGettingDriveTypesError: null,
    driveTypes: action.responseData.drive_types,
  };
};

export const getDriveTypesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingDriveTypes: false,
    isGettingDriveTypesError: action.error,
  };
};

export const updateVignet = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingVignet: true,
    isUpdatingVignetError: false,
  };
};

export const updateVignetSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingVignet: false,
    isUpdatingVignetError: null,
    updatedVignet: action.responseData,
  };
};

export const updateVignetFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingVignet: false,
    isUpdatingVignetError: action.error,
  };
};

export const uploadDocument = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadingDocument: true,
    uploadDocumentError: false,
  };
};

export const uploadDocumentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadingDocument: false,
    uploadDocumentError: null,
  };
};

export const uploadDocumentFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadingDocument: false,
    uploadDocumentError: action.error,
  };
};

export const getSummaryLink = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingSummaryLink: true,
    summaryLinkError: false,
  };
};

export const getSummaryLinkSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingSummaryLink: false,
    summaryLink: action.responseData,
    summaryLinkError: null,
  };
};

export const getSummaryLinkFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingSummaryLink: false,
    summaryLinkError: action.error,
  };
};

// TRANSIT VIGNETTES
export const getVignetTypes = (state = INITIAL_STATE, action) => {
  return { ...state, isGettingVignetteTypes: true, error: false };
};

export const getVignetTypesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVignetteTypes: false,
    error: null,
    vignetTypes: action.responseData,
  };
};

export const getVignetTypesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVignetteTypes: false,
    error: action.error,
  };
};

export const getDeliveryTypes = (state = INITIAL_STATE, action) => {
  return { ...state, isGettingDeliveryTypes: true, error: false };
};

export const getDeliveryTypesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingDeliveryTypes: false,
    error: null,
    deliveryTypes: action.responseData,
  };
};

export const getDeliveryTypesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingDeliveryTypes: false,
    error: action.error,
  };
};

export const checkMooringVignette = (state = INITIAL_STATE, action) => {
  return { ...state, isCheckingMooringVignette: true, error: false };
};

export const checkMooringVignetteSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCheckingMooringVignette: false,
    error: null,
    mooringVignette: action.responseData,
  };
};

export const checkMooringVignetteFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCheckingMooringVignette: false,
    error: action.error,
  };
};

// ACTIVATE VIGNETTES
export const activatingCheckVignette = (state = INITIAL_STATE, action) => {
  return { ...state, isActivatingCheckVignette: true, error: false };
};

export const activatingCheckVignetteSuccess = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isActivatingCheckVignette: false,
    error: null,
  };
};

export const activatingCheckVignetteFailure = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isActivatingCheckVignette: false,
    error: action.error,
  };
};

export const activatingGetDriveTypes = (state = INITIAL_STATE, action) => {
  return { ...state, isActivatingGetDriveTypes: true, error: false };
};

export const activatingGetDriveTypesSuccess = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isActivatingGetDriveTypes: false,
    error: null,
    activateGetDriveTypes: action.responseData,
  };
};

export const activatingGetDriveTypesFailure = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isActivatingGetDriveTypes: false,
    error: action.error,
  };
};

export const activateUpdateOwner = (state = INITIAL_STATE, action) => {
  return { ...state, isUpdatingOwner: true, error: false };
};

export const activateUpdateOwnerSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingOwner: false,
    error: null,
  };
};

export const activateUpdateOwnerFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingOwner: false,
    error: action.error,
  };
};

export const activateUpdateVignet = (state = INITIAL_STATE, action) => {
  return { ...state, isUpdatingActivateVignet: true, error: false };
};

export const activateUpdateVignetSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingActivateVignet: false,
    error: null,
  };
};

export const activateUpdateVignetFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingActivateVignet: false,
    error: action.error,
  };
};

export const activateValidateOwner = (state = INITIAL_STATE, action) => {
  return { ...state, isValidatingOwner: true, error: false };
};

export const activateValidateOwnerSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isValidatingOwner: false,
    error: null,
  };
};

export const activateValidateOwnerFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isValidatingOwner: false,
    error: action.error,
  };
};

export const activateValidateVignet = (state = INITIAL_STATE, action) => {
  return { ...state, isValidatingActivateVignet: true, error: false };
};

export const activateValidateVignetSuccess = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isValidatingActivateVignet: false,
    error: null,
  };
};

export const activateValidateVignetFailure = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isValidatingActivateVignet: false,
    error: action.error,
  };
};

export const activateUploadPhotos = (state = INITIAL_STATE, action) => {
  return { ...state, isUploadingActivatingPhotos: true, error: false };
};

export const activateUploadPhotosSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUploadingActivatingPhotos: false,
    error: null,
  };
};

export const activateUploadPhotosFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUploadingActivatingPhotos: false,
    error: action.error,
  };
};

export const validatePassageOwner = (state = INITIAL_STATE, action) => {
  return { ...state, isValidatingPassageOwner: true, error: false };
};

export const validatePassageOwnerSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isValidatingPassageOwner: false,
    error: null,
  };
};

export const validatePassageOwnerFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isValidatingPassageOwner: false,
    error: action.error,
  };
};

export const validatePassageVignet = (state = INITIAL_STATE, action) => {
  return { ...state, isValidatingPassageVignet: true, error: false };
};

export const validatePassageVignetSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isValidatingPassageVignet: false,
    error: null,
  };
};

export const validatePassageVignetFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isValidatingPassageVignet: false,
    error: action.error,
  };
};

export const activateSaveData = (state = INITIAL_STATE, action) => {
  return { ...state, isSavingActivatedData: true, error: false };
};

export const activateSaveDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isSavingActivatedData: false,
    error: null,
  };
};

export const activateSaveDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isSavingActivatedData: false,
    error: action.error,
  };
};

export const activateGetSummary = (state = INITIAL_STATE, action) => {
  return { ...state, isGettingActivateSummary: true, error: false };
};

export const activateGetSummarySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingActivateSummary: false,
    summaryLink: action.responseData,
    error: null,
  };
};

export const activateGetSummaryFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingActivateSummary: false,
    error: action.error,
  };
};

export const newVignetteCountries = (state = INITIAL_STATE, action) => {
  return { ...state, isGettingCountries: true, countryError: false };
};

export const newVignetteCountriesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingCountries: false,
    countries: action.responseData.countries,
    countryError: null,
  };
};

export const newVignetteCountriesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingCountries: false,
    countryError: action.error,
  };
};

export const validateVoucherCode = (state = INITIAL_STATE, action) => {
  return { ...state, isValidatingVoucherCode: true, error: false };
};

export const validateVoucherCodeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isValidatingVoucherCode: false,
    error: null,
  };
};

export const validateVoucherCodeFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isValidatingVoucherCode: false,
    error: action.error,
  };
};

export const activateGetObjectTypes = (state = INITIAL_STATE, action) => {
  return { ...state, isGettingObjectTypes: true, error: false };
};

export const activateGetObjectTypesSuccess = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isGettingObjectTypes: false,
    error: null,
    objectTypes: action.responseData,
  };
};

export const activateGetObjectTypesFailure = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isGettingObjectTypes: false,
    error: action.error,
  };
};

export const HANDLERS = {
  [Types.GET_VIGNETS_REQUEST]: getVignets,
  [Types.GET_VIGNETS_SUCCESS]: getVignetsSuccess,
  [Types.GET_VIGNETS_FAILURE]: getVignetsFailure,

  [Types.CANCEL_VIGNET_REQUEST]: cancelVignet,
  [Types.CANCEL_VIGNET_SUCCESS]: cancelVignetSuccess,
  [Types.CANCEL_VIGNET_FAILURE]: cancelVignetFailure,

  [Types.GET_VIGNET_REQUEST]: getVignet,
  [Types.GET_VIGNET_SUCCESS]: getVignetSuccess,
  [Types.GET_VIGNET_FAILURE]: getVignetFailure,

  [Types.GET_DRIVE_TYPES_REQUEST]: getDriveTypes,
  [Types.GET_DRIVE_TYPES_SUCCESS]: getDriveTypesSuccess,
  [Types.GET_DRIVE_TYPES_FAILURE]: getDriveTypesFailure,

  [Types.UPDATE_VIGNET_REQUEST]: updateVignet,
  [Types.UPDATE_VIGNET_SUCCESS]: updateVignetSuccess,
  [Types.UPDATE_VIGNET_FAILURE]: updateVignetFailure,

  [Types.UPLOAD_DOCUMENTS_REQUEST]: uploadDocument,
  [Types.UPLOAD_DOCUMENTS_SUCCESS]: uploadDocumentSuccess,
  [Types.UPLOAD_DOCUMENTS_FAILURE]: uploadDocumentFailure,

  [Types.GET_SUMMARY_LINK_REQUEST]: getSummaryLink,
  [Types.GET_SUMMARY_LINK_SUCCESS]: getSummaryLinkSuccess,
  [Types.GET_SUMMARY_LINK_FAILURE]: getSummaryLinkFailure,

  // TRANSIT VIGNETTES
  [Types.GET_VIGNETTE_TYPES]: getVignetTypes,
  [Types.GET_VIGNETTE_TYPES_SUCCESS]: getVignetTypesSuccess,
  [Types.GET_VIGNETTE_TYPES_FAILURE]: getVignetTypesFailure,

  [Types.GET_DELIVERY_TYPES]: getDeliveryTypes,
  [Types.GET_DELIVERY_TYPES_SUCCESS]: getDeliveryTypesSuccess,
  [Types.GET_DELIVERY_TYPES_FAILURE]: getDeliveryTypesFailure,

  [Types.CHECK_MOORING_VIGNETTE]: checkMooringVignette,
  [Types.CHECK_MOORING_VIGNETTE_SUCCESS]: checkMooringVignetteSuccess,
  [Types.CHECK_MOORING_VIGNETTE_FAILURE]: checkMooringVignetteFailure,

  // ACTIVATE VIGNETTES
  [Types.ACTIVATE_CHECK_VIGNETTE]: activatingCheckVignette,
  [Types.ACTIVATE_CHECK_VIGNETTE_SUCCESS]: activatingCheckVignetteSuccess,
  [Types.ACTIVATE_CHECK_VIGNETTE_FAILURE]: activatingCheckVignetteFailure,

  [Types.ACTIVATE_GET_DRIVE_TYPES]: activatingGetDriveTypes,
  [Types.ACTIVATE_GET_DRIVE_TYPES_SUCCESS]: activatingGetDriveTypesSuccess,
  [Types.ACTIVATE_GET_DRIVE_TYPES_FAILURE]: activatingGetDriveTypesFailure,

  [Types.ACTIVATE_UPDATE_VIGNET]: activateUpdateOwner,
  [Types.ACTIVATE_UPDATE_VIGNET_SUCCESS]: activateUpdateOwnerSuccess,
  [Types.ACTIVATE_UPDATE_VIGNET_FAILURE]: activateUpdateOwnerFailure,

  [Types.ACTIVATE_UPDATE_OWNER]: activateUpdateVignet,
  [Types.ACTIVATE_UPDATE_OWNER_SUCCESS]: activateUpdateVignetSuccess,
  [Types.ACTIVATE_UPDATE_OWNER_FAILURE]: activateUpdateVignetFailure,

  [Types.ACTIVATE_VALIDATE_VIGNET]: activateValidateVignet,
  [Types.ACTIVATE_VALIDATE_VIGNET_SUCCESS]: activateValidateVignetSuccess,
  [Types.ACTIVATE_VALIDATE_VIGNET_FAILURE]: activateValidateVignetFailure,

  [Types.ACTIVATE_VALIDATE_OWNER]: activateValidateOwner,
  [Types.ACTIVATE_VALIDATE_OWNER_SUCCESS]: activateValidateOwnerSuccess,
  [Types.ACTIVATE_VALIDATE_OWNER_FAILURE]: activateValidateOwnerFailure,

  [Types.ACTIVATE_UPLOAD_DOCUMENTS]: activateUploadPhotos,
  [Types.ACTIVATE_UPLOAD_DOCUMENTS_SUCCESS]: activateUploadPhotosSuccess,
  [Types.ACTIVATE_UPLOAD_DOCUMENTS_FAILURE]: activateUploadPhotosFailure,

  [Types.VALIDATE_PASSAGE_OWNER]: validatePassageOwner,
  [Types.VALIDATE_PASSAGE_OWNER_SUCCESS]: validatePassageOwnerSuccess,
  [Types.VALIDATE_PASSAGE_OWNER_FAILURE]: validatePassageOwnerFailure,

  [Types.VALIDATE_PASSAGE_VIGNET]: validatePassageVignet,
  [Types.VALIDATE_PASSAGE_VIGNET_SUCCESS]: validatePassageVignetSuccess,
  [Types.VALIDATE_PASSAGE_VIGNET_FAILURE]: validatePassageVignetFailure,

  [Types.ACTIVATE_SAVE_DATA]: activateSaveData,
  [Types.ACTIVATE_SAVE_DATA_SUCCESS]: activateSaveDataSuccess,
  [Types.ACTIVATE_SAVE_DATA_FAILURE]: activateSaveDataFailure,

  [Types.ACTIVATE_GET_SUMMARY]: activateGetSummary,
  [Types.ACTIVATE_GET_SUMMARY_SUCCESS]: activateGetSummarySuccess,
  [Types.ACTIVATE_GET_SUMMARY_FAILURE]: activateGetSummaryFailure,

  [Types.NEW_VIGNETTE_COUNTRIES]: newVignetteCountries,
  [Types.NEW_VIGNETTE_COUNTRIES_SUCCESS]: newVignetteCountriesSuccess,
  [Types.NEW_VIGNETTE_COUNTRIES_FAILURE]: newVignetteCountriesFailure,

  [Types.MOORING_SUMMARY]: getSummaryLink,
  [Types.MOORING_SUMMARY_SUCCESS]: getSummaryLinkSuccess,
  [Types.MOORING_SUMMARY_FAILURE]: getSummaryLinkFailure,

  [Types.NEW_VIGNETTE_SUMMARY]: getSummaryLink,
  [Types.NEW_VIGNETTE_SUMMARY_SUCCESS]: getSummaryLinkSuccess,
  [Types.NEW_VIGNETTE_SUMMARY_FAILURE]: getSummaryLinkFailure,

  [Types.VALIDATE_VOUCHER_CODE]: validateVoucherCode,
  [Types.VALIDATE_VOUCHER_CODE_SUCCESS]: validateVoucherCodeSuccess,
  [Types.VALIDATE_VOUCHER_CODE_FAILURE]: validateVoucherCodeFailure,

  [Types.ACTIVATE_GET_OBJECT_TYPES]: activateGetObjectTypes,
  [Types.ACTIVATE_GET_OBJECT_TYPES_SUCCESS]: activateGetObjectTypesSuccess,
  [Types.ACTIVATE_GET_OBJECT_TYPES_FAILURE]: activateGetObjectTypesFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
