import * as Yup from "yup";

export const ExtendSchema = Yup.object().shape({
  object_length: Yup.string().required("lengthRequired"),
  object_width: Yup.string().required("widthRequired"),
  object_drive_type_id: Yup.string().required("required"),
  object_marina: Yup.string().required("required"),
});

export const AccountHolderSchema = Yup.object().shape({
  account_number: Yup.string()
    .required("accountNumberRequired")
    .min("17", "accountNumberInvalid")
    .trim(),
  account_holder: Yup.string().required("accountHolderRequired").trim(),
});

export const BankSelectionSchema = Yup.object().shape({
  issuer: Yup.string().required("required"),
});
