import { all } from "redux-saga/effects";

import { watchLogin, watchResetPassword, watchSetPassword } from "./auth";
import {
  watchGetPaymentTypes,
  watchPayViaAccountNumber,
  watchPayViaBankPayment,
  watchCheckIbanValidation,

  // TRANSIT VIGNETTES
  watchGetMooringPaymentTypes,
  watchGetNewPaymentTypes,
  watchmakeNewPayment,
  watchMakeMooringPayment,
} from "./payments";
import { watchGetOwner, watchUpdateOwner } from "./profile";
import {
  watchCancelVignet,
  watchDownloadSummary,
  watchGetDriveTypes,
  watchGetVignet,
  watchGetVignets,
  watchUpdateVignet,
  watchUploadDocument,

  // TRANSIT VIGNETTES
  watchGetVignetTypes,
  watchGetDeliveryTypes,
  watchCheckMooringVignette,

  // ACTIVATE VIGNETTES
  watchActivateCheckVignette,
  watchActivateGetDriveTypes,
  watchActivateUpdateOwner,
  watchActivateUpdateVignet,
  watchActivateValidateVignet,
  watchActivateValidateOwner,
  watchActivateUploadPhotos,
  watchValidatePassageOwner,
  watchValidatePassageVignet,
  watchActivateSaveData,
  watchActivateGetSummary,
  watchGetCountries,
  watchGetMooringSummary,
  watchGetNewVignetteSummary,
  watchValidateVoucherCode,
  watchactivateGetObjectTypes,
} from "./vignets";

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchResetPassword(),
    watchSetPassword(),
    watchGetOwner(),
    watchGetVignets(),
    watchUpdateOwner(),
    watchCancelVignet(),
    watchGetVignet(),
    watchGetDriveTypes(),
    watchUpdateVignet(),
    watchGetPaymentTypes(),
    watchPayViaAccountNumber(),
    watchPayViaBankPayment(),
    watchUploadDocument(),
    watchCheckIbanValidation(),
    watchDownloadSummary(),

    // TRANSIT VIGNETTES
    watchGetVignetTypes(),
    watchGetDeliveryTypes(),
    watchGetNewPaymentTypes(),
    watchmakeNewPayment(),
    watchCheckMooringVignette(),
    watchGetMooringPaymentTypes(),
    watchMakeMooringPayment(),
    watchValidatePassageOwner(),
    watchValidatePassageVignet(),

    // ACTIVATE VIGNETTES
    watchActivateCheckVignette(),
    watchActivateGetDriveTypes(),
    watchActivateUpdateOwner(),
    watchActivateUploadPhotos(),
    watchActivateUpdateVignet(),
    watchActivateValidateVignet(),
    watchActivateValidateOwner(),
    watchActivateSaveData(),
    watchActivateGetSummary(),
    watchGetCountries(),
    watchGetMooringSummary(),
    watchGetNewVignetteSummary(),
    watchValidateVoucherCode(),
    watchactivateGetObjectTypes(),
  ]);
}
