import { createTypes } from "reduxsauce";

export default createTypes(
  `
  GET_VIGNETS_REQUEST
  GET_VIGNETS_SUCCESS
  GET_VIGNETS_FAILURE

  CANCEL_VIGNET_REQUEST
  CANCEL_VIGNET_SUCCESS
  CANCEL_VIGNET_FAILURE

  GET_VIGNET_REQUEST
  GET_VIGNET_SUCCESS
  GET_VIGNET_FAILURE

  GET_DRIVE_TYPES_REQUEST
  GET_DRIVE_TYPES_SUCCESS
  GET_DRIVE_TYPES_FAILURE

  UPDATE_VIGNET_REQUEST
  UPDATE_VIGNET_SUCCESS
  UPDATE_VIGNET_FAILURE

  UPLOAD_DOCUMENTS_REQUEST
  UPLOAD_DOCUMENTS_SUCCESS
  UPLOAD_DOCUMENTS_FAILURE

  GET_SUMMARY_LINK_REQUEST
  GET_SUMMARY_LINK_SUCCESS
  GET_SUMMARY_LINK_FAILURE



  GET_VIGNETTE_TYPES
  GET_VIGNETTE_TYPES_SUCCESS
  GET_VIGNETTE_TYPES_FAILURE

  GET_DELIVERY_TYPES
  GET_DELIVERY_TYPES_SUCCESS
  GET_DELIVERY_TYPES_FAILURE

  CHECK_MOORING_VIGNETTE
  CHECK_MOORING_VIGNETTE_SUCCESS
  CHECK_MOORING_VIGNETTE_FAILURE


  ACTIVATE_CHECK_VIGNETTE
  ACTIVATE_CHECK_VIGNETTE_SUCCESS
  ACTIVATE_CHECK_VIGNETTE_FAILURE

  ACTIVATE_GET_DRIVE_TYPES
  ACTIVATE_GET_DRIVE_TYPES_SUCCESS
  ACTIVATE_GET_DRIVE_TYPES_FAILURE

  ACTIVATE_UPDATE_VIGNET
  ACTIVATE_UPDATE_VIGNET_SUCCESS
  ACTIVATE_UPDATE_VIGNET_FAILURE

  ACTIVATE_UPDATE_OWNER
  ACTIVATE_UPDATE_OWNER_SUCCESS
  ACTIVATE_UPDATE_OWNER_FAILURE

  ACTIVATE_VALIDATE_VIGNET
  ACTIVATE_VALIDATE_VIGNET_SUCCESS
  ACTIVATE_VALIDATE_VIGNET_FAILURE

  ACTIVATE_VALIDATE_OWNER
  ACTIVATE_VALIDATE_OWNER_SUCCESS
  ACTIVATE_VALIDATE_OWNER_FAILURE

  ACTIVATE_UPLOAD_DOCUMENTS
  ACTIVATE_UPLOAD_DOCUMENTS_SUCCESS
  ACTIVATE_UPLOAD_DOCUMENTS_FAILURE

  VALIDATE_PASSAGE_OWNER
  VALIDATE_PASSAGE_OWNER_SUCCESS
  VALIDATE_PASSAGE_OWNER_FAILURE

  VALIDATE_PASSAGE_VIGNET
  VALIDATE_PASSAGE_VIGNET_SUCCESS
  VALIDATE_PASSAGE_VIGNET_FAILURE

  ACTIVATE_SAVE_DATA
  ACTIVATE_SAVE_DATA_SUCCESS
  ACTIVATE_SAVE_DATA_FAILURE

  ACTIVATE_GET_SUMMARY
  ACTIVATE_GET_SUMMARY_SUCCESS
  ACTIVATE_GET_SUMMARY_FAILURE

  NEW_VIGNETTE_COUNTRIES
  NEW_VIGNETTE_COUNTRIES_SUCCESS
  NEW_VIGNETTE_COUNTRIES_FAILURE

  MOORING_SUMMARY
  MOORING_SUMMARY_SUCCESS
  MOORING_SUMMARY_FAILURE

  NEW_VIGNETTE_SUMMARY
  NEW_VIGNETTE_SUMMARY_SUCCESS
  NEW_VIGNETTE_SUMMARY_FAILURE

  VALIDATE_VOUCHER_CODE
  VALIDATE_VOUCHER_CODE_SUCCESS
  VALIDATE_VOUCHER_CODE_FAILURE

  ACTIVATE_GET_OBJECT_TYPES
  ACTIVATE_GET_OBJECT_TYPES_SUCCESS
  ACTIVATE_GET_OBJECT_TYPES_FAILURE
`,
  {}
);
