import React, { useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Field } from "formik";
import { Label, Radio, RadioGroup } from "@amsterdam/asc-ui";
// import styled from "styled-components";
import DOMPurify from "dompurify";
import * as Yup from "yup";

import SubmitButton from "../../../../../components/Button";
// import { formatAmountToNL } from "../../../../../utils/helpers";
import { NewVignetteLayout } from "../..";
import {
  BlackIntro,
  CustomErrorMessage,
} from "../../../../../components/Typography";
// import { CustomDivider } from "../../../../../components";
import TransitStorage from "../../../../../utils/storage";
import { BlackP1 } from "../../../../../components/Typography";

// const DropdownWrapper = styled.div`
//   margin-top: 20px;
//   margin-bottom: 40px;
// `;

// const VignetInfoCard = styled.div`
//   border: 2px solid #fec813;
//   padding: 15px 15px 0px 15px;
//   width: 60%;
// `;

// const VignetInfoDescription = styled(BlackP1)`
//   margin-top: -20px;
//   padding-bottom: 0px;
// `;

const VignetSchema = Yup.object().shape({
  vignetKind: Yup.string().required("required"),
  //vignetNumber: Yup.string().required("required"),
});

const ChooseVignette = ({
  isGettingVignetteTypes,
  error,
  vignetTypes,
  getVignetteTypes,
  isValidatingPassageVignet,
  validatePassageVignet,
  lang,
}) => {
  // const [predispositionValue, setPredispositionValue] = useState(
  //   TransitStorage.getNewVignetteMetaDetails()?.vignet_cost
  // );
  // const [vignetNumber, setVignetNumber] = useState(
  //   TransitStorage.getNewVignetteDetailsSchema()?.vignetNumber
  // );
  useEffect(() => {
    TransitStorage.clear();
    getVignetteTypes();
  }, [getVignetteTypes]);

  const memoizedHandleSumbit = useCallback(() => {
    return;
  }, []);

  return (
    <NewVignetteLayout
      step={0}
      title={<FormattedMessage id="newVignetteChooseTitle" />}
      subtitle={
        <FormattedMessage
          id="newVignetteChoseVignetteDescription"
          values={{ br: <br /> }}
        />
      }
      isLoading={isGettingVignetteTypes || isValidatingPassageVignet}
      error={error}
      hasForm
      validationSchema={VignetSchema}
      initialValues={{ ...TransitStorage.getNewVignetteDetailsSchema() }}
      onSubmit={(values) => {
        TransitStorage.persistNewVignetteDetailsSchema(values);
        const chosenVignette = vignetTypes.filter(
          (vignet) => vignet.id === values.vignetKind
        )[0];
        TransitStorage.persistNewVignetteMeta({
          vignet_cost: chosenVignette.cost,
          vignet_name: chosenVignette.name,
        });
        validatePassageVignet({
          vignet_type_id: values.vignetKind,
        });
      }}
    >
      <React.Fragment>
        <BlackIntro>
          <FormattedMessage id="newVignetteChooseVignetteSort" />
        </BlackIntro>

        <Field
          name={"vignetKind"}
          validate={(value) => {
            if (!value) return "you must pick";
          }}
        >
          {({ field, form }) => (
            <>
              <RadioGroup
                name={field.name}
                className="mt-inverse-10 mb-0"
                error={form.errors.vignetKind && form.touched.vignetKind}
              >
                {vignetTypes?.map((vignet) => (
                  <Label
                    htmlFor={vignet.id}
                    label={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(vignet.name),
                        }}
                      />
                    }
                    key={vignet.id}
                  >
                    <Radio
                      onChange={(e) => {
                        form.setFieldValue("vignetKind", vignet.id);
                      }}
                      id={vignet.id}
                      checked={field.value === vignet.id}
                      variant="tertiary"
                      value={vignet.id}
                    />
                  </Label>
                ))}
              </RadioGroup>
              {form.errors.vignetKind && form.touched.vignetKind && (
                <CustomErrorMessage id="makeChoiceAbove" />
              )}
            </>
          )}
        </Field>
        {/* <DropdownWrapper>
          <BlackIntro>
            <FormattedMessage id="newVignetteChooseVignetteDropDownLabel" />
          </BlackIntro>
          <Field name="vignetNumber">
            {({ field, form }) => (
              <div className="mt-inverse-20 amsterdam-input">
                <Select
                  id="vignetNumber"
                  onChange={(e) => {
                    if (Number(e.target.value)) {
                      form.setFieldValue(
                        "vignetNumber",
                        Number(e.target.value)
                      );
                      setVignetNumber(Number(e.target.value));
                    } else {
                      form.setFieldValue("vignetNumber", "");
                      setVignetNumber(null);
                    }
                  }}
                  defaultValue={
                    TransitStorage.getNewVignetteDetailsSchema()?.vignetNumber
                  }
                  error={form.errors.vignetNumber}
                >
                  <option>
                    {lang === "nl" ? "Selecteer aantal" : "Select number"}
                  </option>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
                    <option key={data} value={data}>
                      {data}
                    </option>
                  ))}
                </Select>
                {form.errors.vignetNumber && form.touched.vignetNumber && (
                  <div className="mt-10">
                    <CustomErrorMessage id="selectChoice" />
                  </div>
                )}
              </div>
            )}
          </Field>
          <BlackP1>
            <FormattedMessage id="newVignetteChooseVignetteDropDownDescription" />
          </BlackP1>
        </DropdownWrapper>
        <CustomDivider style={{ backgroundColor: "#767676" }} />
        <BlackIntro>
          <FormattedMessage id="totalAmount" />: &#8364;&nbsp;
          {vignetNumber
            ? formatAmountToNL(predispositionValue * vignetNumber)
            : 0}
        </BlackIntro>
        {vignetNumber > 1 && (
          <VignetInfoCard>
            <BlackIntro>
              <FormattedMessage id="newVignetteChoseVignetteInfoLabel" />
            </BlackIntro>
            <VignetInfoDescription>
              <FormattedMessage id="newVignetteChoseVignetteInfoDescription1" />
              <br />
              <br />
              <FormattedMessage id="newVignetteChoseVignetteInfoDescription2" />
            </VignetInfoDescription>
          </VignetInfoCard>
        )} */}

        <BlackP1 style={{ marginTop: "8px" }}>
          <FormattedMessage
            id="vignettePrivacyStatement"
            values={{
              link: (chunks) => (
                <a
                  href="https://www.amsterdam.nl/privacy/specifieke/privacyverklaring-parkeren-verkeer-bouw/verkeersmanagement-gracht-privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </BlackP1>

        <SubmitButton onNext={memoizedHandleSumbit} showPrevious={false} />
      </React.Fragment>
    </NewVignetteLayout>
  );
};

export default ChooseVignette;
