import { apiRequest } from "../apiRequest";
import { GET_OWNER_DETAILS, UPDATE_OWNER } from "../urls";

export const getOwnerDetails = () => {
  return apiRequest("GET", GET_OWNER_DETAILS, null);
};

export const updateOwnerDetails = (data) => {
  return apiRequest("POST", UPDATE_OWNER, data);
};
