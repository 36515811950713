import React, { useState } from "react";
import PropTypes from "prop-types";
import { ChevronDown, Close, Document, Download } from "@amsterdam/asc-assets";
import { Link, Button } from "@amsterdam/asc-ui";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Files from "react-files";

import { UploadFileContext } from ".";
import { CustomDivider } from "../../../../../components";
import {
  BlackIntro,
  BlackP1,
  CustomErrorMessage,
} from "../../../../../components/Typography";

const UploadWrapper = styled.div`
  background-color: #f5f5f5;
  padding-top: 40px;
  text-align: center;
  height: 100px;
`;

const DescriptionWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const CustomBlackIntro = styled(BlackP1)`
  margin-top: -20px;
`;

const removedFilesId = [];
const LigbaRemoveFilesId = [];

const removeFile = (fileType, fileId, callback) => {
  if (fileType === "Ligplaatsbewijs") {
    LigbaRemoveFilesId.push(fileId);
  } else {
    removedFilesId.push(fileId);
  }
  callback({ type: `${fileType}.delete`, id: fileId });
};

const RenderUploadedFiles = ({
  filesUploaded,
  callback,
  fileType,
  deleteFile,
}) => {
  return (
    <React.Fragment key={filesUploaded.id}>
      <BlackP1>
        <Document height={14} /> {filesUploaded?.file?.name}
        <Button
          variant="textButton"
          onClick={(e) => {
            e.preventDefault();
            deleteFile(filesUploaded.id);
            removeFile(fileType, filesUploaded.id, callback);
          }}
          className="right-float"
        >
          <Close height={14} /> &nbsp;&nbsp;
          <FormattedMessage id="toClear" />
        </Button>
      </BlackP1>
      <CustomDivider className="mt-inverse-20" />
    </React.Fragment>
  );
};

RenderUploadedFiles.propTypes = {
  filesUploaded: PropTypes.shape({}).isRequired,
  callback: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
};

const DocumentUpload = ({
  showRequiredDocumentError,
  setShowRequiredDocumentError,
  documentRef,
  documents,
  persistImage,
  hydrateImage,
  deletePersistedImage,
}) => {
  let { state, dispatch } = React.useContext(UploadFileContext);
  const [persistedImageSet, setPersistedImage] = useState(false);
  const [showImageSizeError1, setShowImageSizeError1] = useState(false);
  const [showImageSizeError2, setShowImageSizeError2] = useState(false);
  if (!persistedImageSet) {
    hydrateImage().then((data) => {
      data.forEach((item) => {
        dispatch({
          type: item.id,
          payload: item,
        });
      });
    });
    setPersistedImage(true);
  }

  return (
    <DescriptionWrapper>
      {documents?.map((data) => (
        <React.Fragment key={data}>
          <div className="mt-20 mb-40">
            <BlackIntro>
              <FormattedMessage
                id={
                  data === "Ligplaatsbewijs"
                    ? "NononTheFingHeader2"
                    : documents.length === 1
                    ? "activateVignetteDetailsBoatPhotoTitle"
                    : "activateVignetteDetailsBoatPhotoTitle2"
                }
              />
            </BlackIntro>
            {/* <CustomBlackIntro>
              <FormattedMessage
                id={
                  data === "Ligplaatsbewijs"
                    ? "NononTheFingDescription"
                    : "activateVignetteDetailsBoatPhotoDescription"
                }
                values={{ br: <br /> }}
              />
            </CustomBlackIntro> */}
          </div>
          <UploadWrapper>
            <Files
              multiple={false}
              ref={documentRef}
              className="files-dropzone-list dropzone-file-bhg"
              onChange={(files) => {
                if (files.length > 0) {
                  data === "Ontheffing"
                    ? setShowImageSizeError1(false)
                    : setShowImageSizeError2(false);
                  deletePersistedImage(data).then((e) => {
                    persistImage({ file: files[0], id: data }, data).then(
                      (event) => {},
                      (error) => {}
                    );
                    setShowRequiredDocumentError(false);
                    dispatch({
                      type: data,
                      payload: { file: files[0], id: data },
                    });
                  });
                } else {
                  data === "Ontheffing"
                    ? setShowImageSizeError1(true)
                    : setShowImageSizeError2(true);
                }
              }}
              onError={(err) => console.log("error", err)}
              accepts={[
                ".png",
                ".jpg",
                ".jpeg",
                "application/pdf",
                ".gif",
                ".doc",
                ".docx",
              ]}
              maxFileSize={8200000}
              minFileSize={0}
              clickable={!state[data]}
            >
              <BlackP1>
                <FormattedMessage id="activateVignetteDetailsDocumentUploadText" />
                &nbsp;
                <Link variant="inline">
                  <FormattedMessage id="activateVignetteDetailsDocumentSelectFiles" />
                </Link>
                &nbsp;&nbsp;
                <Download height={10} size={10} className="bhg-download-icon" />
              </BlackP1>
            </Files>
          </UploadWrapper>
          <BlackP1>
            <Link variant="inline">
              <FormattedMessage id="documentUpload" />
              &nbsp;
              <ChevronDown height={14} size={14} className="mt-5" />
            </Link>
          </BlackP1>

          {data === "Ontheffing" && state.Ontheffing && (
            <RenderUploadedFiles
              filesUploaded={state.Ontheffing}
              fileType={data}
              callback={dispatch}
              deleteFile={deletePersistedImage}
            />
          )}
          {data === "Ligplaatsbewijs" && state.Ligplaatsbewijs && (
            <RenderUploadedFiles
              filesUploaded={state.Ligplaatsbewijs}
              fileType={data}
              callback={dispatch}
              deleteFile={deletePersistedImage}
            />
          )}
          {data === "Ontheffing" && showImageSizeError1 && (
            <CustomErrorMessage id="documentSizeError" />
          )}

          {data === "Ligplaatsbewijs" && showImageSizeError2 && (
            <CustomErrorMessage id="documentSizeError" />
          )}
        </React.Fragment>
      ))}
      {showRequiredDocumentError && (
        <CustomErrorMessage id="requiredDocument" />
      )}
    </DescriptionWrapper>
  );
};

DocumentUpload.propTypes = {
  showRequiredDocumentError: PropTypes.bool.isRequired,
  setShowRequiredDocumentError: PropTypes.func.isRequired,
};

export default DocumentUpload;
