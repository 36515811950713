import React from "react";
import { Checkmark } from "@amsterdam/asc-assets";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";

import ProtectedLayout from "../..";
import { CustomRow, CustomDivider, CustomColumn } from "../../../../components";
import SubmitButton from "../../../../components/Button";
import {
  getNextAvailableVignet,
  history,
  persistSteppersDecision,
  persistVignetDecision,
} from "../../../../utils/helpers";
import DecisionForm from "./DecisionForm";
import { BlackIntro, BlackP1 } from "../../../../components/Typography";

class VignetDecision extends React.Component {
  state = {
    initialValues: {},
  };
  componentDidMount() {
    this.props.getVignets();
    localStorage.removeItem("steppers_decisions");
  }

  canMoveToNext = () => {
    const { vignets } = this.props;
    return (
      vignets.vignets &&
      vignets.vignets.length &&
      vignets.vignets.some((vignet) => vignet.vignet_status === "open")
    );
  };

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(this.state.initialValues)) {
      this.getFormInitialValues(nextProps.vignets);
    }
  }

  getFormInitialValues = (data) => {
    const initialValues = {};
    data.vignets &&
      data.vignets.length &&
      // eslint-disable-next-line
      data.vignets.map((vignet) => {
        initialValues[vignet.vignet_id] = "";
      });
    this.setState({ initialValues });
  };

  render() {
    const { error, isloading, vignets } = this.props;
    return (
      <ProtectedLayout
        step={2}
        headerTitle={<FormattedMessage id="personalInfoHeader" />}
        title={<FormattedMessage id="vignetOverview" />}
        subtitle={<FormattedMessage id="vignetOverviewDescription" />}
        hasForm
        initialValues={this.state.initialValues}
        onSubmit={(values) => {
          persistVignetDecision(values);
          persistSteppersDecision(values);
          const nextVignet = getNextAvailableVignet();
          if (nextVignet) {
            history.push(
              `/push-decision?vignet_id=${nextVignet.vignet_id}&decision=${nextVignet.decision}`
            );
          } else {
            window.location = "/done";
          }
        }}
        isLoading={isloading}
        error={error}
      >
        <React.Fragment>
          <CustomRow>
            <CustomColumn size={3}>
              <BlackIntro>
                <FormattedMessage id="boatName" />
              </BlackIntro>
            </CustomColumn>
            <CustomColumn size={2}>
              <BlackIntro>
                <FormattedMessage id="vignetNumber" />
              </BlackIntro>
            </CustomColumn>
            <CustomColumn size={4}>
              <BlackIntro>
                <FormattedMessage id="renewCancel" />
              </BlackIntro>
            </CustomColumn>
            <CustomDivider style={{ marginTop: "-4px" }} />
          </CustomRow>
          {vignets.vignets &&
            vignets.vignets.length &&
            vignets.vignets.map((vignet) => (
              <CustomRow key={vignet.vignet_id}>
                <CustomColumn size={3}>
                  <BlackP1 style={{ paddingTop: "15px" }}>
                    {vignet.object_name}
                  </BlackP1>
                </CustomColumn>
                <CustomColumn size={2}>
                  <BlackP1 style={{ paddingTop: "15px" }}>
                    {vignet.vignet_number}
                  </BlackP1>
                </CustomColumn>
                <CustomColumn size={4}>
                  {vignet.vignet_status !== "open" ? (
                    <>
                      <BlackP1 style={{ paddingTop: "15px" }}>
                        <Checkmark
                          height={16}
                          size={20}
                          style={{ paddingRight: "10px", paddingTop: "4px" }}
                        />
                        Vignet is{" "}
                        <FormattedMessage
                          id={
                            vignet.vignet_status === "cancelled"
                              ? "smallCancel"
                              : "smallRenew"
                          }
                        />
                      </BlackP1>
                    </>
                  ) : (
                    <DecisionForm vignet={vignet} />
                  )}
                </CustomColumn>
                <CustomColumn size={12}>
                  <CustomDivider style={{ margin: "10px" }} />
                </CustomColumn>
              </CustomRow>
            ))}
          <SubmitButton
            onNext={() => {
              return;
            }}
            disabled={!this.canMoveToNext()}
          />
        </React.Fragment>
      </ProtectedLayout>
    );
  }
}

VignetDecision.propTypes = {
  vignets: PropTypes.shape({}).isRequired,
  getVignets: PropTypes.func.isRequired,
  error: PropTypes.string,
  isloading: PropTypes.bool.isRequired,
};

VignetDecision.defaultProps = {
  error: null,
};

export default VignetDecision;
