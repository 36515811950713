import React, { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Field } from "formik";
import { RadioGroup, Label, Radio } from "@amsterdam/asc-ui";

import {
  BlackIntro,
  BlackP1,
  CustomErrorMessage,
} from "../../../../components/Typography";
import SubmitButton from "../../../../components/Button";
import CardDropDown, { isPaymentCardType } from "./card-dropdown";
import VoucherCodeInput, { isPaymentVoucher } from "./voucher-input";

const PaymentOptions = ({
  showDescription,
  paymentTypes,
  showCardError,
  setShowCardError,
  showVoucherError,
  voucherApiError,
}) => {
  const [paymentType, setPaymentType] = useState(
    localStorage.getItem("paymentChoice")
  );

  const deleteSubOptionsFromFormValues = (form, paymentName) => {
    // delete card_service_code and/or voucher_code from values if the user no longer selected them after first selection
    if (form.values.card_service_code && !isPaymentCardType(paymentName)) {
      form.setFieldValue("card_service_code", undefined);
      localStorage.removeItem("card_service_code");
    }

    if (form.values.voucher_code && !isPaymentVoucher(paymentName)) {
      form.setFieldValue("voucher_code", undefined);
    }
  };

  const memoizedHandleSumbit = useCallback(() => {
    return;
  }, []);

  return (
    <React.Fragment>
      <BlackIntro>
        <FormattedMessage id="dayVignettePaymentChoiceTitle" />
      </BlackIntro>
      {showDescription && (
        <BlackP1 style={{ marginTop: "-24px" }}>
          <FormattedMessage
            id="dayVignettePaymentChoiceDescription"
            values={{ br: <br /> }}
          />
        </BlackP1>
      )}

      <BlackIntro>
        <FormattedMessage id="dayStep4" />
      </BlackIntro>
      <Field
        name={"payment_type_id"}
        validate={(value) => {
          if (!value) return "you must pick";
        }}
      >
        {({ field, form }) => (
          <>
            <RadioGroup
              name={field.name}
              className="mt-inverse-20 mb-0"
              error={
                form.errors.payment_type_id && form.touched.payment_type_id
              }
            >
              {paymentTypes?.map((payment) => (
                <React.Fragment key={payment.id}>
                  <Label htmlFor={payment.id} label={payment.name}>
                    <Radio
                      onChange={(e) => {
                        form.setFieldValue("payment_type_id", payment.id);
                        setPaymentType(payment.name);
                        localStorage.setItem("paymentChoice", payment.name);
                        deleteSubOptionsFromFormValues(form, payment.name);
                      }}
                      id={payment.id}
                      checked={field.value === payment.id}
                      variant="tertiary"
                      value={payment.id}
                    />
                  </Label>
                  <CardDropDown
                    paymentTypes={paymentTypes}
                    selectedPaymentType={paymentType}
                    paymentObject={payment}
                    showCardError={showCardError}
                    setShowCardError={setShowCardError}
                  />
                  <VoucherCodeInput
                    paymentTypes={paymentTypes}
                    selectedPaymentType={paymentType}
                    paymentObject={payment}
                    showVoucherError={showVoucherError}
                    voucherApiError={voucherApiError}
                  />
                </React.Fragment>
              ))}
            </RadioGroup>
            {form.errors.payment_type_id && form.touched.payment_type_id && (
              <CustomErrorMessage
                id="makeChoiceAbove"
                style={{ marginTop: "0px" }}
              />
            )}
          </>
        )}
      </Field>
      <SubmitButton onNext={memoizedHandleSumbit} />
    </React.Fragment>
  );
};

PaymentOptions.defaultProps = {
  showDescription: true,
};

export default PaymentOptions;
