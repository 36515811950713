import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  RadioGroup,
  Label,
  Radio,
  Checkbox,
  ErrorMessage,
} from "@amsterdam/asc-ui";
import { Close } from "@amsterdam/asc-assets";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";

import { Creators } from "../../../../services/redux/payments/actions";
import { Field } from "formik";
import CustomInput from "../../../../components/Input";
import CustomLink from "../../../../components/Link/link";
import { CustomColumn } from "../../../../components";
import { history, parseQuery } from "../../../../utils/helpers";
import {
  BlackH3,
  BlackP1,
  BlackIntro,
  CustomErrorMessage,
} from "../../../../components/Typography";
import { InfoWrapper } from "../../CancelVignet/components";

const PaymentWrapper = styled.div`
  border: 2px solid #ec0000;
  background-color: rgba(255, 255, 255, 0);
  padding: 10px 10px 0px 10px;
  margin-top: 30px;
`;

export const FailedPaymentMessage = () => {
  return (
    <CustomColumn size={6}>
      {history.location.search &&
        parseQuery(history.location.search).failed === "true" && (
          <PaymentWrapper>
            <Close
              height={22}
              size={20}
              style={{ display: "inline", color: "red", paddingTop: "10px" }}
            />
            <BlackIntro style={{ display: "inline", paddingLeft: "10px" }}>
              <FormattedMessage id="bankPaymentErrorTitle" />
            </BlackIntro>
            <BlackP1 style={{ paddingLeft: "24px" }}>
              <FormattedMessage id="bankPaymentErrorDescription" />
            </BlackP1>
          </PaymentWrapper>
        )}
    </CustomColumn>
  );
};

const Payments = ({
  paymentChoice,
  selectPaymentChoice,
  resetFailedPaymentType,
  isSummary,
  resetChoice,
  previousChoice,
  showInstallmentForm,
  ibanValidationError,
  refreshIbanValidation,
  readOnlyMode,
  setShowFailedPayment,
}) => {
  const [paymentType, setPaymentType] = useState();
  useEffect(() => {
    if (resetFailedPaymentType) setPaymentType(resetFailedPaymentType);
    if (previousChoice && !paymentType) setPaymentType(previousChoice);

    // eslint-disable-next-line
  }, []);

  const accountNumberForm = () => {
    return (
      <div style={{ width: "160%" }}>
        <div style={{ maxWidth: "300px" }}>
          <Field
            name="account_number"
            component={CustomInput}
            label={<FormattedMessage id="accountNumber" />}
          />
          <Field
            name="account_holder"
            component={CustomInput}
            label={<FormattedMessage id="accountHolder" />}
          />
        </div>
        {ibanValidationError && (
          <div style={{ maxWidth: "400px" }}>
            <ErrorMessage message={ibanValidationError} />
          </div>
        )}
      </div>
    );
  };

  // this was for bank selection - I left it in case we need to bring it back
  // const bankForm = (options) => {
  //   return (
  //     <div style={{ marginTop: "20px" }}>
  //       <Heading as="h4">
  //         <FormattedMessage id="chooseBank" />
  //       </Heading>
  //       <Field name="issuer" required>
  //         {({ field, form }) => (
  //           <Select
  //             style={{ width: "300px" }}
  //             name="issuer"
  //             onChange={(e) => form.setFieldValue("issuer", e.target.value)}
  //             required="required"
  //           >
  //             <option value={""}>
  //               {lang === "nl" ? "Selecteer uw bank" : "Select your bank"}{" "}
  //             </option>
  //             {options.map((data) => (
  //               <option key={data.id} value={data.DebtorBankId}>
  //                 {data.DebtorBankName}
  //               </option>
  //             ))}
  //           </Select>
  //         )}
  //       </Field>
  //     </div>
  //   );
  // };
  return (
    <React.Fragment>
      <CustomColumn size={12}>
        <BlackH3 forwardedAs="h4" style={{ marginTop: "20px" }}>
          <FormattedMessage id="paymentMethod" />
        </BlackH3>
      </CustomColumn>
      <div style={{ width: "50%" }}>
        <BlackP1>
          <FormattedMessage id="paymentInstruction" />
        </BlackP1>
      </div>
      <CustomColumn size={12}>
        <BlackIntro style={{ marginBottom: "0px" }}>
          <FormattedMessage id="pay" />
        </BlackIntro>
      </CustomColumn>
      <>
        <CustomColumn size={15}>
          {isSummary ? (
            <BlackP1>{paymentType}</BlackP1>
          ) : (
            <RadioGroup name="paymentChoice">
              {paymentChoice.map((data) => (
                <React.Fragment key={data.name}>
                  {paymentType ? (
                    paymentType === data.name && (
                      <>
                        <>
                          <Label htmlFor={data.name} label={data.name}>
                            <Radio
                              id={data.name}
                              variant="tertiary"
                              value={data.name}
                              checked={paymentType === data.name}
                              onChange={(e) => {
                                setPaymentType(data.name);
                                selectPaymentChoice(data.name);
                              }}
                            />
                          </Label>
                          {/* {hasDocuments
                            ? null
                            : paymentType === "iDEAL" &&
                              bankForm(data.issuer_list)} */}
                          {[
                            "In 4 installments",
                            "In 4 termijnen",
                            "In 4 delen",
                          ].includes(paymentType) &&
                            showInstallmentForm &&
                            accountNumberForm()}
                        </>
                      </>
                    )
                  ) : (
                    <Label htmlFor={data.name} label={data.name}>
                      <Radio
                        id={data.name}
                        variant="tertiary"
                        value={data.name}
                        checked={paymentType === data.name}
                        onChange={(e) => {
                          setPaymentType(data.name);
                          selectPaymentChoice(data.name);
                        }}
                      />
                    </Label>
                  )}
                </React.Fragment>
              ))}
            </RadioGroup>
          )}
          {paymentType && !readOnlyMode && (
            <CustomLink
              variant="inline"
              onClick={(e) => {
                e.preventDefault();
                setPaymentType(null);
                resetChoice();
                refreshIbanValidation();
                setShowFailedPayment(false);
              }}
              style={{ paddingLeft: "50px" }}
            >
              <FormattedMessage id="change" />
            </CustomLink>
          )}
        </CustomColumn>
      </>
      {["In 4 installments", "In 4 termijnen", "In 4 delen"].includes(
        paymentType
      ) &&
        !isSummary &&
        showInstallmentForm && (
          <InfoWrapper style={{ width: "50%" }}>
            <Field
              name="account_agreement"
              validate={(value) => {
                if (!value) return "you must agree";
              }}
            >
              {({ field, form }) => (
                <React.Fragment>
                  <Label
                    htmlFor="account_agreement"
                    label={<FormattedMessage id="accountNumberAgreement" />}
                  >
                    <Checkbox
                      id="account_agreement"
                      name="account_agreement"
                      onChange={(e) =>
                        form.setFieldValue(
                          "account_agreement",
                          form.values.account_agreement !== "yes" ? "yes" : ""
                        )
                      }
                      checked={field.value === "yes"}
                      value={"yes"}
                    />
                  </Label>
                  {form.errors.account_agreement &&
                    form.touched.account_agreement && (
                      <CustomErrorMessage id="selectChoice" />
                    )}
                </React.Fragment>
              )}
            </Field>
          </InfoWrapper>
        )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentChoice: state.payments.paymentTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentTypes: () => {
      dispatch(Creators.getPaymentTypesRequest());
    },
  };
};

Payments.propTypes = {
  paymentChoice: PropTypes.shape({}).isRequired,
  selectPaymentChoice: PropTypes.func.isRequired,
  resetFailedPaymentType: PropTypes.string,
  isSummary: PropTypes.bool.isRequired,
  resetChoice: PropTypes.func.isRequired,
  previousChoice: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
