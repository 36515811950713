import React from "react";
import { Checkbox, Label } from "@amsterdam/asc-ui";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Close } from "@amsterdam/asc-assets";
import DOMPurify from "dompurify";

import { CustomColumn, CustomDivider, CustomRow } from "../../../components";
import { history } from "../../../utils/helpers";
import CustomLink from "../../../components/Link/link";
import {
  BlackIntro,
  BlackP1,
  CustomErrorMessage,
} from "../../../components/Typography";

const AmountWrapper = styled.div`
  margin-top: 40px;
`;

const CheckboxWrapper = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  margin: 20px 20px 20px 0px;
`;

const PaymentWrapper = styled.div`
  border: 2px solid #ec0000;
  background-color: rgba(255, 255, 255, 0);
  padding: 10px 10px 0px 10px;
  margin-top: 30px;
`;

const SummaryBlackP1 = styled(BlackP1)`
  margin-bottom: 0px;
`;

const SummaryDivider = styled(CustomDivider)`
  margin-top: 0px;
  margin-bottom: 5px;
`;

const SummaryClose = styled(Close)`
  display: inline;
  color: red;
  padding-top: 10px;
`;

const PaymentErrorTitle = styled(BlackIntro)`
  display: inline;
  padding-left: 10px;
`;

const PaymentErrorDescription = styled(BlackP1)`
  padding-left: 24px;
`;

const SummaryDetails = ({ data, totalStruct, failedPayment }) => {
  return (
    <>
      {data.map((item) => (
        <>
          <CustomRow key={item.title}>
            <BlackIntro>
              <FormattedMessage id={item.title} />
            </BlackIntro>
            {!item.disableChange && (
              <CustomLink
                variant="inline"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(item.route);
                }}
              >
                <FormattedMessage id="change" />
              </CustomLink>
            )}
          </CustomRow>
          {item.info.map((info) => (
            <CustomRow key={info.label}>
              <CustomColumn size={3}>
                <SummaryBlackP1>
                  <FormattedMessage id={info.label} /> {info.labelSuffix}
                </SummaryBlackP1>
              </CustomColumn>
              <CustomColumn size={6}>
                <BlackIntro>
                  {info.value ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(info.value),
                      }}
                    />
                  ) : (
                    <br />
                  )}
                </BlackIntro>
              </CustomColumn>
            </CustomRow>
          ))}
        </>
      ))}
      <AmountWrapper>
        {totalStruct?.map((info) => (
          <>
            {info.items.map((item) => (
              <CustomRow key={item.label}>
                <CustomColumn size={3}>
                  <SummaryBlackP1>
                    <FormattedMessage id={item.label} />
                  </SummaryBlackP1>
                </CustomColumn>
                <CustomColumn size={6}>
                  <BlackIntro>{item.value}</BlackIntro>
                </CustomColumn>
              </CustomRow>
            ))}
            <SummaryDivider />
            <CustomRow>
              <CustomColumn size={3}>
                <BlackIntro style={{ marginBottom: "0px" }}>
                  <FormattedMessage id={info.label} />
                </BlackIntro>
              </CustomColumn>
              <CustomColumn size={6}>
                <BlackIntro style={{ marginTop: "0px" }}>
                  {info.value}
                </BlackIntro>
              </CustomColumn>
            </CustomRow>
          </>
        ))}
      </AmountWrapper>

      <CustomRow>
        <CustomColumn size={6}>
          <CheckboxWrapper>
            <Field
              name="agree_1"
              validate={(value) => {
                if (!value) return "you must agree";
              }}
            >
              {({ field, form }) => (
                <React.Fragment>
                  <Label
                    htmlFor="agree_1"
                    label={<FormattedMessage id="checkbox1" />}
                  >
                    <Checkbox
                      id="agree_1"
                      name="agree_1"
                      onChange={(e) =>
                        form.setFieldValue(
                          "agree_1",
                          form.values.agree_1 !== "yes" ? "yes" : ""
                        )
                      }
                      checked={field.value === "yes"}
                      value={"yes"}
                    />
                  </Label>
                  {form.errors.agree_1 && form.touched.agree_1 && (
                    <CustomErrorMessage id="transitAgreeMessage" />
                  )}
                </React.Fragment>
              )}
            </Field>
          </CheckboxWrapper>
        </CustomColumn>
      </CustomRow>
      <CustomRow>
        <CustomColumn size={6}>
          <CheckboxWrapper>
            <Field
              name="agree_2"
              validate={(value) => {
                if (!value) return "you must agree";
              }}
            >
              {({ field, form }) => (
                <React.Fragment>
                  <Label
                    htmlFor="agree_2"
                    label={
                      <FormattedMessage
                        id="summaryCheckbox2"
                        values={{
                          link: (chunks) => (
                            <a
                              href="https://www.amsterdam.nl/varen"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {chunks}
                            </a>
                          ),
                        }}
                      />
                    }
                  >
                    <Checkbox
                      id="agree_2"
                      name="agree_2"
                      onChange={(e) =>
                        form.setFieldValue(
                          "agree_2",
                          form.values.agree_2 !== "yes" ? "yes" : ""
                        )
                      }
                      checked={field.value === "yes"}
                      value={"yes"}
                    />
                  </Label>
                  {form.errors.agree_2 && form.touched.agree_2 && (
                    <CustomErrorMessage id="transitAgreeMessage" />
                  )}
                </React.Fragment>
              )}
            </Field>
          </CheckboxWrapper>
        </CustomColumn>
      </CustomRow>
      {failedPayment && (
        <CustomColumn size={6}>
          <PaymentWrapper>
            <SummaryClose height={22} size={20} />
            <PaymentErrorTitle>
              <FormattedMessage id="transitPaymentFailedTitle" />
            </PaymentErrorTitle>
            <PaymentErrorDescription>
              <FormattedMessage id="transitPaymentFailedDescription" />
            </PaymentErrorDescription>
          </PaymentWrapper>
        </CustomColumn>
      )}
    </>
  );
};

export default SummaryDetails;
