import React, { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import {
  StepByStepItem,
  StepByStepNavigation,
} from "../../../components/Steppers";
import { history } from "../../../utils/helpers";

const StepperWrapper = styled.div`
  @media only screen and (max-width: 468px) {
    display: none;
  }
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

const CustomStepByStepNavigation = styled(StepByStepNavigation)`
  margin: 40px 0px;
  maxwidth: 200px;
  height: 80px;
`;

const Steppers = ({ step, steps, disabled }) => {
  return (
    <StepperWrapper>
      <CustomStepByStepNavigation>
        {steps.map((data, index) => (
          <StepByStepItem
            heading={<FormattedMessage id={data.label} />}
            onClick={(e) => {
              e.preventDefault();
              if (step >= index && !disabled) history.push(data.route);
            }}
            checked={step >= index}
            active={step === index}
            href="#"
            key={data.label}
            disabled={index > step || disabled}
          />
        ))}
      </CustomStepByStepNavigation>
    </StepperWrapper>
  );
};

export default memo(Steppers);
