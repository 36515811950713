import React from "react";
import { Link } from "@amsterdam/asc-ui";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import AuthWrapper from "../../components/Wrapper";
import { history } from "../../../../utils/helpers";

const Wrapper = styled.div`
  width: 70%;
  margin-bottom: 10px;

  @media only screen and (max-width: 468px) {
    width: 100%;
  }
`;

const ResetPasswordFeedback = (props) => {
  return (
    <AuthWrapper
      title={<FormattedMessage id="forgot-password" />}
      info={<FormattedMessage id="resetFeedback" />}
    >
      <Wrapper>
        <Link
          href="#"
          variant="inline"
          style={{ fontFamily: "Avenir Next LT Pro Regular" }}
          onClick={(e) => {
            e.preventDefault();
            history.push("/");
          }}
        >
          <FormattedMessage id="backToLogin" />
        </Link>
      </Wrapper>
    </AuthWrapper>
  );
};

export default ResetPasswordFeedback;
