import { connect } from "react-redux";
import PersonalInfo from "./components";

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
