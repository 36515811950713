import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { formatAmountToNL } from "../../../../utils/helpers";
import { CustomColumn } from "../../../../components";
import {
  BlackH3,
  BlackIntro,
  BlackP1,
} from "../../../../components/Typography";

const VignetInfoCard = styled.div`
  border: 2px solid #fec813;
  padding: 15px 15px 0px 15px;
`;

const getTranslationDetails = (vignet_type) => {
  if (["Environment", "Milieu"].includes(vignet_type)) {
    return {
      title: "milieuTitle",
      description: "milieuDescription",
    };
  }

  if (["Subscription", "Abonnement"].includes(vignet_type)) {
    return {
      title: "abonmentTitle",
      description: "abonmentDescription",
    };
  }

  if (["Regular", "Regulier"].includes(vignet_type)) {
    return {
      title: "regulierTitle",
      description: "regulierDescription",
    };
  }

  return {
    title: "hybridTitle",
    description: "hybridDescription",
  };
};

const VignetCost = ({
  vignet: { vignet_type, vignet_cost, object_surface_area },
}) => {
  return (
    <React.Fragment>
      <CustomColumn size={12}>
        <BlackH3
          forwardedAs="h4"
          style={{ marginBottom: "20px", marginTop: "10px" }}
        >
          <FormattedMessage id="vignetCalculation" />
        </BlackH3>
      </CustomColumn>
      <CustomColumn size={12}>
        <BlackIntro>
          <FormattedMessage id="vignetType" />
        </BlackIntro>
      </CustomColumn>
      <CustomColumn size={12}>
        <BlackP1 style={{ paddingBottom: "15px", marginTop: "-10px" }}>
          {vignet_type}
        </BlackP1>
      </CustomColumn>
      <CustomColumn size={12}>
        <BlackIntro>
          <FormattedMessage id="totalArea" />
        </BlackIntro>
      </CustomColumn>
      <CustomColumn size={12}>
        <BlackP1 style={{ paddingBottom: "15px", marginTop: "-10px" }}>
          {formatAmountToNL(object_surface_area)}
          &nbsp;
          <FormattedMessage id="squareMeters" />
        </BlackP1>
      </CustomColumn>
      <CustomColumn size={12}>
        <BlackIntro>
          <FormattedMessage id="totalCost" />
        </BlackIntro>
      </CustomColumn>
      <BlackP1 style={{ paddingBottom: "15px", marginTop: "-10px" }}>
        &#8364;&nbsp;{formatAmountToNL(vignet_cost)}
      </BlackP1>
      {/* <VignetInfoCard>
        <BlackIntro>
          <FormattedMessage id={getTranslationDetails(vignet_type).title} />
        </BlackIntro>
        <BlackP1 style={{ marginTop: "-20px", paddingBottom: "0px" }}>
          <FormattedMessage
            id={getTranslationDetails(vignet_type).description}
          />
        </BlackP1>
      </VignetInfoCard> */}
    </React.Fragment>
  );
};

VignetCost.propTypes = {
  vignet: PropTypes.shape({}).isRequired,
};

export default VignetCost;
