import React, { useEffect, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field } from "formik";
import { Label, RadioGroup, Radio } from "@amsterdam/asc-ui";
import styled, { css } from "styled-components";
import Map from "./Map";

import { history } from "../../../../../utils/helpers";
import { NewVignetteLayout } from "../..";
import {
  BlackIntro,
  BlackP1,
  CustomErrorMessage,
} from "../../../../../components/Typography";
import SubmitButton from "../../../../../components/Button";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../../../constants";
import TransitStorage from "../../../../../utils/storage";
import DeliveryCheckPoints from "./delivery-checkpoints";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import { PICKUP_LOCATIONS } from "../../../../../services/api/transit-urls";

import { Helmet } from "react-helmet";

const FormWrapper = styled.div`
  margin: 20px 0px 40px 0px;
`;

const CustomLabel = styled(Label)`
  font-family: Avenir Next LT Pro Demi;
`;

const DeliveryLocationTitle = styled(BlackP1)`
  padding-left: 40px;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}
`;

const DeliveryOptions = ({
  isGettingDeliveryTypes,
  error,
  deliveryTypes,
  getDeliveryTypes,
}) => {
  const user = TransitStorage.getNewVignettePersonalInfoSchema();

  const [locations, setLocations] = useState(null);

  const fetchData = async () => {
    let response = await (await fetch(PICKUP_LOCATIONS)).json();
    setLocations(response);
    console.log(response);
  };

  useEffect(() => {
    fetchData();

    getDeliveryTypes({ country_id: user.country_id });
  }, [getDeliveryTypes, user.country_id]);

  const memoizedHandleSumbit = useCallback(() => {
    return;
  }, []);

  const [showPopup, setShowPopup] = React.useState(true);

  return (
    <NewVignetteLayout
      step={2}
      title={<FormattedMessage id="newVignetteDeliveryOptionsTitle" />}
      isLoading={isGettingDeliveryTypes}
      error={error}
      hasForm
      initialValues={{
        ...TransitStorage.getNewVignetteDeliveryInfoSchema(),
      }}
      onSubmit={(values) => {
        TransitStorage.persistNewVignetteDeliveryInfoSchema(values);
        history.push(`${TRANSIT_VIGNETTE_ROUTE}/new-vignette/payment-choice`);
      }}
    >
      <>
        <BlackP1>
          <span>
            {user?.initials} {user?.prefix} {user?.lastname}
          </span>
          <br />
          <span>
            {user?.address} {user?.number} {user?.addition}{" "}
          </span>
          <br />
          <span>
            {user?.postcode} {user?.city}{" "}
          </span>
          <br />
          <span>{localStorage.getItem("country") || "Nerdeland"}</span>
        </BlackP1>
        <FormWrapper>
          <Field
            name={"deliveryType"}
            validate={(value) => {
              if (!value) return "you must pick";
            }}
          >
            {({ field, form }) => (
              <>
                <RadioGroup
                  name={field.name}
                  className="mt-iverse-10 mb-0"
                  error={form.errors.vignetKind && form.touched.vignetKind}
                >
                  <>
                    {deliveryTypes?.map((delivery, index) => {
                      if (
                        TransitStorage.getNewVignetteDetailsSchema()
                          .vignetNumber > 1 &&
                        index
                      ) {
                        return null;
                      }

                      return (
                        <CustomLabel
                          htmlFor={delivery.id}
                          label={delivery.name}
                          key={delivery.id}
                          disabled={
                            TransitStorage.getNewVignetteDetailsSchema()
                              .vignetNumber > 1 && index
                          }
                        >
                          <Radio
                            onChange={(e) => {
                              form.setFieldValue("deliveryType", delivery.id);
                              const deliveryInfo = {
                                delivery_type_id: delivery.id,
                                delivery_name: delivery.name,
                                useTranslation: false,
                                deliveryPrice: delivery.price,
                              };
                              TransitStorage.persistDeliveryInfoMeta(
                                deliveryInfo
                              );
                            }}
                            id={delivery.id}
                            checked={field.value === delivery.id}
                            variant="tertiary"
                            value={delivery.id}
                          />
                        </CustomLabel>
                      );
                    })}
                    {/* collections point */}
                    {TransitStorage.getNewVignetteDetailsSchema().vignetNumber >
                      21 && (
                      <>
                        <BlackIntro style={{ marginTop: 40 }}>
                          <FormattedMessage id="newVignetteDeliveryOptionsTakeAway" />
                        </BlackIntro>
                        <CustomLabel
                          htmlFor={"collectionsPoint"}
                          label={
                            <FormattedMessage
                              id="newVignetteDeliveryOptionsCollectionLabel"
                              values={{
                                b: (chunks) => <b>{chunks}</b>,
                              }}
                            />
                          }
                          disabled={
                            TransitStorage.getNewVignetteDetailsSchema()
                              .vignetNumber > 1
                          }
                        >
                          <Radio
                            onChange={(e) => {
                              form.setFieldValue(
                                "deliveryType",
                                "collectionsPoint"
                              );
                              const deliveryInfo = {
                                delivery_name:
                                  "newVignetteDeliveryOptionsCollectionPoints",
                                useTranslation: true,
                              };
                              TransitStorage.persistDeliveryInfoMeta(
                                deliveryInfo
                              );
                            }}
                            id={"collectionsPoint"}
                            checked={field.value === "collectionsPoint"}
                            variant="tertiary"
                            value={"collectionsPoint"}
                          />
                        </CustomLabel>
                      </>
                    )}
                    {TransitStorage.getNewVignetteDetailsSchema().vignetNumber >
                      21 && (
                      <DeliveryLocationTitle
                        disabled={
                          TransitStorage.getNewVignetteDetailsSchema()
                            .vignetNumber > 1
                        }
                      >
                        <FormattedMessage
                          id="newVignetteDeliveryOptionsCollectionsMore"
                          values={{ br: <br /> }}
                        />
                      </DeliveryLocationTitle>
                    )}
                  </>
                </RadioGroup>
                {form.errors.deliveryType && form.touched.deliveryType && (
                  <CustomErrorMessage id="makeChoiceAbove" />
                )}
              </>
            )}
          </Field>
        </FormWrapper>
        {TransitStorage.getNewVignetteDetailsSchema().vignetNumber > 21 && (
          <DeliveryCheckPoints
            disabled={
              TransitStorage.getNewVignetteDetailsSchema().vignetNumber > 1
            }
          />
        )}

        <Map locations={locations} />

        <SubmitButton onNext={memoizedHandleSumbit} />
      </>
    </NewVignetteLayout>
  );
};

export default DeliveryOptions;
