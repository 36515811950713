import React, { useRef, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import SubmitButton from "../../../../../components/Button";
import { NewVignetteLayout } from "../..";
import SummaryDetails from "../../../components/Summary";
import { formatAmountToNL, parseQuery } from "../../../../../utils/helpers";
import TransitStorage from "../../../../../utils/storage";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../../../constants";

const Summary = ({ error, isMakingNewPayment, makeNewPayment, location }) => {
  const userInfo = TransitStorage.getNewVignettePersonalInfoSchema();
  const failureMessageRef = useRef();
  useEffect(() => {
    const query = parseQuery(location?.search);
    if (query?.failedPayment && failureMessageRef.current) {
      setTimeout(() => {
        failureMessageRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 1000);
    }
  }, [location?.search]);

  const persistedDeliveryInfo = TransitStorage.getDeliveryInfoMeta();

  const vignetInfo = TransitStorage.getNewVignetteMetaDetails();

  const getDeliveryInfo = () => {
    const deliveryInfo = { label: persistedDeliveryInfo?.delivery_name };
    if (!persistedDeliveryInfo?.useTranslation) {
      const {
        initials,
        lastname,
        address,
        number,
        postcode,
        city,
        prefix,
        addition,
      } = TransitStorage.getNewVignettePersonalInfoSchema();
      deliveryInfo.value = `${initials} ${prefix} ${lastname} <br /> ${address} ${number} ${addition} <br /> ${postcode} ${city} <br /> ${
        localStorage.getItem("country") || "Nerdeland"
      }`;
    } else {
      deliveryInfo.value = "Can be picked up at any outlet";
    }

    return deliveryInfo;
  };

  const calculateTotal = () => {
    // const vignetCost =
    //   TransitStorage.getNewVignetteDetailsSchema()?.vignetNumber *
    //   vignetInfo?.vignet_cost;
    const deliveryCost = persistedDeliveryInfo.deliveryPrice || 0;
    const totalAmount = Number(vignetInfo?.vignet_cost) + Number(deliveryCost);

    return {
      vignetCost: formatAmountToNL(vignetInfo?.vignet_cost),
      deliveryCost: formatAmountToNL(deliveryCost),
      totalAmount: formatAmountToNL(totalAmount),
    };
  };

  const memoizedHandleSumbit = useCallback(() => {
    return;
  }, []);

  const summaryPersonalInfo = [
    {
      title: "step1",
      route: `${TRANSIT_VIGNETTE_ROUTE}/new-vignette/personal-info`,
      info: [
        {
          label: "dayVignetteFirstName",
          value: userInfo?.initials,
        },
        {
          label: "lastname",
          value: userInfo?.lastname,
        },
        {
          label: "insertion",
          value: userInfo?.prefix,
        },
        {
          label: "street",
          value: userInfo?.address,
        },
        {
          label: "houseNumber",
          value: userInfo?.number,
        },
      ],
    },
  ];

  if (userInfo?.addition) {
    summaryPersonalInfo[0].info.push({
      label: "addition",
      value: userInfo?.addition,
    });
  }

  summaryPersonalInfo[0].info.push(
    {
      label: "postcode",
      value: userInfo?.postcode,
    },
    {
      label: "residence",
      value: userInfo?.city,
    },
    {
      label: "country",
      value: localStorage.getItem("country") || "Nederland",
    }
  );

  if (localStorage.getItem("country") === "Nederland") {
    summaryPersonalInfo[0].info.push({
      label: "bsn",
      value: userInfo?.bsn,
    });
  }

  summaryPersonalInfo[0].info.push(
    {
      label: "phone",
      value: userInfo?.phonenumber,
    },
    {
      label: "email",
      value: userInfo?.email,
    }
  );

  if (userInfo.company) {
    summaryPersonalInfo.unshift({
      title: "activateVignetteSummaryCompany",
      route: `${TRANSIT_VIGNETTE_ROUTE}/new-vignette/personal-info`,
      info: [
        {
          label: "company",
          value: userInfo?.company,
        },
        {
          label: "chamberCommerce",
          value: userInfo?.kvk,
        },
      ],
    });
  }
  return (
    <NewVignetteLayout
      step={4}
      title={<FormattedMessage id="dayStep5" />}
      isLoading={isMakingNewPayment}
      error={error}
      hasForm
      initialValues={{ agree_1: "", agree_2: "" }}
      onSubmit={(values) => {
        makeNewPayment();
      }}
    >
      <React.Fragment>
        <div ref={failureMessageRef}>
          <SummaryDetails
            data={[
              ...summaryPersonalInfo,
              {
                title: "product",
                route: `${TRANSIT_VIGNETTE_ROUTE}/new-vignette/`,
                disableChange: true,
                info: [
                  {
                    label: "newVignetteChooseVignetteSort",
                    labelSuffix: TransitStorage.getNewVignetteDetailsSchema()
                      ?.vignetNumber,
                    value: vignetInfo?.vignet_name,
                  },
                ],
              },
              {
                title: "delivery",
                route: `${TRANSIT_VIGNETTE_ROUTE}/new-vignette/delivery-options`,
                info: [
                  {
                    label: getDeliveryInfo().label,
                    value: getDeliveryInfo().value,
                  },
                ],
              },
              {
                title: "payment",
                route: `${TRANSIT_VIGNETTE_ROUTE}/new-vignette/payment-choice`,
                info: [
                  {
                    label: "paymentMethod",
                    value: localStorage.getItem("paymentChoice"),
                  },
                ],
              },
            ]}
            totalStruct={[
              {
                label: "totalAmount",
                value: `€ ${calculateTotal().totalAmount}`,
                items: [
                  {
                    label: "totalVignette",
                    value: `€ ${calculateTotal().vignetCost}`,
                  },
                  {
                    label: "newVignetteDeliveryOptionsCharge",
                    value: `€ ${calculateTotal().deliveryCost}`,
                  },
                ],
              },
            ]}
            failedPayment={parseQuery(location?.search)?.failedPayment}
          />
        </div>
        <SubmitButton
          taskflow={false}
          onNext={memoizedHandleSumbit}
          nextTitle="pay"
        />
      </React.Fragment>
    </NewVignetteLayout>
  );
};

export default Summary;
