import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    getVignetsRequest: [],
    getVignetsSuccess: ["responseData"],
    getVignetsFailure: ["error"],

    cancelVignetRequest: ["data"],
    cancelVignetSuccess: ["responseData"],
    cancelVignetFailure: ["error"],

    getVignetRequest: ["vignet_id"],
    getVignetSuccess: ["responseData"],
    getVignetFailure: ["error"],

    getDriveTypesRequest: [],
    getDriveTypesSuccess: ["responseData"],
    getDriveTypesFailure: ["error"],

    updateVignetRequest: ["data"],
    updateVignetSuccess: ["responseData"],
    updateVignetFailure: ["error"],

    uploadDocumentsRequest: ["data"],
    uploadDocumentsSuccess: ["responseData"],
    uploadDocumentsFailure: ["error"],

    getSummaryLinkRequest: [],
    getSummaryLinkSuccess: ["responseData"],
    getSummaryLinkFailure: ["error"],

    // TRANSIT VIGNETTES
    getVignetteTypes: [],
    getVignetteTypesSuccess: ["responseData"],
    getVignetteTypesFailure: ["error"],

    getDeliveryTypes: ["data"],
    getDeliveryTypesSuccess: ["responseData"],
    getDeliveryTypesFailure: ["error"],

    checkMooringVignette: ["data"],
    checkMooringVignetteSuccess: ["responseData"],
    checkMooringVignetteFailure: ["error"],

    // ACTIVATE VIGNETTES
    activateCheckVignette: ["data"],
    activateCheckVignetteSuccess: ["responseData"],
    activateCheckVignetteFailure: ["error"],

    activateGetDriveTypes: [],
    activateGetDriveTypesSuccess: ["responseData"],
    activateGetDriveTypesFailure: ["error"],

    activateUpdateVignet: ["data"],
    activateUpdateVignetSuccess: ["responseData"],
    activateUpdateVignetFailure: ["error"],

    activateUpdateOwner: ["data"],
    activateUpdateOwnerSuccess: ["responseData"],
    activateUpdateOwnerFailure: ["error"],

    activateValidateVignet: ["data"],
    activateValidateVignetSuccess: ["responseData"],
    activateValidateVignetFailure: ["error"],

    activateValidateOwner: ["data"],
    activateValidateOwnerSuccess: ["responseData"],
    activateValidateOwnerFailure: ["error"],

    activateUploadDocuments: ["data"],
    activateUploadDocumentsSuccess: ["responseData"],
    activateUploadDocumentsFailure: ["error"],

    validatePassageOwner: ["data"],
    validatePassageOwnerSuccess: ["responseData"],
    validatePassageOwnerFailure: ["error"],

    validatePassageVignet: ["data"],
    validatePassageVignetSuccess: ["responseData"],
    validatePassageVignetFailure: ["error"],

    activateSaveData: ["data", "photos"],
    activateSaveDataSuccess: ["responseData"],
    activateSaveDataFailure: ["error"],

    activateGetSummary: [],
    activateGetSummarySuccess: ["responseData"],
    activateGetSummaryFailure: ["error"],

    newVignetteCountries: [],
    newVignetteCountriesSuccess: ["responseData"],
    newVignetteCountriesFailure: ["error"],

    mooringSummary: [],
    mooringSummarySuccess: ["responseData"],
    mooringSummaryFailure: ["error"],

    newVignetteSummary: [],
    newVignetteSummarySuccess: ["responseData"],
    newVignetteSummaryFailure: ["error"],

    validateVoucherCode: ["data"],
    validateVoucherCodeSuccess: ["responseData"],
    validateVoucherCodeFailure: ["error"],

    activateGetObjectTypes: [],
    activateGetObjectTypesSuccess: ["responseData"],
    activateGetObjectTypesFailure: ["error"],
  },
  {}
);
