import { combineReducers } from "redux";

import app from "./services/redux/app/reducers";
import auth from "./services/redux/auth/reducers";
import profile from "./services/redux/profile/reducers";
import vignets from "./services/redux/vignets/reducers";
import payments from "./services/redux/payments/reducers";

const rootReducer = combineReducers({
  app,
  auth,
  profile,
  vignets,
  payments,
});

export default rootReducer;
