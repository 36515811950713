import { Route, Switch } from "react-router-dom";
import { RESTITUTIE_VIGNETTE_ROUTE } from "../../constants";

import PersonalInfo from "./components/PersonalInfo";
import Summary from "./components/Summary";
import Done from "./components/Done";

const RestitutieRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${RESTITUTIE_VIGNETTE_ROUTE}/personal-info`}
        component={PersonalInfo}
      />
      <Route
        exact
        path={`${RESTITUTIE_VIGNETTE_ROUTE}/summary`}
        component={Summary}
      />
      <Route
        exact
        path={`${RESTITUTIE_VIGNETTE_ROUTE}/done`}
        component={Done}
      />
    </Switch>
  );
};

export default RestitutieRoutes;
