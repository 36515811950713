export const BASE_URL = "https://acc.backoffice.digitaalvignet.nl/api-extend";

export const LOGIN_ENDPOINT = "login";
export const RESET_PASSWORD_ENDPOINT = "reset-password";
export const SET_PASSWORD_ENDPOINT = "reset-password";
export const GET_OWNER_DETAILS = "get-owner";
export const GET_VIGNET_DETAILS = "get-vignets";
export const UPDATE_OWNER = "update-owner";
export const CANCEL_VIGNET = "cancel-vignet";
export const GET_VIGNET = "get-vignet";
export const GET_DRIVE_TYPES = "get-drive-types";
export const UPDATE_VIGNET = "update-vignet";
export const UPLOAD_DOCUMENTS = "upload-documents";
export const DOWNLOAD_SUMMARY = "get-summary";

export const GET_PAYMENT_TYPES = "payment-types";
export const ACCOUNT_NUMBER_PAYMENT = "update-mandate";
export const BANK_PAYMENT = "payment";
export const CHECK_IBAN = "check-iban";

export const GOOGLE_MAPS_API_KEY = "AIzaSyAgHk7F-ThLbXGzPDJO9oKt6o70P-y0oho";