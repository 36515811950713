import React from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { Field } from "formik";

import CustomInput from "../../../../../components/Input";
import SubmitButton from "../../../../../components/Button";
import { history } from "../../../../../utils/helpers";
import { DayVignetLayout } from "../..";
import TransitStorage from "../../../../../utils/storage";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../../../constants";

const VignetSchema = Yup.object().shape({
  mobile_phone: Yup.string().required("required"),
  repeatTelephone: Yup.string()
    .required("required")
    .oneOf([Yup.ref("mobile_phone"), null], "transitTelephoneNoMatch"),
  email: Yup.string()
    .email("transitEmailRequired")
    .required("emailRequired")
    .trim(),
  repeatEmail: Yup.string()
    .required("required")
    .oneOf([Yup.ref("email"), null], "transitEmailNoMatch"),
  initials: Yup.string().required("required"),
  lastname: Yup.string(),
});

const PersonalInfo = () => {
  return (
    <DayVignetLayout
      step={2}
      title={<FormattedMessage id="dayStep3" />}
      subtitle={
        <FormattedMessage
          id="dayVignettePersonalDescription"
          values={{ br: <br /> }}
        />
      }
      isLoading={false}
      error={false}
      hasForm
      initialValues={{ ...TransitStorage.getDayVignettePersonalInfoSchema() }}
      validationSchema={VignetSchema}
      onSubmit={(values) => {
        TransitStorage.persistDayVignettePersonalInfoSchema(values);
        history.push(
          `${TRANSIT_VIGNETTE_ROUTE}/mooring-request/payment-choice`
        );
      }}
    >
      <React.Fragment>
        <Field
          name="initials"
          component={CustomInput}
          label={<FormattedMessage id="dayVignetteFirstName" />}
          style={{ width: "300px" }}
        />
        <Field
          name="lastname"
          component={CustomInput}
          label={<FormattedMessage id="lastname" />}
          style={{ width: "300px" }}
        />
        <Field
          name="prefix"
          component={CustomInput}
          label={<FormattedMessage id="insertion" />}
          style={{ width: "300px" }}
        />
        <Field
          name="mobile_phone"
          component={CustomInput}
          label={<FormattedMessage id="telephone" />}
          style={{ width: "300px" }}
        />
        <Field
          name="repeatTelephone"
          component={CustomInput}
          label={<FormattedMessage id="repeatTelephone" />}
          style={{ width: "300px" }}
        />
        <Field
          name="email"
          component={CustomInput}
          label={<FormattedMessage id="email" />}
          style={{ width: "300px" }}
        />
        <Field
          name="repeatEmail"
          component={CustomInput}
          label={<FormattedMessage id="repeatEmail" />}
          style={{ width: "300px" }}
        />
        <SubmitButton
          onNext={() => {
            return;
          }}
        />
      </React.Fragment>
    </DayVignetLayout>
  );
};

export default PersonalInfo;
