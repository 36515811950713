import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  isloading: false,
  error: null,
  user: null,

  resettingPassword: false,
  resetPasswordError: null,

  settingPassword: false,
  setPasswordError: null,
};

export const login = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: true, error: null };
};

export const loginSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    error: null,
    user: action.responseData,
  };
};

export const loginFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    error: action.error,
  };
};

// reset password
export const resetPassword = (state = INITIAL_STATE, action) => {
  return { ...state, resettingPassword: true, resetPasswordError: null };
};

export const resetPasswordSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    resettingPassword: false,
    resetPasswordError: null,
  };
};

export const resetPasswordFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    resettingPassword: false,
    resetPasswordError: action.error,
  };
};

// set password
export const setPassword = (state = INITIAL_STATE, action) => {
  return { ...state, settingPassword: true, setPasswordError: null };
};

export const setPasswordSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    settingPassword: false,
    setPasswordError: null,
  };
};

export const setPasswordFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    settingPassword: false,
    setPasswordError: action.error,
  };
};

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  // reset password
  [Types.RESET_PASSWORD_REQUEST]: resetPassword,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,

  // set password
  [Types.SET_PASSWORD_REQUEST]: setPassword,
  [Types.SET_PASSWORD_SUCCESS]: setPasswordSuccess,
  [Types.SET_PASSWORD_FAILURE]: setPasswordFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
