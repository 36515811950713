import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Radio, Label, RadioGroup, Checkbox } from "@amsterdam/asc-ui";
import styled from "styled-components";

import ProtectedLayout from "../..";
import { Field } from "formik";
import CustomInput from "../../../../components/Input";
import SubmitButton from "../../../../components/Button";
import { CustomRow, CustomColumn, CustomDivider } from "../../../../components";
import CustomLink from "../../../../components/Link/link";
import SummaryPage from "../../Summary/";
import {
  BlackIntro,
  BlackP1,
  CustomErrorMessage,
} from "../../../../components/Typography";

export const InfoWrapper = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  margin: 20px 20px 20px 0px;
`;

const CancelVignet = ({
  isCancelling,
  vignet,
  cancellingError,
  isGettingSummaryVignetError,
  cancelVignet,
  getOwner,
  lang,
}) => {
  const [showSummary, setShowSummary] = useState(false);
  const [cancellationReason, setCancellationReason] = useState();
  const [formValues, setFormValues] = useState({});

  const sumbitData = () => {
    const values = formValues;
    cancelVignet({
      vignet_id: vignet.vignet_id,
      reason: values.reason === "others" ? values.other_reason : values.reason,
    });
  };

  return (
    <ProtectedLayout
      step={3}
      subtitle={<FormattedMessage id="cancelVignetdescription" />}
      title={<FormattedMessage id="cancel" />}
      titleMore={vignet.vignet_number}
      hasForm={!showSummary}
      initialValues={{
        reason: "",
        other_reason: "",
        agree: "",
      }}
      isSubmitting={isCancelling}
      error={cancellingError || isGettingSummaryVignetError}
      onSubmit={(values) => {
        setFormValues(values);
        if (values.reason === "others" && !values.other_reason) return;
        if (!showSummary) {
          setShowSummary(true);
          getOwner();
          setCancellationReason(
            values.reason === "others" ? values.other_reason : values.reason
          );
        }
      }}
    >
      <React.Fragment>
        <CustomRow>
          <CustomColumn size={2}>
            <BlackIntro>
              <FormattedMessage id="boatName" />
            </BlackIntro>
          </CustomColumn>
          <CustomColumn size={2}>
            <BlackIntro>
              <FormattedMessage id="vignetNumber" />
            </BlackIntro>
          </CustomColumn>
          <CustomColumn size={4}>
            <BlackIntro>
              <FormattedMessage id="cancelReason" />
            </BlackIntro>
          </CustomColumn>
          {showSummary && (
            <CustomColumn size={1}>
              <CustomLink
                variant="inline"
                onClick={(e) => {
                  e.preventDefault();
                  setShowSummary(!showSummary);
                }}
              >
                <FormattedMessage id="change" />
              </CustomLink>
            </CustomColumn>
          )}

          <CustomDivider style={{ marginTop: "-4px" }} />
        </CustomRow>
        <CustomRow>
          <CustomColumn size={2}>
            <BlackP1>{vignet.object_name}</BlackP1>
          </CustomColumn>
          <CustomColumn size={2}>
            <BlackP1>{vignet.vignet_number}</BlackP1>
          </CustomColumn>
          <CustomColumn size={showSummary ? 5 : 4}>
            {showSummary ? (
              <BlackP1>{cancellationReason}</BlackP1>
            ) : (
              <>
                <Field
                  name="reason"
                  validate={(value) => {
                    if (!value) return "you must pick";
                  }}
                >
                  {({ field, form }) => (
                    <>
                      <RadioGroup name={field.name} error={form.errors.reason}>
                        <Label
                          htmlFor="Boot verkocht"
                          label={<FormattedMessage id="vesselSold" />}
                        >
                          <Radio
                            onChange={(e) => {
                              form.values.other_reason = "";
                              form.setFieldValue(
                                "reason",
                                lang === "nl" ? "Boot verkocht" : "Vessel sold"
                              );
                            }}
                            id="Boot verkocht"
                            checked={
                              lang === "nl"
                                ? field.value === "Boot verkocht"
                                : field.value === "Vessel sold"
                            }
                            variant="tertiary"
                            value={
                              lang === "nl" ? "Boot verkocht" : "Vessel sold"
                            }
                          />
                        </Label>
                        {/* <Label
                          htmlFor="Vignet is zoek geraakt"
                          label={<FormattedMessage id="vignetLost" />}
                        >
                          <Radio
                            onChange={(e) => {
                              form.values.other_reason = "";
                              form.setFieldValue(
                                "reason",
                                lang === "nl"
                                  ? "Vignet is zoek geraakt"
                                  : "Vignette has been lost"
                              );
                            }}
                            id="Vignet is zoek geraakt"
                            variant="tertiary"
                            checked={
                              lang === "nl"
                                ? field.value === "Vignet is zoek geraakt"
                                : field.value === "Vignette has been lost"
                            }
                            value={
                              lang === "nl"
                                ? "Vignet is zoek geraakt"
                                : "Vignette has been lost"
                            }
                            name={"reason"}
                          />
                        </Label> */}
                        {/* <Label
                          htmlFor="Vignet is gestolen"
                          label={<FormattedMessage id="vignetStolen" />}
                        >
                          <Radio
                            onChange={(e) => {
                              form.values.other_reason = "";
                              form.setFieldValue(
                                "reason",
                                lang === "nl"
                                  ? "Vignet is gestolen"
                                  : "Vignette has been stolen"
                              );
                            }}
                            id="Vignet is gestolen"
                            variant="tertiary"
                            checked={
                              lang === "nl"
                                ? field.value === "Vignet is gestolen"
                                : field.value === "Vignette has been stolen"
                            }
                            value={
                              lang === "nl"
                                ? "Vignet is gestolen"
                                : "Vignette has been stolen"
                            }
                            name={"reason"}
                          />
                        </Label> */}
                        <Label
                          htmlFor="others"
                          label={<FormattedMessage id="others" />}
                        >
                          <Radio
                            onChange={(e) =>
                              form.setFieldValue("reason", "others")
                            }
                            id="others"
                            variant="tertiary"
                            checked={field.value === "others"}
                            value="others"
                            name={"reason"}
                          />
                        </Label>
                      </RadioGroup>
                    </>
                  )}
                </Field>
                <Field name="other_reason">
                  {({ field, form }) => (
                    <React.Fragment>
                      {form.values.reason === "others" &&
                        !form.values.other_reason &&
                        !form.errors.other_reason &&
                        form.setFieldError(
                          "other_reason",
                          lang === "nl" ? "Verplicht" : "Required"
                        )}
                      <CustomInput
                        disabled={form.values.reason !== "others"}
                        field={field}
                        form={form}
                        error={form.values.reason === "others"}
                        onChange={(e) => {
                          form.setFieldValue("other_reason", e.target.value);
                        }}
                      />
                      {form.errors.reason && (
                        <CustomErrorMessage
                          id="selectChoice"
                          style={{ marginTop: "0px" }}
                        />
                      )}
                    </React.Fragment>
                  )}
                </Field>
              </>
            )}
          </CustomColumn>
        </CustomRow>
        <CustomRow>
          <CustomColumn size={5}>
            {showSummary ? (
              <InfoWrapper style={{ padding: "20px 20px 0px 20px" }}>
                <BlackP1>
                  <FormattedMessage id="cancellationAgreement" />
                </BlackP1>
              </InfoWrapper>
            ) : (
              <InfoWrapper>
                <Field
                  name="agree"
                  validate={(value) => {
                    if (!value) return "you must agree";
                  }}
                >
                  {({ field, form }) => (
                    <React.Fragment>
                      <Label
                        htmlFor="agree"
                        label={<FormattedMessage id="cancellationAgreement" />}
                      >
                        <Checkbox
                          id="agree"
                          name="agree"
                          onChange={(e) => {
                            form.setFieldValue(
                              "agree",
                              form.values.agree !== "yes" ? "yes" : ""
                            );
                          }}
                          checked={form.values.agree === "yes"}
                          value={form.values.agree}
                        />
                      </Label>
                      {form.errors.agree && form.touched.agree && (
                        <CustomErrorMessage id="cancellationRequired" />
                      )}
                    </React.Fragment>
                  )}
                </Field>
              </InfoWrapper>
            )}
          </CustomColumn>
        </CustomRow>
        {showSummary && (
          <CustomRow>
            <div style={{ marginTop: "40px" }}>
              <SummaryPage
                vignetProperties={[
                  {
                    label: "vignetNumber",
                    value: vignet.vignet_number,
                  },
                  {
                    label: "boatname",
                    value: vignet.object_name,
                  },
                  {
                    label: "unsubscribeReason",
                    value: cancellationReason,
                  },
                ]}
                onNext={() => sumbitData()}
                disabled={isCancelling}
              />
            </div>
          </CustomRow>
        )}
        {!showSummary && (
          <SubmitButton
            onNext={() => {
              return;
            }}
            disabled={isCancelling}
          />
        )}
      </React.Fragment>
    </ProtectedLayout>
  );
};

CancelVignet.propTypes = {
  isCancelling: PropTypes.bool.isRequired,
  vignet: PropTypes.shape({}).isRequired,
  cancellingError: PropTypes.string,
  isGettingSummaryVignetError: PropTypes.string,
  cancelVignet: PropTypes.func.isRequired,
  getOwner: PropTypes.func.isRequired,
};

CancelVignet.defaultProps = {
  cancellingError: null,
  isGettingSummaryVignetError: null,
};

export default CancelVignet;
