import axios from "axios";
import { axiosInstance, transitAxiosInstance, getToken } from "./axiosDefault";
import { BASE_URL } from "./urls";
import { BASE_URL as TRANSIT_BASE_URL } from "./transit-urls";

const lang = localStorage.getItem("lang") || "nl";

export const apiRequest = (method, url, data = {}, file = null) => {
  const response = axiosInstance(file)({
    method,
    url: `${url}/${lang}`,
    data,
  });
  return response;
};

export const transitApiRequest = (method, url, data = {}, file = null) => {
  const response = transitAxiosInstance(file)({
    method,
    url: `${url}/${lang}`,
    data,
  });
  return response;
};

export const apiFileRequest = (method, url, data) => {
  const formatData = new FormData();
  const files = Object.entries(data.data.documents);
  // eslint-disable-next-line
  files.map((d) => {
    if (d[1]) {
      let innerFilelist = Object.entries(d[1]);
      // eslint-disable-next-line
      innerFilelist.map((vignetFile) => {
        if (vignetFile[1]) formatData.append("documents[]", vignetFile[1]);
      });
    }
  });
  formatData.append("vignet_id", data.data.vignet_id);
  return axios.post(`${BASE_URL}/${url}`, formatData, {
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "multipart/form-data",
    },
  });
};

export const transitApiFileRequest = (method, url, data) => {
  const formatData = new FormData();
  const files = Object.entries(data);
  // eslint-disable-next-line
  files.map((d) => {
    if (d[1]) {
      let innerFilelist = Object.entries(d[1]);
      // eslint-disable-next-line
      innerFilelist.map((vignetFile) => {
        if (vignetFile[1]) formatData.append("photos[]", vignetFile[1]);
      });
    }
  });
  return axios.post(`${TRANSIT_BASE_URL}/${url}`, formatData, {
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "multipart/form-data",
    },
  });
};
