import { fromPairs } from "lodash";
import { lang } from "moment";
import { useLanguage } from "../../lang";
import sendFormDataToCms from "./ApiHandler";

const PersonalInfoFormValidator = {
  lang: {},
  formData: { data: {}, meta: {} },
  dumpFormData: (formData = PersonalInfoFormValidator.formData) => {
    PersonalInfoFormValidator.formData = formData;
    if (!PersonalInfoFormValidator.formData) {
      console.error("DumpFormData: FormData invalidated!");
      PersonalInfoFormValidator.formData = { data: {}, meta: {} };
    }
    window.localStorage.setItem(
      "restitution",
      JSON.stringify(PersonalInfoFormValidator.formData)
    );
  },
  fetchFormData: () => {
    const raw = window.localStorage.getItem("restitution");
    if (raw) {
      PersonalInfoFormValidator.formData = JSON.parse(raw);
    } else {
      console.error("FetchFormData: FormData invalidated!", raw);
      PersonalInfoFormValidator.formData = { data: {}, meta: {} };
      PersonalInfoFormValidator.dumpFormData();
    }
    return PersonalInfoFormValidator.formData;
  },
  isValueEmpty: (value) => {
    if (!value || value.trim().length == 0 || value.trim() == "") {
      return true;
    }
    return false;
  },
  isFieldEmpty: (field) => {
    const formData = PersonalInfoFormValidator.fetchFormData().data;
    return PersonalInfoFormValidator.isValueEmpty(formData[field]);
  },
  generateHash: () => {
    const formData = PersonalInfoFormValidator.fetchFormData().data;
    const str = JSON.stringify(formData);
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
      let chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  },
  isHashValid: () => {
    const hash = PersonalInfoFormValidator.fetchFormData().meta.hash;
    if (!hash) {
      return {
        isValid: false,
        note: PersonalInfoFormValidator.lang.restitutionFormErrorMissingHash,
      };
    }
    if (hash != PersonalInfoFormValidator.generateHash()) {
      return {
        isValid: false,
        note: PersonalInfoFormValidator.lang.restitutionFormErrorMismatchedHash,
      };
    }
    return { isValid: true };
  },
  isPhoneNumberValid: () => {
    const formData = PersonalInfoFormValidator.fetchFormData().data;
    if (PersonalInfoFormValidator.isValueEmpty(formData.phoneNumber)) {
      return {
        isValid: false,
        note:
          PersonalInfoFormValidator.lang.restitutionFormErrorMissingPhoneNumber,
      };
    }
    if (
      !/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(
        formData.phoneNumber
      )
    ) {
      return {
        isValid: false,
        note:
          PersonalInfoFormValidator.lang.restitutionFormErrorInvalidPhoneNumber,
      };
    }
    return { isValid: true };
  },
  isEmailValid: () => {
    const formData = PersonalInfoFormValidator.fetchFormData().data;
    if (PersonalInfoFormValidator.isValueEmpty(formData.email)) {
      return {
        isValid: false,
        note: PersonalInfoFormValidator.lang.restitutionFormErrorMissingEmail,
      };
    }
    if (
      !PersonalInfoFormValidator.isValueEmpty(formData.email) &&
      PersonalInfoFormValidator.isValueEmpty(formData.emailValidation)
    ) {
      return {
        isValid: false,
        note:
          PersonalInfoFormValidator.lang
            .restitutionFormErrorMissingEmailValidation,
      };
    }
    if (formData.email != formData.emailValidation) {
      return {
        isValid: false,
        note:
          PersonalInfoFormValidator.lang.resittutionFormErrorMismatchedEmail,
      };
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
      return {
        isValid: false,
        note: PersonalInfoFormValidator.lang.restitutionFormErrorInvalidEmail,
      };
    }
    return { isValid: true };
  },
  isReasonValid: () => {
    const formData = PersonalInfoFormValidator.fetchFormData().data;
    if (PersonalInfoFormValidator.isValueEmpty(formData.reason)) {
      return {
        isValid: false,
        note: PersonalInfoFormValidator.lang.restitutionFormErrorMissingReason,
      };
    }
    if (formData.reason.length < 10) {
      return {
        isValid: false,
        note: PersonalInfoFormValidator.lang.restitutionFormErrorReasonToShort,
      };
    }
    return { isValid: true };
  },
  isFormValid: () => {
    if (PersonalInfoFormValidator.validatorCallback) {
      PersonalInfoFormValidator.validatorCallback();
    }
    return {
      isValid:
        !PersonalInfoFormValidator.isFieldEmpty("initials") &&
        !PersonalInfoFormValidator.isFieldEmpty("lastName") &&
        !PersonalInfoFormValidator.isFieldEmpty("vignetNumber") &&
        !PersonalInfoFormValidator.isFieldEmpty("vesselName") &&
        PersonalInfoFormValidator.isEmailValid().isValid &&
        PersonalInfoFormValidator.isPhoneNumberValid().isValid &&
        PersonalInfoFormValidator.isReasonValid().isValid &&
        PersonalInfoFormValidator.isHashValid().isValid,
    };
  },
  validatorCallback: () => {},
  setCallback: (callback) => {
    PersonalInfoFormValidator.validatorCallback = callback;
  },
  sendAndCheck: async () => {
    const hashValidation = PersonalInfoFormValidator.isHashValid();
    if (!hashValidation.isValid) {
      return hashValidation;
    }
    return sendFormDataToCms();
  },
  hasUserAgreed: () => {
    console.error("Undefined agreement behaviour!");
    return { isValid: false };
  },
  performPost: () => {
    if (PersonalInfoFormValidator.hasUserAgreed()) {
      return PersonalInfoFormValidator.sendAndCheck();
    } else {
      PersonalInfoFormValidator.setUserAgreementValidity();
    }
    return {
      isValid: false,
      note: "User hasn't agreed to the terms of service.",
    };
  },
  setUserAgreementValidity: () => {
    console.error("setUserAgreementValidity callback not set!");
    return { isValid: false };
  },
};

export { PersonalInfoFormValidator };
