import React from "react";
import styled from "styled-components";
import { Field } from "formik";
import { Select } from "@amsterdam/asc-ui";

import { CustomErrorMessage } from "../../../../components/Typography";

const SelectWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  width: 200px;
`;

export const isPaymentCardType = (name) => {
  return name?.includes("card");
};

const CardDropDown = ({
  paymentTypes,
  selectedPaymentType,
  paymentObject,
  showCardError,
  setShowCardError,
}) => {
  const isCardPaymentAvailable = () => {
    return paymentTypes?.filter((payment) => isPaymentCardType(payment.name))
      .length;
  };

  if (
    isCardPaymentAvailable() &&
    isPaymentCardType(selectedPaymentType) &&
    isPaymentCardType(paymentObject.name)
  ) {
    const cardChoices = paymentTypes.filter((payment) =>
      isPaymentCardType(payment.name)
    )[0];
    return (
      <Field name="card_service_code">
        {({ field, form }) => (
          <SelectWrapper>
            <Select
              id="card_service_code"
              onChange={(e) => {
                form.setFieldValue("card_service_code", e.target.value);
                if (e.target.value) setShowCardError(false);
              }}
              error={showCardError}
              defaultValue={localStorage.getItem("card_service_code")}
            >
              <option value="">Select Card</option>
              {cardChoices?.card_list?.map((data) => (
                <option key={data.ServiceCode} value={data.ServiceCode}>
                  {data.CardName}
                </option>
              ))}
            </Select>
            {showCardError && (
              <div className="mt-10">
                <CustomErrorMessage id="selectChoice" />
              </div>
            )}
          </SelectWrapper>
        )}
      </Field>
    );
  }

  return null;
};

export default CardDropDown;
