import React from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";

import { CustomColumn, CustomRow } from "../../../../../components";
import { BlackP1 } from "../../../../../components/Typography";

const BoldText = styled.span`
  font-family: Avenir Next LT Pro Demi;
`;

const HoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
`;

const OpeningHoursWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const HoursLabel = styled(BlackP1)`
  margin-top: 0px;
  margin-bottom: 0px;
`;

const DeliveryRow = styled(CustomRow)`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}
`;

const DeliveryCheckPoints = ({ disabled }) => {
  return (
    <DeliveryRow disabled={disabled}>
      <CustomColumn size={8}>
        <CustomColumn size={4}>
          <BlackP1 style={{ marginBottom: 0 }}>
            <BoldText>GVB klantenservice</BoldText> <br />
            Stationsplein 6 <br />
            (tegenover Centraal Station) <br />
            <br />
            <BoldText>
              <FormattedMessage id="openHours" />
            </BoldText>
          </BlackP1>
          <HoursContainer>
            {[
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].map((hour) => (
              <OpeningHoursWrapper key={hour}>
                <div>
                  <HoursLabel>
                    <FormattedMessage id={hour} />
                  </HoursLabel>
                </div>
                <div className="ml-20">
                  <HoursLabel>08:00 - 19.00 uur</HoursLabel>
                </div>
              </OpeningHoursWrapper>
            ))}
          </HoursContainer>
        </CustomColumn>
        <CustomColumn size={4}>
          <BlackP1 style={{ marginBottom: 0 }}>
            <BoldText>Waternet klantenbalie</BoldText> <br />
            Korte Ouderkerkerdijk 7 <br />
            (nabij Amstel Station) <br />
            <br />
            <BoldText>
              <FormattedMessage id="openHours" />
            </BoldText>
          </BlackP1>
          <HoursContainer>
            {[
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].map((hour) => (
              <OpeningHoursWrapper key={hour}>
                <div>
                  <HoursLabel>
                    <FormattedMessage id={hour} />
                  </HoursLabel>
                </div>
                <div
                  className="ml-20"
                  style={{
                    width: ["saturday", "sunday"].includes(hour) ? "55%" : "",
                  }}
                >
                  <HoursLabel>
                    {["saturday", "sunday"].includes(hour) ? (
                      <FormattedMessage id="closed" />
                    ) : (
                      "09:00 - 17.00 uur"
                    )}
                  </HoursLabel>
                </div>
              </OpeningHoursWrapper>
            ))}
          </HoursContainer>
        </CustomColumn>
      </CustomColumn>
    </DeliveryRow>
  );
};

export default DeliveryCheckPoints;
