import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import {
  StepByStepItem,
  StepByStepNavigation,
} from "../../../components/Steppers";
import { getVignetStatus, history } from "../../../utils/helpers";

const StepperWrapper = styled.div`
  height: 500px;

  @media only screen and (max-width: 468px) {
    display: none;
  }
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

const CustomStepByStepNavigation = styled(StepByStepNavigation)`
  margin: 40px 0px;
  maxwidth: 200px;
  height: 80px;
`;

const getDecisionName = (decision) => {
  return <FormattedMessage id={decision} />;
};

const getDecisions = () => {
  const data = localStorage.getItem("steppers_decisions");
  if (data) {
    return JSON.parse(data);
  }
};

const Steppers = ({ step, activeVignet }) => {
  const [toggleUp, setToggleUp] = useState(true);
  useEffect(() => {
    setToggleUp(step === 3);
  }, [step]);
  return (
    <StepperWrapper>
      <CustomStepByStepNavigation>
        <StepByStepItem
          heading={<FormattedMessage id="step1" />}
          onClick={(e) => {
            e.preventDefault();
            history.push("/personal-info");
          }}
          checked={step >= 1}
          active={step === 1}
          href="#"
        />
        <StepByStepItem
          heading={<FormattedMessage id="step2" />}
          onClick={(e) => {
            e.preventDefault();
            if (step >= 2) history.push("/vignets-decision");
          }}
          checked={step >= 2}
          active={step === 2}
          disabled={step < 2}
        />
        <StepByStepItem
          heading={<FormattedMessage id="step3" />}
          checked={step >= 3}
          active={step === 3}
          disabled={step < 3}
          dropDownUp={!toggleUp}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setToggleUp(!toggleUp);
          }}
        />
        {toggleUp && activeVignet ? (
          <>
            {getDecisions()?.map((vignet) => (
              <StepByStepItem
                heading={getDecisionName(vignet.decision)}
                active={vignet.vignet_number === activeVignet?.vignet_number}
                checked={getVignetStatus(vignet.vignet_number) !== "open"}
                vignet={vignet.vignet_number}
                key={vignet.vignet_number}
                disabled={step < 3}
              />
            ))}

            <StepByStepItem
              heading={<FormattedMessage id="step5" />}
              onClick={(e) => {
                e.preventDefault();
                if (step >= 5) history.push("/done");
              }}
              checked={step >= 5}
              active={step === 5}
              disabled={step < 5}
            />
          </>
        ) : (
          <StepByStepItem
            heading={<FormattedMessage id="step5" />}
            onClick={(e) => {
              e.preventDefault();
              if (step >= 5) history.push("/done");
            }}
            checked={step >= 5}
            active={step === 5}
            disabled={step < 5}
          />
        )}
      </CustomStepByStepNavigation>
    </StepperWrapper>
  );
};

export default Steppers;
