import styled from "styled-components";
import { Row, RadioGroup, Column } from "@amsterdam/asc-ui";

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CustomRow = styled(Row)`
  padding-left: 0px;
`;

export const CustomDivider = styled.div`
  background-color: #e6e6e6;
  width: 100%;
  height: 1px;
  margin: 20px 0px 20px 0px;
`;

export const CustomRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: Avenir Next LT Pro Regular;

  @media only screen and (max-width: 468px) {
    flex-direction: column;
  }
`;

export const CustomColumn = ({ size, small, children, large }) => {
  return (
    <Column
      wrap
      span={{
        small: small,
        medium: size,
        big: large || size,
        large: large || size,
        xLarge: large || size,
      }}
    >
      {children}
    </Column>
  );
};

CustomColumn.defaultProps = {
  small: 12,
};

export const CustomMarginTopSpacing = styled.div`
  margin-top: 20px;
`;
