import { connect } from "react-redux";
import { TRANSIT_VIGNETTE_ROUTE } from "../../../constants";
import { history } from "../../../utils/helpers";

import HomePage from "./components";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToActivate: () => {
      history.push(`${TRANSIT_VIGNETTE_ROUTE}/activate-vignette`);
    },
    goToRequestVignette: () => {
      history.push(`${TRANSIT_VIGNETTE_ROUTE}/new-vignette`);
    },
    goToMooringRequest: () => {
      history.push(`${TRANSIT_VIGNETTE_ROUTE}/mooring-request`);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
