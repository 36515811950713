import { connect } from "react-redux";

import { Creators } from "../../../../services/redux/payments/actions";
import Summary from "./components";

const mapStateToProps = (state) => {
  const { error, isMakingNewPayment } = state.payments;
  return { error, isMakingNewPayment };
};
const mapDispatchToProps = (dispatch) => {
  return {
    makeNewPayment: () => {
      dispatch(Creators.makeNewPayment());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
