import { connect } from "react-redux";

import { Creators } from "../../../services/redux/vignets/actions";
import { Creators as ProfileCreators } from "../../../services/redux/profile/actions";
import CancelVignet from "./components";

const mapStateToProps = (state) => {
  const {
    isCancelling,
    cancellingError,
    cancelledVignet,
    cancelVignetReason,
    vignet,
    isGettingVignet,
    isGettingVignetError,
  } = state.vignets;
  const { isloading } = state.profile;
  return {
    isCancelling,
    cancellingError,
    cancelledVignet,
    cancelVignetReason,
    vignet,
    isGettingSummaryVignet: isGettingVignet,
    isGettingSummaryVignetError: isGettingVignetError,
    isSummaryLoading: isloading,
    lang: state.app.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelVignet: (data) => {
      dispatch(Creators.cancelVignetRequest(data));
    },
    getOwner: () => {
      dispatch(ProfileCreators.getOwnerRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelVignet);
