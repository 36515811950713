import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import SubmitButton from "../../../../../components/Button";
import { ActivateVignetteLayout } from "../..";
import { BlackIntro, BlackP1 } from "../../../../../components/Typography";
import CustomInput from "../../../../../components/Input";
import AmsterdamImage from "../../../../../assets/amsterdam.png";
import OutsideImage from "../../../../../assets/outside.png";
import { CustomRow, CustomColumn } from "../../../../../components";
import { mergeSchemas } from "../../../../../utils/helpers";
import TransitStorage from "../../../../../utils/storage";

const VignetInfoCard = styled.div`
  border: 2px solid #fec813;
  padding: 15px 15px 0px 15px;
  width: 60%;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 500px;
  height: 250px;
  margin-left: -8px;
  object-fit: contain;
`;

const ActivateInput = styled(CustomInput)`
  width: 300px;
`;

const VignetPinSchema = Yup.object().shape({
  pincode: Yup.string().required("required"),
});

const RepeatVignetSchema = Yup.object().shape({
  repeatvignetteNumber: Yup.string()
    .required("required")
    .oneOf([Yup.ref("vignet"), null], "transitVignetteNoMatch"),
});

const VignetSchema = Yup.object().shape({
  vignet: Yup.string().required("required"),
});

const ChooseVignette = ({
  isActivatingCheckVignette,
  error,
  activateVignette,
}) => {
  const vignetteChoice = 1;

  const getFormProperties = () => {
    let content = {
      description: "activateVignetteDescription1",
      secondFieldName: "activateVignetteCode",
      secondFieldType: "password",
      image: AmsterdamImage,
    };

    if (vignetteChoice === 2) {
      content = {
        description: "activateVignetteDescription2",
        secondFieldName: "repeatvignetteNumber",
        secondFieldType: "text",
        image: OutsideImage,
      };
    }

    return content;
  };

  const memoizedHandleSumbit = useCallback(() => {
    return;
  }, []);

  return (
    <ActivateVignetteLayout
      step={0}
      title={<FormattedMessage id="activateVignetteSubtitle" />}
      isLoading={isActivatingCheckVignette}
      error={error}
      hasForm
      validationSchema={
        vignetteChoice === 1
          ? mergeSchemas(VignetSchema, VignetPinSchema)
          : mergeSchemas(VignetSchema, RepeatVignetSchema)
      }
      initialValues={{
        vignet: TransitStorage.getActivateVignetteSchema()?.vignetInfo?.vignet,
        [vignetteChoice === 1 ? "pincode" : "repeatvignetteNumber"]: "",
      }}
      onSubmit={(values) => {
        values.vignet_type = vignetteChoice;
        activateVignette(values);
        TransitStorage.persistActivateVignetteSchema(values);
      }}
    >
      <React.Fragment>
        {vignetteChoice && (
          <>
            <BlackP1>
              <FormattedMessage
                id={getFormProperties().description}
                values={{ br: <br /> }}
              />
            </BlackP1>
            <Field
              name="vignet"
              component={ActivateInput}
              label={<FormattedMessage id="vignetteNumber" />}
            />
            <Field
              name={vignetteChoice === 1 ? "pincode" : "repeatvignetteNumber"}
              component={ActivateInput}
              label={
                <FormattedMessage
                  values={{ br: <br /> }}
                  id={getFormProperties().secondFieldName}
                />
              }
              type={getFormProperties().secondFieldType}
            />
            <VignetInfoCard>
              <BlackIntro>
                <FormattedMessage id="activateVignetteNotificationTitle" />
              </BlackIntro>
            </VignetInfoCard>
            <CustomRow>
              <CustomColumn size={3}>
                <Image src={getFormProperties().image} alt="vignette" />
              </CustomColumn>
            </CustomRow>
            <br />
            <BlackP1>
              <FormattedMessage
                id="vignettePrivacyStatement"
                values={{
                  link: (chunks) => (
                    <a
                      href="https://www.amsterdam.nl/privacy/specifieke/privacyverklaring-parkeren-verkeer-bouw/verkeersmanagement-gracht-privacy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </BlackP1>
            <br />
            <SubmitButton onNext={memoizedHandleSumbit} showPrevious={false} />
          </>
        )}
      </React.Fragment>
    </ActivateVignetteLayout>
  );
};

export default ChooseVignette;
