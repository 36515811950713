import * as React from "react";
import { Checkbox } from "@amsterdam/asc-ui";
import style from "./AgreementConfirmation.module.css";

const AgreementConfirmation = (props) => {
  const [valid, setValid] = React.useState(true);
  const validity = valid ? style.valid : style.invalid;

  props.callback.setValidity = (validity) => {
    setValid(validity);
  };

  return (
    <div className={`${style.container} ${validity}`}>
      <Checkbox
        onChange={(e) => {
          props.onChange(e);
          if (e.target.checked) {
            setValid(e.target.checked);
          }
        }}
        value={props.checked}
      ></Checkbox>
      <label>{props.children}</label>
    </div>
  );
};

export default AgreementConfirmation;
