import { connect } from "react-redux";

import { Creators } from "../../../services/redux/auth/actions";
import SetPasswordForm from "./components";

const mapStateToProps = (state) => {
  const { settingPassword, setPasswordError } = state.auth;
  return {
    isloading: settingPassword,
    error: setPasswordError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPassword: (password, token) => {
      dispatch(Creators.setPasswordRequest(password, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordForm);
