import React from "react";
import { Button } from "@amsterdam/asc-ui";
import { FormattedMessage } from "react-intl";
import pdfMake from "pdfmake/build/pdfmake";
import { BlackH2, BlackP1 } from "../../../components/Typography";
import { useLanguage } from "../../../lang";

const DownloadSummaryButton = (props) => {
  const [language, messages] = useLanguage();
  const fileName = language == "nl" ? "Samenvatting" : "Summary";
  return (
    <>
      <Button
        variant="primary"
        onClick={(e) => {
          e.preventDefault();
          pdfMake.createPdf(props.pdfData).download(fileName);
        }}
        className="mt-inverse-20"
      >
        <React.Fragment>
          <FormattedMessage id="download" />
          <FormattedMessage id="resume" />
        </React.Fragment>
      </Button>

      <BlackH2 as="h2" className="mt-20">
        <FormattedMessage id="ask" />?
      </BlackH2>
      <BlackP1></BlackP1>
      <BlackP1>
        <FormattedMessage id="finalDetail" />
      </BlackP1>
    </>
  );
};

export default DownloadSummaryButton;
