import { FormattedMessage } from "react-intl";
import { RESTITUTIE_VIGNETTE_ROUTE } from "../../../constants";
import AppLayout from "../../TransitVignette";

import SubmitButton from "../../../components/Button";
import { PersonalInfoFormValidator } from "../FormValidator";
import { useLanguage } from "../../../lang";

const steps = [
  {
    label: "newVignetteStep2",
    route: `${RESTITUTIE_VIGNETTE_ROUTE}/personal-info`,
    validate: PersonalInfoFormValidator.isFormValid,
  },
  {
    label: "newVignetteStep5",
    route: `${RESTITUTIE_VIGNETTE_ROUTE}/summary`,
    validate: PersonalInfoFormValidator.performPost,
  },
  {
    label: "newVignetteStep6",
    route: `${RESTITUTIE_VIGNETTE_ROUTE}/done`,
  },
];

// We can't properly use react states here because of the way they built this form.
const getCurrentStep = () => {
  for (const step in steps) {
    if (window.location.href.includes(steps[step].route)) {
      PersonalInfoFormValidator.fetchFormData().meta.step = step;
      PersonalInfoFormValidator.dumpFormData();
      return step;
    }
  }
  return PersonalInfoFormValidator.fetchFormData.meta.step || 0;
};

const submitButtonOnNext = (step) => {
  if (step < steps.length - 1) {
    if (steps[step].validate) {
      const validation = steps[step].validate();
      if (
        typeof validation === "object" &&
        typeof validation.then === "function"
      ) {
        validation.then((success) => {
          if (!success.isValid) {
            return;
          }
          const formData = (PersonalInfoFormValidator.fetchFormData().meta.step = ++step);
          window.location.href = steps[step].route;
        });
      } else {
        if (!validation.isValid) {
          return;
        }
        const formData = (PersonalInfoFormValidator.fetchFormData().meta.step = ++step);
        window.location.href = steps[step].route;
      }
    }
  }
};

const getSubmitButton = (step, messages) => {
  if (step >= steps.length - 1) {
    return null;
  }
  let isSummary = false;
  if (step == steps.length - 2) {
    isSummary = true;
  }
  return (
    <SubmitButton
      onNext={() => {
        submitButtonOnNext(step);
      }}
      onPrevious={() => {
        if (step > 0) {
          const formData = (PersonalInfoFormValidator.fetchFormData().meta.step = --step);
          window.location.href = steps[step].route;
        }
      }}
      showPrevious={step != 0}
      nextTitle={isSummary ? messages.sendNow : messages.next}
      taskflow={!isSummary}
    ></SubmitButton>
  );
};

const RestitutieLayout = (props) => {
  let step = getCurrentStep();
  let title = props.title || "";
  let subtitle = props.subtitle || "";
  let rest = props.rest || {};
  let children = props.children || <div>Empty</div>;
  const [language, messages] = useLanguage();
  return (
    <AppLayout
      headerTitle={<FormattedMessage id="restitutionFormTitle" />}
      step={step}
      steps={steps}
      title={title}
      subtitle={subtitle}
      {...rest}
    >
      {children}
      {getSubmitButton(step, messages)}
    </AppLayout>
  );
};

export default RestitutieLayout;
