import { connect } from "react-redux";

import { Creators } from "../../../services/redux/auth/actions";
import LoginForm from "./components";

const mapStateToProps = (state) => {
  const { isloading, error } = state.auth;
  return {
    isloading,
    error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => {
      dispatch(Creators.loginRequest(email, password));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
